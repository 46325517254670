import React, { useEffect, useState } from "react";
import TriggerEmergencyStyle from "./TriggerEmergency.module.css";
import { Heading } from "@chakra-ui/layout";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { useGeolocated } from "react-geolocated";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { ShowAlert } from "../../GlobalComponents/ShowAlert/ShowAlert";
import Timer from "../../GlobalComponents/Timer/Timer";
import { qrCall } from "./Action/qrWithoutMobile";
import { raiseEmergencyWithCompanyIdAction } from "./Action/companyCall";
import { qrEmergencyWithMobile } from "./Action/qrWithMobile";
import axios from "axios";
import apiConfig from "../../apiConfig/apiConfig";

const TriggerEmergency = () => {
  const baseUri = apiConfig.api.baseURL || "https://v2.apis.pococare.com"
  const navigate = useNavigate();
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: Infinity,
  });

  const [mobile, setMobile] = useState("");
  const { companyId } = useParams<{ companyId: string }>();
  const [autoCall, setAutoCall] = useState(true); // eslint-disable-line
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleAutoCall = async () => { // eslint-disable-line
    if (!autoCall) {
      return
    }
    if (autoCall) {
      if (!companyId) {
        ShowAlert('waring', 'Beneficiary not valid!!');
        return
      }
      if (companyId && companyId.length === 24) {
        try {

          let res = await qrCall(companyId ? companyId : "", coords?.latitude ? String(coords.latitude) : null, coords?.longitude ? String(coords.longitude) : null)
          if (res.data.message.includes('ticket already created ticketId')) {

            ShowAlert('warning', 'Ticket has already created!')
          } else {

            ShowAlert('success', 'Please wait, Our team will reach out to you soon!')
          }
          setButtonClicked(true);
          setAutoCall(false);
          navigate('/')
        }
        catch (error: any) {
          error.response?.data?.message ? ShowAlert('warning', error.response?.data?.message) :
            ShowAlert('warning', "Please try again later!")

          console.error();
        }
      }
      else if (companyId && companyId.length === 10) {
        try {
          const res = await raiseEmergencyWithCompanyIdAction(companyId, null, coords?.latitude ? String(coords.latitude) : null, coords?.longitude ? String(coords.longitude) : null)

          if (res.data.message.includes('ticket already created ticketId')) {
            ShowAlert('warning', 'Ticket has already created.')
          } else {

            ShowAlert('success', 'Please wait, Our team will reach out to you soon!')
          }
          setButtonClicked(true);
          setAutoCall(false);
          navigate('/')
        } catch (error: any) {
          error.response?.data?.message ? ShowAlert('warning', error.response?.data?.message) :
            ShowAlert('warning', "Please try again later!")
          console.error();
          return;
        }
      } else {
        ShowAlert("warning", "Invalid BenId Or Invalid CompanyId");
      }

    }

  };

  const handleCall = async () => {
    if (!mobile) {
      return ShowAlert("warning", "Phone number is required");
    }

    if (mobile.length !== 10) {
      return ShowAlert(
        "warning",
        "Phone number should be exactly 10 digits"
      );
    }

    if (companyId && companyId.length === 24) {
      try {
        let res = await qrEmergencyWithMobile(companyId ? companyId : "", mobile, coords?.latitude ? String(coords.latitude) : null, coords?.longitude ? String(coords.longitude) : null)
        if (res.data.message.includes('ticket already created ticketId')) {
          ShowAlert('warning', 'Ticket has already created.')
        } else {

          ShowAlert('success', 'Please wait, Our team will reach out to you soon!')
        }
        setButtonClicked(true);
        setAutoCall(false);
        navigate('/')
      } catch (error: any) {
        error.response?.data?.message ? ShowAlert('warning', error.response?.data?.message) :
          ShowAlert('warning', "Please try again later!")

        console.error();
        return;
      }
    }
    else if (companyId && companyId.length === 10) {
      try {
        const res = await raiseEmergencyWithCompanyIdAction(companyId, mobile, coords?.latitude ? String(coords.latitude) : null, coords?.longitude ? String(coords.longitude) : null)

        if (res.data.message.includes('ticket already created ticketId')) {
          ShowAlert('warning', 'Ticket has already created.')
        } else {

          ShowAlert('success', 'Please wait, Our team will reach out to you soon!')
        }
        setButtonClicked(true);
        setAutoCall(false);
        navigate('/')
      } catch (error: any) {
        error.response?.data?.message ? ShowAlert('warning', error.response?.data?.message) :
          ShowAlert('warning', "Please try again later!")
        console.error();
        return;
      }
    } else {
      ShowAlert("warning", "Invalid BenId Or Invalid CompanyId");
      // navigate('/');
    }
  };

  useEffect(() => {
    if (companyId && companyId.length === 24) {
      axios.get(`${baseUri}/beneficiary/api/v1/temp-ben/${companyId}`).then((res) => {
        if (res.data.activated === false) {
          console.log(res.data)
          sessionStorage.setItem('companyLogo', res.data.company.logo);
          return navigate(`/activateAccount/${companyId}`)
        }
      }).catch((err: any) => {
        console.log(err)
      })
    }

  }, []) //  eslint-disable-line 

  return (
    <>
      <div className={TriggerEmergencyStyle.main}>
        <Image
          width={"80"}
          paddingTop={"10px"}
          marginLeft={"10px"}
          src="/Images/logo.png"
        />
        <div className={TriggerEmergencyStyle.container}>
          <Heading marginBottom={"20px"}>
            Hello from Pococare Heartbeat Team,
          </Heading>
          <Heading marginBottom={"20px"} as="h3" size="md">
            Thank you for scanning the QR code of a registered beneficiary to notify an emergency.
          </Heading>
          <Heading marginBottom={"20px"} as="h3" size="md">
            Please enter your 10 digit mobile number. Our agent will call you right away.
          </Heading>
          <InputGroup>
            <InputLeftAddon children="+91" />
            <Input
              value={mobile}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, "");
                const formattedValue = inputValue.slice(0, 10);
                setMobile(formattedValue)
              }}
              type="tel"
              placeholder="Phone Number"
            />
          </InputGroup>
          <Button
            isDisabled={buttonClicked ? true : false}
            colorScheme="green"
            onClick={handleCall}
            minWidth={"100%"}
            marginTop={"10px"}
          >
            Receive a call
          </Button>
          {companyId && companyId.length < 10 ? (
            ""
          ) : (
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              We are waiting for you to enter call back phone number, in next
              <Timer type="call" handleCall={handleAutoCall} time={60} /> seconds
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default TriggerEmergency
