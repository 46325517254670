import axios from "axios";

async function getLatLong(city: string) {
  const apiKey = "AIzaSyCyMav3OTEct_1qw15ZeqxTmdnyS6onYD8"
  try {
    const address = `${city}`;
    const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address,
        key: apiKey,
      },
    });

    if (response.data.results.length > 0) {
      const location = response.data.results[0].geometry.location;
      const lat = location.lat;
      const lng = location.lng;
      return { lat, lng };
    } else {
      return null; // No results found
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

export default getLatLong;