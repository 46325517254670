import * as react from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import hospitalStyle from "./ProfilePreferredHospital.module.css";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { FaRegHospital } from "react-icons/fa";
import { BenDataProps } from '../../../Helper/BenInterface'
import { getHospitalByIdAction } from "../Action/getHospitalAction";


const ProfilePreferredHospital: React.FC<{ data: BenDataProps }> = ({ data }) => {
  const [show, setShow] = useState(false);
  const [hospital, setHospitals] = useState<any>([]);
  const navigate = useNavigate();
  const checksAvailable = () => {
    setShow(false)
    for (let i = 0; i < data?.address?.length; i++) {
      if (data?.address[i].preferredHospital?.length) {
        setShow(true)
        break
      }
    }
  };
  const handleAddHospitalDetails = () => {
    navigate(`/addBenFullDetails/${data._id}/6`);
  };
  const handleEdit = () => {
    navigate(`/addBenFullDetails/${data._id}/6`);
  };

  async function getAllHospital() {
    const promises = data?.address.map(async (el, i) => {
    if(el?.preferredHospital?.[0]?.id!==""){
      try {
        let res = await getHospitalByIdAction(el?.preferredHospital?.[0].id || "");
        return { address: res[0].address };
      } catch (e: any) {
        return null;
      }
    }
    });
// console.log('promises:', promises)
    const resolvedHospitals = await Promise.all(promises);
    // console.log('resolvedHospitals:', resolvedHospitals);
    setHospitals(resolvedHospitals);
  }

  // here i removed empty dependencies from useEffect if any re-render happen need to change this
  useEffect(() => {
    checksAvailable();
    // eslint-disable-next-line
    getAllHospital()
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {data.address.length > 0 ? (
        <react.Box className={hospitalStyle.hospital_wrapper}>
          <react.Box className={hospitalStyle.hospital_container}>
            <react.Box>
              <react.Text className={hospitalStyle.subTitle}>
                Preferred Hospital's
              </react.Text>
            </react.Box>
            {!show ? (
              <react.Box>
                <react.Box
                  onClick={handleAddHospitalDetails}
                  className={hospitalStyle.hospitalCard}
                >
                  <AiOutlinePlus className={hospitalStyle.addHospitalDet} />
                  <react.Text className={hospitalStyle.addHospitalDet}>
                    Add Preferred Hospital
                  </react.Text>
                </react.Box>
              </react.Box>
            ) : (
              data?.address.map((el, i) => {

                return (
                  el.preferredHospital?.[0] ? (
                    <react.Box className={hospitalStyle.hospitals_details} key={i}>
                      <react.Box className={hospitalStyle.left}>
                        <react.Flex align={"center"} gap={"10px"}>
                          <react.Box>
                            <react.Image src="/Images/home.svg" />
                          </react.Box>
                          <react.Box>
                            <react.Text
                              className={hospitalStyle.hospitalAddress}
                            >
                              {el.label}
                            </react.Text>
                          </react.Box>
                        </react.Flex>
                        <react.Box>
                          <react.Text className={hospitalStyle.typeHospital}>
                            {el.addressLine1 || ""}
                          </react.Text>
                        </react.Box>
                        <react.Box>
                          <react.Text>{el.addressLine2 || ""}</react.Text>
                        </react.Box>
                        <react.Box>
                          <react.Text>
                            {el.city || ""} {el.pinCode || ""}
                          </react.Text>
                        </react.Box>
                      </react.Box>
                      <react.Box className={hospitalStyle.left}>
                        <react.Flex align={"center"} gap={"10px"}>
                          <react.Box>
                            <FaRegHospital />
                          </react.Box>
                          <react.Box>
                            <react.Text
                              className={hospitalStyle.hospitalAddress}
                            >{el.preferredHospital[0]?.hospitalName || "Hospital Not Found Add"}</react.Text>
                          </react.Box>
                        </react.Flex>
                        <react.Box>
                          <react.Text className={hospitalStyle.typeHospital}>
                            Km:{el.preferredHospital[0]?.distance || ""}
                          </react.Text>
                        </react.Box>
                        <react.Box>
                        {hospital[i]?(<react.Text className={hospitalStyle.typeHospital}>
                            Address:{hospital[i]?.address || ""}
                          </react.Text>):null}
                        </react.Box>
                      </react.Box>

                    </react.Box>
                  ) : null
                );
              })
            )}
          </react.Box>
          {show && (
            <react.Box className={hospitalStyle.editIcon}>
              <HiOutlinePencilSquare
                onClick={handleEdit}
                color={"#008470"}
                fontSize={"23px"}
              />
            </react.Box>
          )}
        </react.Box>
      ) : null}
    </>
  )
}

export default ProfilePreferredHospital
