import React, { useState } from "react";
import HealthIdInput from "./HealthIdInput";
import {
  InitiateAuthAbhaAction,
  confirmWithAadhaarOtpAction,
  getaAbhaProfileForAadhaarLoginAction,
  resendAuthOtpAadhaarAction,
  saveAbhaDetailsAction,
} from "../../../Actions/beneficiaryDetails.action";
import { ShowAlert } from "../../../../../GlobalComponents/ShowAlert/ShowAlert";
import { formateHealthIdInput } from "../Shared/FormateHealthId";
// import VerifyAadhaarOtp from "../VerifyAadhaarOtp";
import VerifyAadhaarOtpAndHealthId from "./VerifyAadhaarOtpAndHealthId";
import { useParams } from "react-router-dom";
import AdhaarLoginTermCondition from "./AdhaarLoginTermCondition";

const LinkAndLoginByAadhaar = () => {
  const [loading, setLoading] = useState(false);
  const [healthId, setHealthIdInput] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(" "));
  const [isChecked, setIsChecked] = useState(false);

  const { benId } = useParams();
  const [status, setStatusModal] = useState({
    termsConditions: true,
    healthIdInputStatus: false,
    verifyOtpModal: false,
  });
  const handleChangeVerifyOtp = (value: any, index: any) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };
  const handleChangeHelathIdInput = (value: string) => {
    setHealthIdInput(value);
  };
  const handleCheckboxChange = () => {
    setIsChecked(isChecked ? false : true); // Toggle the isChecked state
    console.log(isChecked, "isCHecked *******");
  };
  const handleSubmitHealthIdInput = async () => {
    try {
      setLoading(true);
      const extractNumbersOnly = healthId.replace(/\D/g, "").toString();
      if (extractNumbersOnly.length < 14) {
        return ShowAlert(
          "warning",
          "Please enter 14 digit valid health id number."
        );
      }
      const formatedHealthId = await formateHealthIdInput(extractNumbersOnly);
      const payload = { authMethod: "AADHAAR_OTP", healthid: formatedHealthId };
      const verfiyHealthId = await InitiateAuthAbhaAction(payload);
      localStorage.setItem("txnId", JSON.stringify(verfiyHealthId?.data));
      setStatusModal({
        termsConditions: false,
        healthIdInputStatus: false,
        verifyOtpModal: true,
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log("Error in Verifying HealthId:", error);
      ShowAlert("warning", error.response.data.message);
    }
  };
  const handleSubmitLinkAndVerifyAdhaarOtpAndHealthId = async () => {
    if (otp.length < 6) {
      return ShowAlert("warning", "Fill all the fields");
    }
    if (otp.length === 6) {
      try {
        setLoading(true);
        let parsedTxnId;
        let transactionId = localStorage.getItem("txnId");
        if (transactionId) {
          parsedTxnId = JSON.parse(transactionId);
        }

        await confirmWithAadhaarOtpAction({
          benId: benId,
          otp: otp.join(""),
          txnId: parsedTxnId.txnId,
        });
        // HIT THE PROFILE API THEN TO GET THE PROFILE .
        let getProfile = await getaAbhaProfileForAadhaarLoginAction(benId);
        const abhaDetails = {
          abhaLinked: true,
          beneficiaryId: benId,
          abhaMobileNumber: getProfile?.data?.mobile,
          abhaHealthId: getProfile?.data?.healthId,
          abhaHealthNumber: getProfile?.data?.healthIdNumber,
          abhaAddress: getProfile?.data?.healthId,
          txnId: parsedTxnId?.txnId,
        };
        await saveAbhaDetailsAction(abhaDetails);
        setLoading(false);
        ShowAlert(
          "success",
          "Your ABHA account has been linked with Pococare successfully."
        );
        window.location.reload();
        return;
      } catch (error: any) {
        setLoading(false);
        return ShowAlert("warning", error.response.data.message);
      }
    }
  };
  const handleResendAuthOtpAadhaar = async () => {
    try {
      let parsedTxnId;
      const transactionId = localStorage.getItem("txnId");
      if (transactionId) {
        parsedTxnId = JSON.parse(transactionId);
      }
      const authPayload = {
        authMethod: "AADHAAR_OTP",
        txnId: parsedTxnId?.txnId,
      };
      await resendAuthOtpAadhaarAction(authPayload);
      ShowAlert(
        "success",
        "Resend OTP successfully to Aadhaar linked mobile no.. ."
      );
      // return response.data;
    } catch (error: any) {
      return ShowAlert("warning", error.response.data.message);
    }
  };
  return (
    <div>
      {status.termsConditions && (
        <AdhaarLoginTermCondition
          handleCheckboxChange={handleCheckboxChange}
          isChecked={isChecked}
          setStatusModal={setStatusModal}
        />
      )}
      {status.healthIdInputStatus && (
        <HealthIdInput
          healthId={healthId}
          loading={loading}
          setStatusModal={setStatusModal}
          handleSubmitHealthIdInput={handleSubmitHealthIdInput}
          handleChangeHelathIdInput={handleChangeHelathIdInput}
        />
      )}
      {status.verifyOtpModal && (
        <VerifyAadhaarOtpAndHealthId
          otp={otp}
          loading={loading}
          setStatusModal={setStatusModal}
          handleResendAuthOtpAadhaar={handleResendAuthOtpAadhaar}
          handleChangeVerifyOtp={handleChangeVerifyOtp}
          handleSubmitLinkAndVerifyAdhaarOtpAndHealthId={
            handleSubmitLinkAndVerifyAdhaarOtpAndHealthId
          }
        />
      )}
    </div>
  );
};

export default LinkAndLoginByAadhaar;
