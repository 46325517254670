import {
    Box,
    CircularProgress,
    CircularProgressLabel,
    Text,
  
} from "@chakra-ui/react";
import React from "react";
import style from "./MidCard.module.css";
interface MidCardProps{
    percentage:number
    content:string
}
const MidCard: React.FC<MidCardProps> = ({ percentage, content }) => {
    // const [isLargerThan1024] = useMediaQuery("(min-width: 1440px)");
    // const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

    return (
        <>
            <Box className={style.wrapper} >
                <Box className={style.child}>
                    <Box display={"flex"} alignItems={"center"} w={"100%"}>
                        <CircularProgress
                            size={"60%"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            value={percentage}
                            color="#c31515"
                        >
                            <CircularProgressLabel
                                display={ "flex"}
                                alignItems={ "center"}
                                justifyContent={ "center"}
                                fontWeight={400}
                                fontFamily="'Poppins', sans-serif"
                                fontSize={ "1.25rem"}
                            // className={style.percentage}
                            >
                                {percentage}
                                {"%"}
                            </CircularProgressLabel>
                        </CircularProgress>
                    </Box>
                    <Box   display={"flex"} alignItems={"center"}>
                        <Text className={style.content_Text}>{content}</Text>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default MidCard;
