import { contactUs } from "../../../http"

export const sendContactUs=async(data:any)=>{
    try{
        const res=await contactUs(data);
        console.log('res:', res)
        return res.data;
    }catch(e){
        console.log('e:', e)
        throw e;

    }


}