import { Box, Heading } from "@chakra-ui/react";
import Navbar from "../../GlobalComponents/Navbar/Navbar";
import Footer from "../../GlobalComponents/Footer/Footer";
import style from "./TermsAndConditions.module.css"
import TermsAndConitionText from "./TermsAndConitionText";

const TermsAndConditions = () => {
  return (
    <>
      <Navbar />
      <Box className={style.wrapper}>
        <Box className={style.container}>
          <Heading className={style.primary_Color}>
            Terms and Conditions
          </Heading>
            <TermsAndConitionText/>
        </Box>
      </Box>
      {/* <Box> */}
        <Footer />
      {/* </Box> */}
    </>
  );
};

export default TermsAndConditions;
