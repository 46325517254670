import {
  Box,
  Checkbox,
  Stack,
} from "@chakra-ui/react";
const TermsAndConditions = ({ isChecked, handleCheckboxChange }: any) => {
  return (
    <Box>
      <Box overflowY="scroll" p={0}>
      <iframe
            title="Terms and Conditions"
            src={ "/TermsAndConditionForAbha.html"}
            style={{ width: "100%", height: "450px" }}
          ></iframe>
      </Box>
      <Box mt={0.5}>
        <Stack padding={0.5} spacing={1} direction="row">
          <Checkbox
            ml={3.5}
            colorScheme="pink"
            defaultChecked={isChecked}
            onChange={handleCheckboxChange}
          >
            I agree terms & condtions
          </Checkbox>
        </Stack>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
