import {
  InitiateAuthAbha,
  addBeneficiaryAddress,
  addBeneficiaryEmergencyContact,
  addBeneficiaryInsurance,
  authorizedTokenForLogin,
  checkAndGenerateMobileOtp,
  confirmWithAadhaarOtp,
  createAbhaCareContext,
  createHealthIdWithPreVerified,
  createHospital,
  deleteBeneficiaryAddress,
  deleteBeneficiaryEmergencyContact,
  deleteBeneficiaryInsurance,
  generateAdhaarOtp,
  generateLoginMobileOtp,
  getAbhaCardInPng,
  getAbhaDetailsFromPococareAcc,
  getAbhaProfile,
  getAllBeneficiariesData,
  getAzureSignedUrl,
  getBenCareContexts,
  getMedicalInfo,
  getNearByHospital,
  getPocoAbhaDocument,
  getSingleBenData,
  getaAbhaProfileForAadhaarLogin,
  handleConfirmAbhaLoginOtp,
  handleSendAbhaLoginOtp,
  resendAdhaarOtp,
  resendAuthOtpAadhaar,
  resenedLoginOtp,
  resenedPrimaryMobileOtp,
  saveAbhaDetails,
  saveMedicalInfo,
  setPocoMedicalDocument,
  updateAbhaPocoDoc,
  updateBeneficiaryAddress,
  updateBeneficiaryEmergencyContact,
  updateBeneficiaryInsurance,
  updateBeneficiaryPersonalInfo,
  verifyAdhaarOtp,
  verifyMobileOtp,
  verifyPrimaryMobileOtp,
} from "../../../http";
import axios from "axios";
import { base64StringToBlob } from "blob-util";

const getSingleBeneficiaryData = async (id: string) => {
  let beneficiaryData;
  try {
    beneficiaryData = await getSingleBenData(id);
    return beneficiaryData.data.data;
  } catch (error) {
    console.log("error in beneficiary data action", error);
    throw error;
  }
};

const getSignedUrl = async (
  docName: string,
  benId: string,
  docType: string
) => {
  try {
    const signedUrl = await getAzureSignedUrl(docName, benId, docType);
    return signedUrl.data.data;
  } catch (error) {
    throw error;
  }
};

const uploadFileToAzure = async (signedUrl: string, files: any) => {
  const contentType = files.fileList[0].type;
  const b64Data = files.base64.split(",")[1];
  const blob = base64StringToBlob(b64Data, contentType);
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: signedUrl,
      headers: {
        "x-ms-blob-type": "BlockBlob",
        "x-ms-date": new Date().toUTCString(),
        ContentEncoding: "base64",
        "Content-Type": contentType,
      },
      data: blob,
    };

    const response = await axios.request(config);
    console.log(response);
    return signedUrl.split("?")[0];
  } catch (error) {
    throw error;
  }
};

const updatePersonalInfo = async (
  benId: string,
  payload: any
): Promise<void> => {
  try {
    let response = await updateBeneficiaryPersonalInfo(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const uploadFile = async (
  beneficiaryId: string,
  files: any,
  fileType: string
) => {
  try {
    let signedUrlResponse = await getSignedUrl(
      files.fileList[0].name,
      beneficiaryId,
      fileType
    );
    let signedUrl = signedUrlResponse.data.signedUrl;
    let uploadToAzureResponse = await uploadFileToAzure(signedUrl, files);
    return uploadToAzureResponse;
  } catch (error) {
    throw error;
  }
};

const deleteAddressAction = async (benId: string, addressId: string) => {
  try {
    let response = await deleteBeneficiaryAddress(benId, addressId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const addAddressAction = async (benId: string, payload: any) => {
  try {
    let response = await addBeneficiaryAddress(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateAddressAction = async (benId: string, payload: any) => {
  try {
    let response = await updateBeneficiaryAddress(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const addEmergencyContact = async (benId: string, payload: any) => {
  try {
    let response = await addBeneficiaryEmergencyContact(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteEmergencyContact = async (benId: string, payload: any) => {
  try {
    let response = await deleteBeneficiaryEmergencyContact(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateEmergencyContact = async (benId: string, payload: any) => {
  try {
    let response = await updateBeneficiaryEmergencyContact(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const addBenInsurance = async (benId: string, payload: any) => {
  try {
    let response = await addBeneficiaryInsurance(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteBenInsurance = async (benId: string, policyId: string) => {
  try {
    let response = await deleteBeneficiaryInsurance(benId, policyId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateBenInsurance = async (benId: string, payload: any) => {
  try {
    let response = await updateBeneficiaryInsurance(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  let month = now.getMonth() + 1; // Months are 0-indexed
  let day = now.getDate();

  // Add leading zeros to single-digit months and days
  if (month < 10) {
    // @ts-ignore
    month = `0${month}`;
  }
  if (day < 10) {
    // @ts-ignore
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};

const getNearByHospitals = async (payload: any) => {
  try {
    let response = await getNearByHospital(payload);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const earthRadius = 6371;

  const radLat1 = (lat1 * Math.PI) / 180;
  const radLon1 = (lon1 * Math.PI) / 180;
  const radLat2 = (lat2 * Math.PI) / 180;
  const radLon2 = (lon2 * Math.PI) / 180;

  const dLat = radLat2 - radLat1;
  const dLon = radLon2 - radLon1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(radLat1) *
      Math.cos(radLat2) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  const distance = earthRadius * c;

  return distance;
}

const createHospitalData = async (payload: any) => {
  try {
    let response = await createHospital(payload);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const createMedicalInfo = async (benId: string, payload: any) => {
  try {
    let response = await saveMedicalInfo(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getMedicalInfoAction = async (benId: string) => {
  try {
    let response = await getMedicalInfo(benId);
    console.log("response in medical info action", response.data[0]);
    return response.data[0];
  } catch (error) {
    throw error;
  }
};

const getAllAddresses = async (subId: string) => {
  try {
    let data = await getAllBeneficiariesData(subId);
    let subData = data.data.data;

    let addresses: any = [];

    for (let i = 0; i < subData.length; i++) {
      if (subData[i]?.address) {
        for (let j = 0; j < subData[i].address.length; j++) {
          if (subData[i]?.address[j]?.label) {
            addresses.push(subData[i].address[j]);
          }
        }
      }
    }

    // return unique addresses using addressLine1
    addresses = addresses.reduce((unique: any, address: any) => {
      if (!unique.find((item: any) => item.addressLine1 === address.addressLine1)) {
        unique.push(address);
      }
      return unique;
    }, []);

    return addresses;
  } catch (error) {
    throw error;
  }
};

const generateAdhaarOtpAction = async (aadhaarPayload: any) => {
  try {
    let response = await generateAdhaarOtp(aadhaarPayload);
    return response;
  } catch (error: any) {
    throw error;
  }
};

const resendAdhaarOtpAction = async (trxnIdPayload: any) => {
  try {
    let response = await resendAdhaarOtp(trxnIdPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

const resenedPrimaryMobileOtpAction = async (trxnIdPayload: any) => {
  try {
    let response = await resenedPrimaryMobileOtp(trxnIdPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

const verifyAdhaarOtpAction = async (OtpTrxnIdPayload: any) => {
  try {
    let response = await verifyAdhaarOtp(OtpTrxnIdPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

const checkAndGenerateMobileOtpAction = async (MobileNoTrxnIdPayload: any) => {
  try {
    let response = await checkAndGenerateMobileOtp(MobileNoTrxnIdPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

const createHealthIdWithPreVerifiedAction = async (userDataPayload: any) => {
  try {
    let response = await createHealthIdWithPreVerified(userDataPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

const generateLoginMobileOtpAction = async (userDataPayload: any) => {
  try {
    let response = await generateLoginMobileOtp(userDataPayload);
    return response;
  } catch (error) {
    throw error;
  }
};
const verifyMobileOtpAction = async (userDataPayload: any) => {
  try {
    let response = await verifyMobileOtp(userDataPayload);
    return response;
  } catch (error) {
    throw error;
  }
};
const verifyPrimaryMobileOtpAction = async (userDataPayload: any) => {
  try {
    let response = await verifyPrimaryMobileOtp(userDataPayload);
    return response;
  } catch (error) {
    throw error;
  }
};



const saveAbhaDetailsAction = async (abhaPayload: any) => {
  try {
    let response = await saveAbhaDetails(abhaPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

const authorizedTokenForLoginAction = async (payload: any) => {
  try {
    let response = await authorizedTokenForLogin(payload);
    return response;
  } catch (error) {
    throw error;
  }
};
const getAbhaProfileAction = async (id: any) => {
  try {
    let response = await getAbhaProfile(id);
    return response;
  } catch (error) {
    throw error;
  }
};
const getAbhaCardInPngAction = async (id: any) => {
  try {
    let response = await getAbhaCardInPng(id);
    return response;
  } catch (error) {
    throw error;
  }
};
const getAbhaDetailsFromPococareAccAction = async (id: any) => {
  try {
    let response = await getAbhaDetailsFromPococareAcc(id);
    return response;
  } catch (error) {
    throw error;
  }
};
const getPocoAbhaDocumentAction = async (id: any) => {
  try {
    let response = await getPocoAbhaDocument(id);
    return response;
  } catch (error) {
    throw error;
  }
};

const setPocoMedicalDocumentAction = async (id: any)=>{
  try {
    let response = await setPocoMedicalDocument(id);
    return response;
  } catch (error) {
    throw error;
  }
}

const createAbhaCareContextAction = async (data: any)=>{
  try {
    let response = await createAbhaCareContext(data);
    return response;
  } catch (error) {
    throw error;
  }
}

const handleSendAbhaLoginOtpAction = async (data: any)=>{
  try {
    let response = await handleSendAbhaLoginOtp(data);
    return response;
  } catch (error) {
    throw error;
  }
}

const handleConfirmAbhaLoginOtpAction = async (data: any)=>{
  try {
    let response = await handleConfirmAbhaLoginOtp(data);
    return response;
  } catch (error) {
    throw error;
  }
}

const updateAbhaPocoDocAction = async (benId: any,docId:any)=>{
  try {
    let response = await updateAbhaPocoDoc(benId,docId);
    return response;
  } catch (error) {
    throw error;
  }
}

const getBenCareContextsAction = async (benId: any)=>{
  try {
    let response = await getBenCareContexts(benId);
    return response;
  } catch (error) {
    throw error;
  }
}

const getSingleBenDataAction = async (id: any) => {
  try {
    let response = await getSingleBenData(id);
    return response;
  } catch (error) {
    throw error;
  }
};

const InitiateAuthAbhaAction = async (healthIdPayload: any) => {
  try {
    let response = await InitiateAuthAbha(healthIdPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

const confirmWithAadhaarOtpAction = async (OtpPayload: any) => {
  try {
    let response = await confirmWithAadhaarOtp(OtpPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

const getaAbhaProfileForAadhaarLoginAction = async (benId: any) => {
  try {
    let response = await getaAbhaProfileForAadhaarLogin(benId);
    return response;
  } catch (error) {
    throw error;
  }
};

const resendAuthOtpAadhaarAction = async (AuthPayload: any) => {
  try {
    let response = await resendAuthOtpAadhaar(AuthPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

const resenedLoginOtpAction = async (trxnIdPayload: any) => {
  try {
    let response = await resenedLoginOtp(trxnIdPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  handleConfirmAbhaLoginOtpAction,
  handleSendAbhaLoginOtpAction,
  updateAbhaPocoDocAction,
  getBenCareContextsAction,
  createAbhaCareContextAction,
  setPocoMedicalDocumentAction,
  getPocoAbhaDocumentAction,
  resenedLoginOtpAction,
  resendAuthOtpAadhaarAction,
  getaAbhaProfileForAadhaarLoginAction,
  confirmWithAadhaarOtpAction,
  InitiateAuthAbhaAction,
  resenedPrimaryMobileOtpAction,
  getSingleBenDataAction,
  verifyPrimaryMobileOtpAction,
  getAbhaDetailsFromPococareAccAction,
  getAbhaCardInPngAction,
  getAbhaProfileAction,
  authorizedTokenForLoginAction,
  saveAbhaDetailsAction,
  verifyMobileOtpAction,
  generateLoginMobileOtpAction,
  createHealthIdWithPreVerifiedAction,
  checkAndGenerateMobileOtpAction,
  verifyAdhaarOtpAction,
  resendAdhaarOtpAction,
  generateAdhaarOtpAction,
  getSingleBeneficiaryData,
  getSignedUrl,
  uploadFileToAzure,
  updatePersonalInfo,
  uploadFile,
  deleteAddressAction,
  addAddressAction,
  updateAddressAction,
  addEmergencyContact,
  deleteEmergencyContact,
  updateEmergencyContact,
  addBenInsurance,
  deleteBenInsurance,
  updateBenInsurance,
  getCurrentDate,
  getNearByHospitals,
  getDistanceFromLatLonInKm,
  createHospitalData,
  createMedicalInfo,
  getMedicalInfoAction,
  getAllAddresses,
};
