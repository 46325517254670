import { addBeneficiaryAddress } from "../../../http"

export const createAddress=async(benId:string,addressData:any)=>{

try{
    const res=await addBeneficiaryAddress(benId,addressData)
    return res.data
}catch(e){
    throw e;

}

}