import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BenDataProps } from "../../Helper/BenInterface";
interface MyComponentProps {
    children: any;
}
// interface SubscriberData {
//     subscriber: BenDataProps
// }
interface BeneficiaryData {
    beneficiary: BenDataProps[]
}
const GuestRoute: React.FC<MyComponentProps> = ({ children }) => {
    // const subscriberData = useSelector((state: SubscriberData) => state.subscriber);
    const beneficiaryData = useSelector((state: BeneficiaryData) => state.beneficiary);
    // console.log('fromGustROute:', beneficiaryData);
    // checking beneficiary
    if (beneficiaryData.length === 1) {
        return <Navigate to={"/addBeneficiaries"} />;
    }

    // checking subscriber data
    // if (Object.keys(subscriberData).length) {
    //     return <Navigate to={"/dashboard"} />;
    // }

    return children;
};

export default GuestRoute;
