import {
  Box,
  Button,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { useState } from "react";
import { ShowAlert } from "../../../../GlobalComponents/ShowAlert/ShowAlert";
import {
  createAbhaCareContextAction,
  handleSendAbhaLoginOtpAction,
  updateAbhaPocoDocAction,
} from "../../Actions/beneficiaryDetails.action";
import AbhaLogin from "./AbhaLogin/AbhaLogin";
import { DataItem } from "./Shared/DataItem";
import {
  docTypeColorMapping,
  docType,
  documentTypeMapping,
  hiType,
  tabData,
  tableHead,
} from "./Shared/Common";
import { generateSaSToken } from "../../../../Helper/sasToken";
import { useParams } from "react-router-dom";

const AbhaDetails = ({
  abhaDetails,
  pocoMedicalDocDetails,
  careContextDetails,
  fetchBenCareContexts,
}: any) => {
  const { benId } = useParams();
  const [sessionStatus, setSessionStatus] = useState(false);
  const [tab, setTab] = useState(1);
  const abha = abhaDetails;

  const switchTabs = async (id: string) => {
    if (+id === 2) {
      await fetchBenCareContexts(benId);
    }
    setTab(+id);
  };
  const getSasToken = () => {
    let sasTokenFromLS = localStorage.getItem("sasToken");
    if (!sasTokenFromLS) {
      generateSaSToken();
      sasTokenFromLS = localStorage.getItem("sasToken");
    }
    return sasTokenFromLS;
  };

  const handleViewDocument = (documentURL: string) => {
    window.open(documentURL + "?" + getSasToken(), "_blank");
  };

  const handleSendAbhaLoginOtp = async (abhaDetails: any) => {
    try {
      const prepareData = {
        query: {
          id: abhaDetails.abhaAddress,
          purpose: "KYC_AND_LINK",
          authMode: "MOBILE_OTP",
          requester: {
            type: "HIP",
            id: "poco-hip-2024",
          },
        },
      };
      console.log(prepareData, "prepareData");
      await handleSendAbhaLoginOtpAction(prepareData);
      ShowAlert(
        "success",
        "ABHA login otp sent to your linked mobile successfully."
      );
    } catch (error) {
      console.log(error, "error....");
    }
  };

  const handleUpload = async (item: any) => {
    try {
      const setMedicalData = {
        abhaId: abha.abhaAddress,
        benId: abha.beneficiaryId,
        patientDisplay: item.docType,
        careContextDisplay: item.description,
        documentURL: item.docURL,
        hiType: hiType[documentTypeMapping[item.docType]],
      };
      let res = await createAbhaCareContextAction(setMedicalData);
      console.log(res, "*******createAbhaCareContextAction*********");
      if (res.status === 201) {
        let resDoc = await updateAbhaPocoDocAction(item.benId, item.docId);
        console.log(resDoc, "updateAbhaPocoDocAction");
        return ShowAlert("success", "Successfully Added Care context to ABHA");
      }
      console.log(res, "********* Response ********");
      return;
    } catch (error: any) {
      console.log(
        error?.response?.data?.message,
        "<<<<<<<Abha Error from handleUpload optionss>>>>>>>..."
      );
      console.log(error);
      if (error?.response?.data?.statusCode === 500) {
        setSessionStatus(sessionStatus ? true : true);
        handleSendAbhaLoginOtp(abhaDetails);
      }
      ShowAlert("warning", "Unexpected error please try again later...");
    }
  };

  return (
    <Box>
      {sessionStatus && (
        <AbhaLogin
          abhaDetails={abhaDetails}
          sessionStatus={sessionStatus}
          setSessionStatus={setSessionStatus}
        />
      )}
      <Box>
        <Box
          borderRadius="6px"
          border="1px solid rgba(51, 51, 51, 0.60)"
          justifyContent={"center"}
          alignItems={"center"}
          background="#FFF"
          p={4}
          display={"flex"}
          maxWidth={"942px"}
          height={"209px"}
        >
          <Box
            marginRight={"43px"}
            marginLeft={"56px"}
            backgroundImage="url('https://developmentbucket.blob.core.windows.net/logos/abhaLogo.png')"
            backgroundPosition="50%"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            width="146px"
            height={"145.115px"}
          ></Box>
          <Box
            justifyContent="center"
            alignItems="center"
            width="calc(100% - 197px)"
            p={4}
          >
            <DataItem label="Mobile Number:" value={abha.abhaMobileNumber} />
            <DataItem label="Health ID:" value={abha.abhaHealthId} />
            <DataItem label="Health Number:" value={abha.abhaHealthNumber} />
            <DataItem label="Abha Address:" value={abha.abhaAddress} />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          display={"flex"}
          maxWidth={"942px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          height={"85px"}
          borderBottom="2px solid #00A7B5"
          background="#FFF"
          mt={4}
        >
          {tabData.map((data: any) => (
            <Box
              onClick={() => switchTabs(data.id)}
              display={"flex"}
              bgColor={`${
                tab === data.id ? "rgba(210, 240, 242, 0.30)" : "white"
              }`}
              textAlign={"left"}
              alignItems={"center"}
              height={"85px"}
              width={"450px"}
            >
              <Text
                ml={6}
                color={`${
                  tab === data.id ? "#00A7B5" : "rgba(51, 51, 51, 0.70)"
                }`}
                textAlign={"center"}
                fontFamily="Poppins"
                fontSize="22px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="24px"
                letterSpacing="1.56px"
                sx={{
                  fontVariantNumeric: "lining-nums tabular-nums",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                }}
              >
                {data.tabName}
              </Text>
            </Box>
          ))}
        </Box>
        <Box width={"1092"} maxH={"200px"} flexShrink={"0"}>
          <Table variant="simple">
            <Thead>
              <Tr height={"75px"}>
                {tableHead.map((val) => (
                  <Th
                    color="rgba(51, 51, 51, 0.90)"
                    fontFamily="Poppins"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="24px"
                    letterSpacing="1.08px"
                  >
                    {val}
                  </Th>
                ))}
              </Tr>
            </Thead>
            {tab === 1 && (
              <Tbody>
                {pocoMedicalDocDetails?.length > 0 &&
                  pocoMedicalDocDetails.map((item: any, index: number) => (
                    <Tr key={index}>
                      <Td>
                        <Flex align="center">
                          <Box
                            bg={docType[item.docType].color}
                            borderRadius="6px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            minW="180px"
                            height="56px"
                          >
                            <Text
                              color="#FFF"
                              fontFamily="Poppins"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="400"
                              lineHeight="24px"
                              letterSpacing="1.2px"
                              sx={{
                                fontVariantNumeric: "lining-nums tabular-nums",
                                fontFeatureSettings: "'clig' off, 'liga' off",
                              }}
                            >
                              {docType[item.docType].key}
                            </Text>
                          </Box>
                        </Flex>
                      </Td>
                      <Td>
                        <Box ml={0.1}>
                          <Text
                            maxW={"294px"}
                            color="rgba(51, 51, 51, 0.80)"
                            fontFamily="Poppins"
                            fontSize="12px"
                            fontWeight="300"
                            lineHeight="16.882px"
                            letterSpacing="0.96px;"
                          >
                            {item.description}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        <Text>
                          {item.pubDate ? item.pubDate.slice(0, 10) : "NA"}
                        </Text>
                      </Td>
                      <Td>
                        <Button
                          onClick={() => handleUpload(item)}
                          isDisabled={item.isPubToAbha}
                          color={`${item.pubStatus ? "#29A972" : "#385BF0"}`}
                          fontFamily="Poppins"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="500"
                          lineHeight="24px"
                          letterSpacing="0.96px;"
                          borderRadius="20px"
                          padding="4px 24px"
                          border={`${
                            item.isPubToAbha
                              ? "1px solid #29A972"
                              : "1px solid #385BF0"
                          }`}
                          background={`${
                            item.isPubToAbha
                              ? "rgba(41, 169, 114, 0.20)"
                              : "rgba(56, 91, 240, 0.20)"
                          }`}
                        >
                          {item.isPubToAbha ? "Published" : "Publish"}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                {(!pocoMedicalDocDetails ||
                  pocoMedicalDocDetails.length === 0) && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="10vh"
                  >
                    <Text fontSize="lg" color="gray.500">
                      No documents found..
                    </Text>
                  </Box>
                )}
              </Tbody>
            )}

            {tab === 2 && (
              <Tbody>
                {careContextDetails?.length > 0 &&
                  careContextDetails.map((item: any, index: number) => (
                    <Tr key={index}>
                      <Td>
                        <Flex align="center">
                          <Box
                            bg={
                              item?.hiType?.display
                                ? docTypeColorMapping[
                                    item?.hiType?.display?.split(" ").join("")
                                  ]
                                : "rgba(240, 56, 156, 0.80)"
                            }
                            borderRadius="6px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            minW="180px"
                            height="56px"
                          >
                            <Text
                              color="#FFF"
                              fontFamily="Poppins"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="400"
                              lineHeight="24px"
                              letterSpacing="1.2px"
                              sx={{
                                fontVariantNumeric: "lining-nums tabular-nums",
                                fontFeatureSettings: "'clig' off, 'liga' off",
                              }}
                            >
                              {item?.hiType?.display}
                            </Text>
                          </Box>
                        </Flex>
                      </Td>
                      <Td>
                        <Box ml={0.1}>
                          <Text
                            maxW={"294px"}
                            color="rgba(51, 51, 51, 0.80)"
                            fontFamily="Poppins"
                            fontSize="12px"
                            fontWeight="300"
                            lineHeight="16.882px"
                            letterSpacing="0.96px;"
                          >
                            {item.careContextDisplay}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        <Text>{item.updatedAt.slice(0, 10)}</Text>
                      </Td>
                      <Td>
                        <Button
                          onClick={() => handleViewDocument(item.documentURL)}
                          color={`${item.finalStatus ? "#29A972" : "#385BF0"}`}
                          fontFamily="Poppins"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="500"
                          lineHeight="24px"
                          letterSpacing="0.96px;"
                          borderRadius="20px"
                          padding="4px 24px"
                          border={`${
                            item.finalStatus
                              ? "1px solid #29A972"
                              : "1px solid #385BF0"
                          }`}
                          background={`${
                            item.finalStatus
                              ? "rgba(41, 169, 114, 0.20)"
                              : "rgba(56, 91, 240, 0.20)"
                          }`}
                        >
                          {item.finalStatus ? "View" : "Request"}{" "}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                {(!careContextDetails || careContextDetails.length === 0) && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="10vh"
                  >
                    <Text fontSize="lg" color="gray.500">
                      No documents found..
                    </Text>
                  </Box>
                )}
              </Tbody>
            )}
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

export default AbhaDetails;

// ########################## OLD CODE FOR DISPLAYING ABHA DETAILS OF THE USER ############################

/* <Box>
<Flex
  maxW="1092px"
  borderWidth="2px"
  flexDir={{ base: "column", xl: "row" }}
  // paddingBottom={{ base: "12px", xl: "0" }}
  // borderRadius="xl"
  overflow="hidden"
  justifyContent="center"
  alignItems="center"
  // bg="#f5f9f9"
  sx={{
    borderRadius: "0.375rem",
    border: "1px solid rgba(51, 51, 51, 0.60)",
    background: "#FFF"
  }}
>
  <Box>
    {" "}
    <Image
      width="197px"
      src="https://developmentbucket.blob.core.windows.net/logos/abhaLogo.png"
    />{" "}
  </Box>
  <Box>
    <VStack >
      <Text sx={{
      color: "rgba(51, 51, 51, 0.70)",
      fontVariantNumeric: "lining-nums tabular-nums",
      // fontFeatureSettings: 'clig' off, 'liga' off,
      fontFamily: "Poppins",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.5rem",
      letterSpacing: "0.0675rem"
    }}>
        Mobile Number: {abha.abhaMobileNumber}
      </Text>
      <Text sx={{
      color: "rgba(51, 51, 51, 0.70)",
      fontVariantNumeric: "lining-nums tabular-nums",
      // fontFeatureSettings: 'clig' off, 'liga' off,
      fontFamily: "Poppins",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.5rem",
      letterSpacing: "0.0675rem"
    }}>
        Health ID: {abha.abhaHealthId}
      </Text>
      <Text sx={{
      color: "rgba(51, 51, 51, 0.70)",
      fontVariantNumeric: "lining-nums tabular-nums",
      // fontFeatureSettings: 'clig' off, 'liga' off,
      fontFamily: "Poppins",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.5rem",
      letterSpacing: "0.0675rem"
    }}>
        Health Number: {abha.abhaHealthNumber}
      </Text>
      <Text sx={{
      color: "rgba(51, 51, 51, 0.70)",
      fontVariantNumeric: "lining-nums tabular-nums",
      // fontFeatureSettings: 'clig' off, 'liga' off,
      fontFamily: "Poppins",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.5rem",
      letterSpacing: "0.0675rem"
    }}>ABHA Address: {abha.abhaAddress}</Text>
    </VStack>
  </Box>
</Flex>
</Box> */

// DUMMY DATA HERE.............................
// const medicalData = [
//   {
//     label: "Recent Hospital",
//     description:
//       "Allergic to sulpha drugs, sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs.",
//     date: "24/06/24",
//     status: "Published",
//     bgColor: "rgba(0, 167, 181, 0.80)",
//     pubStatus: true,
//   },
//   // border-radius: 6px;
//   {
//     label: "Prescription",
//     description:
//       "Allergic to sulpha drugs to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs.",
//     date: "24/06/24",
//     status: "Publish",
//     bgColor: "rgba(240, 56, 156, 0.80);",
//     pubStatus: false,
//   },
//   {
//     label: "Past Surgeries",
//     description:
//       "Allergic to sulpha drugs to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs.",
//     date: "24/06/24",
//     status: "Publish",
//     bgColor: "rgba(56, 91, 240, 0.80);",
//     pubStatus: false,
//   },
// ];
// const abhaMedicalData = [
//   {
//     label: "Lab Report",
//     description:
//       "Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs.",
//     date: "24/06/24",
//     status: "View",
//     bgColor: "rgba(0, 167, 181, 0.80)",
//     pubStatus: true,
//   },
//   // border-radius: 6px;
//   {
//     label: "Prescription",
//     description:
//       "Allergic to sulpha drugs, , Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs.",
//     date: "24/06/24",
//     status: "Request",
//     bgColor: "rgba(240, 56, 156, 0.80);",
//     pubStatus: false,
//   },
//   {
//     label: "Lab Report",
//     description:
//       "Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs, Allergic to sulpha drugs.",
//     date: "24/06/24",
//     status: "View",
//     bgColor: "rgba(56, 91, 240, 0.80);",
//     pubStatus: false,
//   },
// ];
