/**
 * @param name Take the param as user name .
 * @returns three different usernames for abha creation .
 */
export const suggestThreeUserName = (name: string) => {
  let sugesstedName = [];
  if (name.length > 8) {
    name = name.slice(0, 8);
  }
  let keyWords = ["hlth", "abha", ".", "_"];
  for (let i = 0; i < 3; i++) {
    let randomNo = Math.floor(Math.random() * 60000);
    let keyInd = Math.floor(Math.random() * 4);
    // console.log(keyInd, randomNo);
    let newName = name.toLowerCase() + keyWords[keyInd] + randomNo;
    if (newName.length > 18) {
      newName = newName.slice(0, 17);
    }
    sugesstedName.push(newName);
  }
  return sugesstedName;
};
