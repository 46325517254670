import { getAllBeneficiariesData } from "../../../http";
//need to refactor this api call///
export const getBeneficiaryData = async (subId: string) => {
  try {
    const { data } = await getAllBeneficiariesData(subId);
    console.log("data:", data);
    if (data.data.length) {
      return data;
      // navigate("/home");
    }
  } catch (e:any) {
    throw e
  }
};
