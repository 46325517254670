import { raiseEmergencyWithCompanyId } from "../../../http";

export const raiseEmergencyWithCompanyIdAction = async (companyId:string,mobile:any,lat:string|null,lng:string|null) => {
    
    try {

       let data = await raiseEmergencyWithCompanyId(companyId,mobile,lat,lng)
       return data;

    } catch (error:any) {

      throw error;
      
    }
  };
  