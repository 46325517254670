import { Box, Heading, Text } from "@chakra-ui/react";

const LinkAbhaInfo = () => {
  return (
    <Box mb={4}>
      <Heading padding={2} as="h4" size="md">
        Link Existing ABHA
      </Heading>
      <Text p={2} fontWeight={500}>
        Existing ABHA ID can be linked with phone and OTP. After phone
        validation, list of ABHA ID linked to phone is displayed. User can
        select the ABHA ID to link with Pococare Beneficiary. Existing ABHA ID
        can be directly linked with ABHA ID by validating with associated Aadhar
        ID based OTP. Click second button for this option.
      </Text>
    </Box>
  );
};

export default LinkAbhaInfo;
