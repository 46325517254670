import {
    Box,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { createHospitalData, getDistanceFromLatLonInKm } from "../../Actions/beneficiaryDetails.action";
import { ShowAlert } from "../../../../GlobalComponents/ShowAlert/ShowAlert";
import { updateBeneficiaryAddress } from "../../../../http";

type Address = {
    location: any;
    _id: any;
    // Define the type for your address here
};

const FindHospital: React.FC<{
    closeModal: () => void;
    addresses: Address[];
    data: any;
    selectedAddress: Address;
    setData: (data: any) => void;
}> = ({
    closeModal,
    data,
    selectedAddress,
}) => {
        const [showDropdown, setShowDropdown] = useState(false);
        const [lat, setLat] = useState<string>("");
        const [lng, setLng] = useState<string>("");
        const [hospitalAddress, setHospitalAddress] = useState<string>("");
        const [hospitalName, setHospitalName] = useState<string>("");
        const [cityName, setCityName] = useState<string>("");
        const [keyword, setKeyword] = useState<string>("");

        const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState<any[]>([]);
        const initialRef = React.useRef(null);
        const finalRef = React.useRef(null);
        const navigate = useNavigate();

        const handleSelectHospital = (suggestion: any) => {
            const placeId = suggestion.place_id;
            const service = new window.google.maps.places.PlacesService(document.createElement("div"));

            // Fetch place details using placeId
            service.getDetails(
                { placeId: placeId, fields: ["geometry.location"] },
                (place, status) => {
                    if (status === "OK" && place && place.geometry && place.geometry.location) {
                        setKeyword(suggestion.description);
                        setHospitalAddress(suggestion.structured_formatting.secondary_text);
                        setHospitalName(suggestion.structured_formatting.main_text);
                        setLat(place.geometry.location.lat().toString());
                        setLng(place.geometry.location.lng().toString());
                        setShowDropdown(false); // Hide the dropdown after selecting a hospital
                    } else {
                        // Handle error or show a message that details couldn't be fetched
                    }
                }
            );
        };

        const handleAutoComplete = async (keyWord: string) => {
            setShowDropdown(true);
            const service = new window.google.maps.places.AutocompleteService();
            const request = {
                input: cityName + "" + keyWord,
                types: ["hospital"], // Restrict the results to hospitals
            };

            service.getPlacePredictions(request, (predictions) => {
                setAutoCompleteSuggestions(predictions || []);
            });
        };

        const updatePreferredHospital = async (hospital: any) => {

            console.log(selectedAddress, "selectedAddress");
            try {
                await updateBeneficiaryAddress(data._id, {
                    _id: selectedAddress._id,
                    preferredHospital: [hospital],
                }
                )
                ShowAlert("success", "Preferred Hospital Saved Successfully!");
                setTimeout(() => {
                    navigate(`/profile/view/${data._id}`);
                }, 1500);
            } catch (error: any) {
                if (error?.response?.data?.message) {
                    if (Array.isArray(error.response.data.message)) {
                        return ShowAlert("warning", error.response.data.message[0]);
                    }
                }
                console.log(error, "error")
                ShowAlert("warning", "Something went wrong, please try again later 1");
            }
        }

        const handleSave = async () => {

            if (!hospitalName) {
                return updatePreferredHospital({
                    id: "0",
                    hospitalName: keyword,
                    distance: "Not Available",
                })
            }

            const hospitalPayload = {
                name: hospitalName || keyword,
                contacts: [],
                address: hospitalAddress || cityName,
                location: {
                    type: "Point",
                    coordinates: [
                        Number(lng || 0),
                        Number(lat || 0)
                    ]
                }
            }

            try {

                let distance;
                console.log(selectedAddress, "selectedAddress")
                if (selectedAddress?.location && selectedAddress?.location) {
                    distance = getDistanceFromLatLonInKm(selectedAddress?.location[0]?.latitude, selectedAddress?.location[0]?.longitude, Number(lat), Number(lng));
                }

                console.log(distance, "distance")

                let hospitalData = await createHospitalData(hospitalPayload);
                let hospitalPayLoad = {
                    id: hospitalData._id,
                    hospitalName: hospitalData.name,
                    distance: distance?.toFixed(2) || "Not Available",
                }
               await updatePreferredHospital(hospitalPayLoad);
            } catch (error: any) {
                if (error?.response?.data?.message) {
                    if (Array.isArray(error.response.data.message)) {
                        return ShowAlert("warning", error.response.data.message[0]);
                    }
                }
                ShowAlert("warning", "Something went wrong, please try again later 0");
                console.log(error, "error")
            }

        };

        return (
            <div>
                <Modal
                    initialFocusRef={initialRef}
                    finalFocusRef={finalRef}
                    isOpen={true}
                    onClose={closeModal}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add New Hospital</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <Text>City Name</Text>
                            <InputGroup>
                                <Input
                                    value={cityName}
                                    onChange={(e) => {
                                        setCityName(e.target.value);
                                    }}
                                    marginBottom={"10px"}
                                    placeholder="Enter City Name"
                                    size="md"
                                />
                            </InputGroup>
                            <Text>Hospital Name</Text>
                            <InputGroup>
                                <Input
                                    value={keyword}
                                    onChange={(e) => {
                                        setKeyword(e.target.value);
                                        handleAutoComplete(e.target.value);
                                    }}
                                    marginBottom={"10px"}
                                    placeholder="Enter Hospital Name"
                                    size="md"
                                />
                            </InputGroup>

                            {showDropdown &&
                                autoCompleteSuggestions.length > 0 && (
                                    <Box>
                                        {autoCompleteSuggestions.map((suggestion) => (
                                            <Box
                                                key={suggestion.place_id}
                                                onClick={() => {
                                                    handleSelectHospital(suggestion);
                                                }}
                                                p={2}
                                                cursor="pointer"
                                                _hover={{ bg: "gray.100" }}
                                            >
                                                {suggestion.description}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={handleSave} bgColor={"#38a169"} color={"white"} mr={3}>
                                Save
                            </Button>
                            <Button onClick={closeModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </div>
        );
    };

export default FindHospital;
