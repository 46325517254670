import * as subscriberSlice from "../../../../Redux/Subscriber-Slice/Subscriber-slice";
import { getBeneficiaryByUserId } from "../../../../http";
export const getBen = async (userId:string,dispatch:any) => {
    
    try {
      const { data } = await getBeneficiaryByUserId(userId)
      dispatch(subscriberSlice.saveSubscriber(data.data))

      return {...data.data,resCode:200}
      
    } catch (error:any) {
      
      throw error
    }
  };
  