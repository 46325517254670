import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC= () => {

    sessionStorage.removeItem("lastVisitedPage")
    const [redirect, setRedirect] = useState(10);
    const navigate = useNavigate();
    useEffect(() => {
      let inte = setInterval(() => {
        if (redirect <= 0) {
          clearInterval(inte);
          return navigate("/");
        }
        return setRedirect((prev) => prev - 1);
      }, 1000);
      return () => {
        clearInterval(inte);
      };
    }, [redirect, navigate]);

    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img
                style={{ width: "500px" }}
                src="/Images/disconnet.jpg"
                alt="pagenotfound"
            />
            <h1 style={{ fontSize: "60px" }}>404 page Not Found</h1>
            <h1 style={{ color: "#00A7B5" }}>Redirecting in {redirect}</h1>
        </div>
    )
}

export default NotFound
