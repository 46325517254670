import { Box, Heading } from "@chakra-ui/react";
import Navbar from "../../GlobalComponents/Navbar/Navbar";
import Footer from "../../GlobalComponents/Footer/Footer";
import style from "./Privacypolicy.module.css"
import PrivacypolicyText from "./PrivacypolicyText";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Box className={style.wrapper}>
        <Box className={style.container}>
          <Heading className={style.primary_Color} as="h1" size="xl">
            Privacy Policy
          </Heading>
          <Box>
          <PrivacypolicyText/>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
