import { Box } from "@chakra-ui/react";
import React from "react";
import { DataItem } from "../Shared/DataItem";

const AbhaProfileView = ({ abha }: any) => {
  return (
    <Box>
      <Box
        borderRadius="6px"
        border="1px solid rgba(51, 51, 51, 0.60)"
        justifyContent={"center"}
        alignItems={"center"}
        background="#FFF"
        p={4}
        display={"flex"}
        maxWidth={"942px"}
        height={"209px"}
      >
        <Box
          marginRight={"43px"}
          marginLeft={"56px"}
          backgroundImage="url('https://developmentbucket.blob.core.windows.net/logos/abhaLogo.png')"
          backgroundPosition="50%"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          width="146px"
          height={"145.115px"}
        ></Box>
        <Box
          justifyContent="center"
          alignItems="center"
          width="calc(100% - 197px)"
          p={4}
        >
          <DataItem label="Mobile Number:" value={abha?.abhaMobileNumber} />
          <DataItem label="Health ID:" value={abha?.abhaHealthId} />
          <DataItem label="Health Number:" value={abha?.abhaHealthNumber} />
          <DataItem label="Abha Address:" value={abha?.abhaAddress} />
        </Box>
      </Box>
    </Box>
  );
};

export default AbhaProfileView;
