import {
  Box,
  Button,
  FormControl,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";

import React, { useState } from "react";

const VerifyMobileOtp = ({
  otp,
  setStatus,
  handleChangeVerifyOtp,
  verifyOtpLoading,
  handleSubmitVerifyMobileOtp,
  handleResenedLoginOtp,
}: any) => {
  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => {
    setIsOpen(false);
    window.location.reload();
    setStatus({
      termsConditionsStatus: true,
      generateMobileOtpStatus: false,
      VerifyMobileOtpStatus: false,
      selectAccountStatus: false,
    });
  };

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <Box p={4} maxW="md" overflow="hidden">
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "sm", xl: "md" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="left">
            Verify OTP sent to ABHA mobile number
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <FormControl alignItems="center">
                <HStack justifyContent="center" textAlign="center">
                  <PinInput placeholder="" otp size="md">
                    {otp.map((curr: any, index: any) => (
                      <PinInputField
                        key={index + "__"}
                        value={curr}
                        onChange={(e) =>
                          handleChangeVerifyOtp(e.target.value, index)
                        }
                      />
                    ))}
                  </PinInput>
                </HStack>
              </FormControl>
              {
                <Text mt={5} alignItems={"center"}>
                  Not received OTP?{" "}
                  <Link onClick={handleResenedLoginOtp} color={"#008470"}>
                    Resend OTP
                  </Link>
                </Text>
              }
              <Button
                onClick={handleSubmitVerifyMobileOtp}
                bg="#008470"
                color="white"
                mr={3}
                mt={6}
                isLoading={verifyOtpLoading}
                loadingText="Sending"
                _hover={{
                  bg: "#008470",
                }}
              >
                Verify OTP
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default VerifyMobileOtp;
