import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormLabel, HStack, Radio, RadioGroup, Select, Stack, Textarea } from '@chakra-ui/react';
import { MultiSelect } from 'react-multi-select-component';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import MedicalProfileStyle from './MedicalProfile.module.css';
//@ts-ignore
import ReactFileReader from 'react-file-reader';
import { ShowAlert } from '../../../../GlobalComponents/ShowAlert/ShowAlert';
import { createMedicalInfo, getMedicalInfoAction, uploadFile } from '../../Actions/beneficiaryDetails.action'; // eslint-disable-line

interface MedicalProfileProps {
  changeStep: (step: number) => void;
  setData: (data: any) => void;
  data: any;
  setCurrentStep: (step: number) => void;
}

const MedicalProfile: React.FC<MedicalProfileProps> = ({ changeStep, setData, data, setCurrentStep }) => {
  const [recentLoading, setRecentLoading] = useState(false); // eslint-disable-line
  const [pastLoading, setPastLoading] = useState(false); // eslint-disable-line
  const [currentLoading, setCurrentLoading] = useState(false); // eslint-disable-line
  const [bloodGroup, setBloodGroup] = useState<string | undefined>();
  const [chronicConditions, setChronicConditions] = useState<{ value: string; label: string }[]>([]);
  const [otherCondition2, setOtherCondition2] = useState<string | undefined>()
  const [otherChronic, setOtherChronic] = useState<string | undefined>();
  const [isOtherConditiion, setIsOtherrCondition] = useState<boolean>(false)
  const [currentMedication, setCurrentMedication] = useState<string | undefined>();
  const [currentimage, setCurrentimage] = useState<string | undefined>(); // eslint-disable-line
  const [currentMedicationDescription, setCurrentMedicationDescription] = useState<string | undefined>();
  const [allergiesToMedication, setAllergiesToMedication] = useState<string | undefined>();
  const [allergiesToMedicationDescription, setAllergiesToMedicationDescription] = useState<string | undefined>();
  const [pastimage, setPastimage] = useState<string | undefined>(); // eslint-disable-line
  const [pastMedicalHistory, setPastMedicalHistory] = useState<string | undefined>();
  const [pastMedicalHistoryDescription, setPastMedicalHistoryDescription] = useState<string | undefined>();
  const [recentimage, setRecentimage] = useState<string | undefined>(); // eslint-disable-line
  const [recentHospitalization, setRecentHospitalization] = useState<string | undefined>();
  const [recentHospitalizationDescription, setRecentHospitalizationDescription] = useState<string | undefined>();
  const [differentlyAbled, setDifferentlyAbled] = useState<string | undefined>();
  const [differentlyAbledDescription, setDifferentlyAbledDescription] = useState<string | undefined>();
  const [mobilityStatus, setMobilityStatus] = useState<string | undefined>();
  let SaSToken = localStorage.getItem("sasToken");
  const options = [
    { label: 'Type 2 Diabetics Mellitus', value: 'Type 2 Diabetes Mellitus' },
    { label: 'HyperTension', value: 'Hypertension' },
    { label: 'Heart Disease', value: 'Heart Disease' },
    { label: 'Cancer', value: 'Cancer' },
    { label: 'Chronic Kidney Disease', value: 'Chronic Kidney Disease' },
    { label: 'Asthma', value: 'Asthma' },
    { label: 'Hyperlipidemia', value: 'Hyperlipidemia' },
    { label: 'Dialysis', value: 'Dialysis' },
    { label: 'Others', value: otherChronic },
  ];


  const addMedicalProfile = async () => {
    if (!bloodGroup) {
      return ShowAlert('warning', 'Please select blood group');
    }
    if (currentMedication === 'Yes' && (!currentMedicationDescription || currentMedicationDescription?.trim() === "")) {
      return ShowAlert('warning', 'Please provide current medication description');
    }

    if (allergiesToMedication === 'Yes' && (!allergiesToMedicationDescription || allergiesToMedicationDescription?.trim() === "")) {
      return ShowAlert('warning', 'Please provide allergies to medication description');
    }

    if (pastMedicalHistory === 'Yes' && (!pastMedicalHistoryDescription || pastMedicalHistoryDescription?.trim() === "")) {
      return ShowAlert('warning', 'Please provide past medical history description');
    }

    if (recentHospitalization === 'Yes' && (!recentHospitalizationDescription || recentHospitalizationDescription.trim() === "")) {
      return ShowAlert('warning', 'Please provide recent hospitalization description');
    }

    if (differentlyAbled === 'Yes' && (!differentlyAbledDescription || differentlyAbledDescription.trim() === "")) {
      return ShowAlert('warning', 'Please provide differently abled description');
    }
    if (isOtherConditiion && !otherCondition2) {
      return ShowAlert('warning', 'Please provide other chronic conditions');
    }

    const medicalProfilePayload: any = {
      chronicConditions: chronicConditions.map((el) => {
        if (el.label === 'Others') return otherCondition2;
        else return el.value
      })
    };

    if (currentMedication === 'Yes') {
      medicalProfilePayload.currentMedications = {
        description: currentMedicationDescription || '',
        docsUrl: currentimage || '',
        iSpresent: true
      };
    } else if (currentMedication === 'No') {
      medicalProfilePayload.currentMedications = {
        description: '',
        docsUrl: '',
        iSpresent: false
      };
    }

    if (allergiesToMedication === 'Yes') {
      medicalProfilePayload.allergiesToMedications = {
        description: allergiesToMedicationDescription || '',
        iSpresent: true
      };
    } else if (allergiesToMedication === 'No') {
      medicalProfilePayload.allergiesToMedications = {
        description: '',
        iSpresent: false
      };
    }

    if (pastMedicalHistory === 'Yes') {
      medicalProfilePayload.pastMedicalRecords = {
        description: pastMedicalHistoryDescription || '',
        docsUrl: pastimage ? [pastimage] : [],
        iSpresent: true
      };
    } else if (pastMedicalHistory === 'No') {
      medicalProfilePayload.pastMedicalRecords = {
        description: '',
        docsUrl: [],
        iSpresent: false
      };
    }

    if (recentHospitalization === 'Yes') {
      medicalProfilePayload.recentHospitalizations = {
        doctorsInformation: recentimage || '',
        description: recentHospitalizationDescription || '',
        iSpresent: true
      };
    } else if (recentHospitalization === 'No') {
      medicalProfilePayload.recentHospitalizations = {
        doctorsInformation: '',
        description: '',
        iSpresent: false
      };
    }
    if (differentlyAbled === 'Yes') {
      medicalProfilePayload.generalInfo = {
        ...medicalProfilePayload.generalInfo,
        differentlyAbled: differentlyAbledDescription
      };
    } else if (differentlyAbled === 'No') {
      medicalProfilePayload.generalInfo = {
        ...medicalProfilePayload.generalInfo,
        differentlyAbled: ""
      };
    }

    if (bloodGroup) {
      medicalProfilePayload.generalInfo = {
        ...medicalProfilePayload.generalInfo,
        bloodGroup
      }
    }

    if (mobilityStatus) {
      medicalProfilePayload.generalInfo = {
        ...medicalProfilePayload.generalInfo,
        mobilityStatus
      };
    }

    try {
      await createMedicalInfo(data._id, medicalProfilePayload);
      setData({ ...data, medicalInfo: medicalProfilePayload });
      ShowAlert('success', 'Medical Profile Added');
      setCurrentStep(5);
    } catch (error) {
      console.log("Error while adding medical profile", error);
      ShowAlert('warning', 'Error while adding medical profile');
    }
  };

  const handleRecentDoc = async (files: any) => {
    setRecentLoading(true);
    try {
      const res = await uploadFile(data._id, files, 'recentHospitalizationDoc');
      setRecentimage(res);
      setRecentLoading(false);
    } catch (error) {
      console.log("error while uploading current doc", error);
      setRecentimage("");
      setRecentLoading(false);
    }

  };

  const handlePastDoc = async (files: any) => {
    setPastLoading(true);
    try {
      const res = await uploadFile(data._id, files, 'pastMedicalHospitalizationDoc');
      setPastimage(res);
      setPastLoading(false);
    } catch (error) {
      console.log("error while uploading current doc", error);
      setPastLoading(false);
      setPastimage('');
    }

  };

  const handleCurrentDoc = async (files: any) => {
    setCurrentLoading(true);
    try {
      const res = await uploadFile(data._id, files, 'currentHospitalizationDoc');
      setCurrentimage(res);
      setCurrentLoading(false);
    } catch (error) {
      console.log("error while uploading current doc", error);
      setCurrentLoading(false);
      setCurrentimage('');
    }

  };

  const handleViewRecentDocument = () => {
    window.open(recentimage + "?" + SaSToken || '', '_blank');
  };

  const handleViewCurrentDocument = () => {
    window.open(currentimage + "?" + SaSToken || '', '_blank');
  };

  const handleViewPastDocument = () => {
    window.open(pastimage + "?" + SaSToken || '', '_blank');
  };

  const setUpcomingMedicalData = (response: any) => {
    // console.log(response.medicalInfo.allergiesToMedications,"-----------------------------------")
    setChronicConditions(
      response.medicalInfo?.chronicConditions?.map((el: string) => {

        if (el !== 'Dialysis' && el !== 'Hyperlipidemia' && el !== 'Asthma' && el !== 'Chronic Kidney Disease' && el !== 'Cancer' && el !== 'Heart Disease' && el !== 'Hypertension' && el !== 'Type 2 Diabetes Mellitus') {
          return { value: el, label: "Others" }
        } else {
          return { value: el, label: el }
        }
      }) || []
    )
    setOtherCondition2(response.medicalInfo?.chronicConditions?.filter((el: string) => {

      if (el !== 'Dialysis' && el !== 'Hyperlipidemia' && el !== 'Asthma' && el !== 'Chronic Kidney Disease' && el !== 'Cancer' && el !== 'Heart Disease' && el !== 'Hypertension' && el !== 'Type 2 Diabetes Mellitus') {
        return el
      } else return false
    }))
    setCurrentMedication(response.medicalInfo?.currentMedications?.length === 0 ? "" : response.medicalInfo?.currentMedications[0]?.iSpresent ? "Yes" : "No");
    setCurrentMedicationDescription(response.medicalInfo?.currentMedications[0]?.description || '');
    setCurrentimage(response.medicalInfo?.currentMedications[0]?.docsUrl || '');

    setAllergiesToMedication(response.medicalInfo?.allergiesToMedications?.length === 0 ? "" : response.medicalInfo?.allergiesToMedications[0].iSpresent ? "Yes" : "No");
    setAllergiesToMedicationDescription(response.medicalInfo?.allergiesToMedications[0]?.description || "");

    setPastMedicalHistory(response.medicalInfo?.pastMedicalRecords?.length === 0 ? "" : response.medicalInfo?.pastMedicalRecords[0]?.iSpresent ? "Yes" : "No");
    setPastMedicalHistoryDescription(response.medicalInfo?.pastMedicalRecords[0]?.description || "");
    setPastimage(response.medicalInfo?.pastMedicalRecords[0]?.docsUrl[0] || '');

    setRecentHospitalization(response.medicalInfo?.recentHospitalizations?.length === 0 ? "" : response.medicalInfo?.recentHospitalizations[0]?.iSpresent ? "Yes" : "No");
    setRecentHospitalizationDescription(response.medicalInfo?.recentHospitalizations[0]?.description || "");
    setRecentimage(response.medicalInfo?.recentHospitalizations[0]?.doctorsInformation || "");

    setDifferentlyAbled(response.medicalInfo?.generalInfo?.differentlyAble !== undefined ? (response.medicalInfo.generalInfo.differentlyAble !== "" ? "Yes" : "No") : "");
    setDifferentlyAbledDescription(response.medicalInfo?.generalInfo?.differentlyAble ? response.medicalInfo?.generalInfo?.differentlyAble : '');

    setMobilityStatus(response.medicalInfo?.generalInfo?.mobilityStatus || '');

    setBloodGroup(response.medicalInfo?.generalInfo?.bloodGroup || '');
  }

  const getMedicalData = async () => {
    try {
      let response = await getMedicalInfoAction(data._id);

      let getOtherChronic: any = await response?.medicalInfo?.chronicConditions?.filter((el: string) => {
        if (el !== 'Dialysis' && el !== 'Hyperlipidemia' && el !== 'Asthma' && el !== 'Chronic Kidney Disease' && el !== 'Cancer' && el !== 'Heart Disease' && el !== 'Hypertension' && el !== 'Type 2 Diabetes Mellitus') {
          return el
        } else return false
      })
      setOtherChronic(getOtherChronic.join())
      setUpcomingMedicalData(response);
    } catch (error: any) {
      console.log("Error while getting medical data", error);
      if (error?.response?.data?.message) {
        ShowAlert('warning', error?.response?.data?.message);
      }
    }
  }

  useEffect(() => {
    getMedicalData();
  }, []); // eslint-disable-line

  useEffect(() => {

    let otherChronic = chronicConditions.filter((el) => el.label === 'Others')

    if (otherChronic.length === 0) {
      setOtherCondition2("");
      setIsOtherrCondition(false)
    } else {
      setIsOtherrCondition(true)
    }

  }, [chronicConditions])
  return (
    <>
      <FormControl>
        <FormLabel fontWeight={'bold'}>Chronic Conditions</FormLabel>
        {/* @ts-ignore */}
        <MultiSelect
          options={options}
          value={chronicConditions}
          onChange={setChronicConditions}
          hasSelectAll={false}
          shouldToggleOnHover={false}
        />
        {isOtherConditiion && <Textarea
          value={otherCondition2 || ''}
          onChange={(e) => setOtherCondition2(e.target.value)}
          placeholder='Type Other Chronic Conditions'
          size='sm'
          marginTop={'10px'}
        />}
        <FormLabel fontWeight={'bold'} marginTop={'20px'}>
          Blood Group
        </FormLabel>
        <Select
          marginTop={'20px'}
          value={bloodGroup || ''}
          onChange={(e) => {
            setBloodGroup(e.target.value);
          }}
          bottom={'15px'}
          size={'md'}
          width={'100%'}
          placeholder='Select'
        >
          <option value='A'>A</option>
          <option value='A+'>A+</option>
          <option value='A-'>A-</option>
          <option value='B'>B</option>
          <option value='B+'>B+</option>
          <option value='B-'>B-</option>
          <option value='O'>O</option>
          <option value='O+'>O+</option>
          <option value='O-'>O-</option>
          <option value='AB'>AB</option>
          <option value='AB+'>AB+</option>
          <option value='AB-'>AB-</option>
        </Select>
        <FormLabel fontWeight={'bold'} marginTop={'20px'}>
          Current Medication
        </FormLabel>
        <RadioGroup onChange={setCurrentMedication} value={currentMedication || ''}>
          <Stack spacing={5} direction='row'>
            <Radio colorScheme='green' value='Yes'>
              Yes
            </Radio>
            <Radio colorScheme='red' value='No'>
              No
            </Radio>
          </Stack>
        </RadioGroup>
        {currentMedication === 'Yes' ? (
          <>
            <Textarea
              value={currentMedicationDescription || ''}
              onChange={(e) => setCurrentMedicationDescription(e.target.value)}
              placeholder='Provide Description of Medicines'
              size='sm'
              marginTop={'10px'}
            />
            {currentimage ? (
              <Button
                isLoading={currentLoading ? true : false}
                loadingText='Uploading'
                colorScheme='teal'
                variant='outline'
                marginTop={'10px'}
                onClick={handleViewCurrentDocument}
              >
                View Document
              </Button>
            ) : (
              <ReactFileReader fileTypes={['.pdf', '.zip', '.jpeg', '.png', '.jpg']} base64={true} multipleFiles={false} handleFiles={handleCurrentDoc}>
                {/* @ts-ignore */}
                <Button marginTop={'10px'} type='solid'>
                  Upload Document
                </Button>
              </ReactFileReader>
            )}
          </>
        ) : (
          ''
        )}
        <FormLabel fontWeight={'bold'} marginTop={'20px'}>
          Allergies To Medication
        </FormLabel>
        <RadioGroup value={allergiesToMedication} onChange={setAllergiesToMedication}>
          <Stack spacing={5} direction='row'>
            <Radio colorScheme='green' value='Yes'>
              Yes
            </Radio>
            <Radio colorScheme='red' value='No'>
              No
            </Radio>
          </Stack>
        </RadioGroup>
        {allergiesToMedication === 'Yes' ? (
          <Textarea
            value={allergiesToMedicationDescription || ''}
            onChange={(e) => setAllergiesToMedicationDescription(e.target.value)}
            placeholder='Provide details of Medicines Allergies'
            size='sm'
            marginTop={'10px'}
          />
        ) : (
          ''
        )}

        <FormLabel fontWeight={'bold'} marginTop={'20px'}>
          Past Medical/Surgical History
        </FormLabel>
        <RadioGroup value={pastMedicalHistory} onChange={setPastMedicalHistory}>
          <Stack spacing={5} direction='row'>
            <Radio colorScheme='green' value='Yes'>
              Yes
            </Radio>
            <Radio colorScheme='red' value='No'>
              No
            </Radio>
          </Stack>
        </RadioGroup>
        {pastMedicalHistory === 'Yes' ? (
          <>
            <Textarea
              value={pastMedicalHistoryDescription}
              onChange={(e) => setPastMedicalHistoryDescription(e.target.value)}
              placeholder='Provide Description of Medical and Surgical History'
              size='sm'
              marginTop={'10px'}
            />
            {pastimage ? (
              <Button
                isLoading={pastLoading ? true : false}
                loadingText='Uploading'
                colorScheme='teal'
                variant='outline'
                marginTop={'10px'}
                onClick={handleViewPastDocument}
              >
                View Document
              </Button>
            ) : (
              <ReactFileReader fileTypes={['.pdf', '.zip', '.jpeg', '.png', '.jpg']} base64={true} multipleFiles={false} handleFiles={handlePastDoc}>
                {/* @ts-ignore */}
                <Button marginTop={'10px'} type='solid'>
                  Upload Document
                </Button>
              </ReactFileReader>
            )}
          </>
        ) : (
          ''
        )}

        <FormLabel fontWeight={'bold'} marginTop={'20px'}>
          Recent Hospitalization
        </FormLabel>
        <RadioGroup value={recentHospitalization} onChange={setRecentHospitalization}>
          <Stack spacing={5} direction='row'>
            <Radio colorScheme='green' value='Yes'>
              Yes
            </Radio>
            <Radio colorScheme='red' value='No'>
              No
            </Radio>
          </Stack>
        </RadioGroup>
        {recentHospitalization === 'Yes' ? (
          <>
            <Textarea
              value={recentHospitalizationDescription || ''}
              onChange={(e) => setRecentHospitalizationDescription(e.target.value)}
              placeholder='Provide Description of Recent Hospitalization Description'
              size='sm'
              marginTop={'10px'}
            />
            {recentimage ? (
              <Button
                isLoading={recentLoading ? true : false}
                loadingText='Uploading'
                colorScheme='teal'
                variant='outline'
                marginTop={'10px'}
                onClick={handleViewRecentDocument}
              >
                View Document
              </Button>
            ) : (
              <ReactFileReader fileTypes={['.pdf', '.zip', '.jpeg', '.png', '.jpg']} base64={true} multipleFiles={false} handleFiles={handleRecentDoc}>
                {/* @ts-ignore */}
                <Button marginTop={'10px'} type='solid'>
                  Upload Document
                </Button>
              </ReactFileReader>
            )}
          </>
        ) : (
          ''
        )}

        <FormLabel fontWeight={'bold'} marginTop={'20px'}>
          Differently Abled
        </FormLabel>
        <RadioGroup value={differentlyAbled} onChange={setDifferentlyAbled}>
          <Stack spacing={5} direction='row'>
            <Radio colorScheme='green' value='Yes'>
              Yes
            </Radio>
            <Radio colorScheme='red' value='No'>
              No
            </Radio>
          </Stack>
        </RadioGroup>
        {differentlyAbled === 'Yes' ? (
          <Textarea
            value={differentlyAbledDescription || ''}
            onChange={(e) => setDifferentlyAbledDescription(e.target.value)}
            placeholder='Provide Description'
            size='sm'
            marginTop={'10px'}
          />
        ) : (
          ''
        )}
        <FormLabel fontWeight={'bold'} marginTop={'20px'}>
          Mobility Status
        </FormLabel>
        <Select
          marginTop={'20px'}
          value={mobilityStatus || ''}
          onChange={(e) => {
            setMobilityStatus(e.target.value);
          }}
          bottom={'15px'}
          size={'md'}
          width={'100%'}
          placeholder='Select'
        >
          <option value='Immobile'>Immobile (Bed-ridden)</option>
          <option value='Mobile'>Mobile (Able to move)</option>
          <option value='Assisted Mobility'>Assisted Mobility (Walker, Wheel chair)</option>
        </Select>
      </FormControl>

      <HStack justifyContent={'space-between'} marginTop={'20px'} spacing={12}>
        <GrFormPrevious className={MedicalProfileStyle.redIcon} onClick={() => changeStep(-1)} />
        <Button onClick={() => addMedicalProfile()} colorScheme='green' variant={'solid'}>
          Save and continue
        </Button>
        {
          (data.medicalInfo?.allergiesToMedications?.length ||
            data.medicalInfo?.chronicConditions?.length ||
            data.medicalInfo?.currentMedications?.length ||
            data.medicalInfo?.generalInfo?.bloodGroup?.length ||
            data.medicalInfo?.generalInfo?.differentlyAble?.length ||
            data.medicalInfo?.recentHospitalizations?.length ||
            data.medicalInfo?.generalInfo?.mobilityStatus?.length) &&
          (
            <GrFormNext
              onClick={() => changeStep(+1)}
              className={MedicalProfileStyle.redIcon}
            />
          )
        }
      </HStack>
    </>
  );
};

export default MedicalProfile;
