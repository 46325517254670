import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getAbhaDetailsFromPococareAccAction } from "../../BeneficiaryDetails/Actions/beneficiaryDetails.action";
import {useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
// import AbhaDetails from "../../BeneficiaryDetails/components/Abha/AbhaDetails";
import AbhaProfileStyle from "./ProfileAbhaDetails.module.css";
import AbhaProfileView from "../../BeneficiaryDetails/components/Abha/AbhaProfile/AbhaProfileView";

const ProfileAbhaDetails = ({ data }: any) => {
  const [abhaDetails, setAbhaDetails] = useState(null);
  const { profileId } = useParams();
  console.log(profileId, "profileId");
  const navigate = useNavigate();

  const handleIntegrateAbha = () => {
    navigate(`/addBenFullDetails/${data._id}/7`);
  };
  const fetchAbhaDetails = async (profileId: any) => {
    try {
      const response = await getAbhaDetailsFromPococareAccAction(profileId);
      const extractedData = response?.data; // Extract specific data from the response
      setAbhaDetails(extractedData);
    } catch (error: any) {
      console.log(error, "Error while fetching the beneficiary details");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAbhaDetails(profileId);
    };
    fetchData();
  }, [profileId]);

  return (
    <>
      <Box className={AbhaProfileStyle.abha_wrapper}>
        <Box padding={5}>
          <Box display={"flex"} flexDir={"column"} gap={"3px"}>
            <Text fontWeight={"600"} color="#008470">
              {" "}
              ABHA Account
            </Text>
          </Box>
          <Box>
            {!abhaDetails && (
              <Box
                onClick={handleIntegrateAbha}
                display="flex"
                justifyContent="left"
                alignItems="center"
                color="#008470"
              >
                <AiOutlinePlus />
                <Text>Integrate ABHA </Text>
              </Box>
            )}
            {abhaDetails && (
              <Box ml={0.5} mt={3}>
                <AbhaProfileView abha={abhaDetails} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfileAbhaDetails;
