import { Flex, Text } from "@chakra-ui/react";

export const DataItem = ({ label, value }: any) => (
    <Flex mb={2}>
      <Text
        flex="1"
        color="rgba(51, 51, 51, 0.70)"
        fontFamily="Poppins"
        fontSize="16px"
        fontStyle="normal"
        fontWeight="500"
        lineHeight="24px"
        letterSpacing="0.96px"
        maxWidth={"167px"}
      >
        {label}
      </Text>
      <Text
        flex="1"
        color="#333"
        sx={{
          fontVariantNumeric: "lining-nums tabular-nums",
          fontFeatureSettings: "'clig' off, 'liga' off",
        }}
        fontFamily="Poppins"
        fontSize="16px"
        fontStyle="normal"
        fontWeight="500"
        lineHeight="24px"
        letterSpacing="0.96px"
      >
        {value}
      </Text>
    </Flex>
  );
  