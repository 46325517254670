import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Button,
  Box,
  Flex,
  Stack,
  Input,
  FormErrorMessage,
  Text,
  Checkbox,
  Link,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import loginStyle from "./Login.module.css";
import classNames from "classnames";
import { sendOtp } from "./Action/loginAction";
import { LoginOtp } from "./Action/loginAction";
import OtpModal from "../Otp/Otp";
import { ShowAlert } from "../../../GlobalComponents/ShowAlert/ShowAlert";
import { Field, Formik } from "formik";
// import { updateUser } from '../../../Redux/Subscriber-Slice/Subscriber-slice';
// import OtpModal, { Otp } from '../Otp/Otp';
// import OtpModal from '../Otp/OtpModal'; // Import your OTP modal component;

interface LoginProps {
  loginModal: boolean;
  onClose: () => void;
}
const Login: React.FC<LoginProps> = ({ loginModal, onClose }) => {
  const [number, setNumber] = useState<LoginOtp>({
    countryCode: "",
    number: "",
  });
  const [isAgree, setTandCStatus] = useState<boolean>(true);
  const [validNumber, setValidNumber] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(false); // State to OTP
  const [disable, setDisable] = useState(true);
  const [showOtpModal, setShowOtpModal] = useState(false); // State to manage OTP
  const phoneInputClass = classNames(loginStyle.myPhoneInputClass);
  const containerClass = classNames(loginStyle.myContainerClass);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handlePhoneChange = (value: string) => {
    setNumber({ countryCode: "91", number: value });
    value.length === 10 ? setValidNumber(true) : setValidNumber(false);
    if ((value.length === 10 && isAgree) || (validEmail && isAgree))
      setDisable(false);
    else if (value.length !== 10) {
      setDisable(true);
    }
  };
  const handleTandCStatus = (value: boolean) => {
    setTandCStatus(value);
    if (validEmail) {
      setDisable(!value);
    } else if (validNumber) {
      setDisable(!value);
    } else {
      setDisable(true);
    }
  };
  const handleClick = async (values: any) => {
    console.log("validNumber:", validNumber);

    try {
      if (isAgree === false) {
        // eslint-disable
        return ShowAlert("warning", "Kindly Agree Terms and Conditions.");
      }
      setLoading(true);
      setTimeout(async () => {
        try {
          const emailRegex =
            /^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,5}){1,2}$/;
          if (values.email.length > 0 && !values.email.match(emailRegex)) {
            setLoading(false);
            return ShowAlert("warning", "Please enter correct email ");
          }
          await sendOtp({ ...number, email: values.email });
          setEmail(values.email);
          // Once the OTP is sent, stop the loading state and show the modal
          setLoading(false);
          setShowOtpModal(true);
          ShowAlert("success", "We've sent the OTP to your phone or email!");
        } catch (error: any) {
          setLoading(false);
          setShowOtpModal(false);
          if (error.response?.data?.message) {
            // ShowAlert("warning", `${error.response?.data?.message}`)
            ShowAlert("warning", `Couldn't find Pococare account`);
          } else {
            ShowAlert("warning", `Please try again`);
          }
        }
      }, 1000);
    } catch (error: any) {
      ShowAlert("warning", `Please try again`);
      setLoading(false);
    }
  };
  const handleCloseMOdal = () => {
    setDisable(true);
    setTandCStatus(true);
    setValidEmail(false);
    setValidNumber(false);
    onClose();
  };
  return (
    <>
      <Modal isOpen={loginModal} onClose={handleCloseMOdal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login to your Pococare account now!</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={10}>
            <Box className={loginStyle.inputWrapper}>
              <Flex>
                <Stack spacing={2} mx={"auto"} width={"100%"}>
                  <Box rounded={"lg"}>
                    <Stack spacing={4}>
                      <Box zIndex={1000} width={"100%"}>
                        <Formik
                          onSubmit={handleClick}
                          initialValues={{
                            email: "",
                          }}
                        >
                          {({ handleSubmit, errors }) => (
                            <form onSubmit={handleSubmit}>
                              <Stack spacing={2} align="flex-start">
                                <FormControl>
                                  <FormLabel htmlFor="email">Email</FormLabel>
                                  <Field
                                    as={Input}
                                    id="email"
                                    name="email"
                                    onInput={(e: any) => {
                                      // let value = e.target.value
                                      // const emailRegex = /^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,5}){1,2}$/;
                                      // if (!value.match(emailRegex)) {
                                      //   setValidEmail(false)
                                      //   setDisable(true)
                                      // } else {
                                      //   setValidEmail(true)
                                      //   if(validEmail){
                                      //     setDisable(false)
                                      //   }
                                      // }
                                      setDisable(false);
                                    }}
                                    placeholder="Email"
                                    validate={(value: string) => {
                                      let error: string = "";
                                      const emailRegex =
                                        /^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,5}){1,2}$/;
                                      if (!value.match(emailRegex)) {
                                        // console.log("checkkkk")
                                        setValidEmail(false);
                                        // error = 'Email should be valid';
                                        if (validNumber) {
                                          setDisable(false);
                                        } else {
                                          setDisable(true);
                                        }
                                      } else {
                                        setValidEmail(true);
                                        if (isAgree) {
                                          console.log(isAgree, "isAgree iffff");
                                          setDisable(false);
                                        } else {
                                          setDisable(true);
                                        }
                                        // if (validNumber) {
                                        //   setDisable(false)
                                        // }
                                      }
                                      return error;
                                    }}
                                  />
                                  <FormErrorMessage>
                                    {errors.email}
                                  </FormErrorMessage>
                                </FormControl>

                                <Text
                                  fontWeight={"bold"}
                                  fontSize={"lg"}
                                  ml={"45%"}
                                  textAlign={"center"}
                                >
                                  OR
                                </Text>

                                <FormControl>
                                  <FormLabel htmlFor="email">Mobile</FormLabel>
                                  <PhoneInput
                                    country="in"
                                    onlyCountries={["in"]}
                                    autoFormat={true}
                                    disableCountryCode={true}
                                    placeholder="Phone Number"
                                    inputClass={phoneInputClass}
                                    containerClass={containerClass}
                                    onChange={handlePhoneChange}
                                  />
                                </FormControl>
                                <Box mt={0} mb={3}>
                                  <Checkbox
                                    colorScheme="pink"
                                    defaultChecked={isAgree}
                                    onChange={(e) =>
                                      handleTandCStatus(e.target.checked)
                                    }
                                  >
                                    I agree{" "}
                                    <span className={loginStyle.termsCondition}>
                                      <Link
                                        href="/terms&condition"
                                        target="_blank"
                                      >
                                        Terms & Conditions
                                      </Link>
                                    </span>{" "}
                                    and{" "}
                                    <span className={loginStyle.termsCondition}>
                                      <Link
                                        href="/privacyPolicy"
                                        target="_blank"
                                      >
                                        Privacy Policy.
                                      </Link>
                                    </span>
                                  </Checkbox>
                                </Box>
                                <Button
                                  isDisabled={disable}
                                  margin={"auto"}
                                  w={"50%"}
                                  borderRadius={"46px"}
                                  bg={"#008470"}
                                  color={"white"}
                                  _hover={{
                                    bg: "#008470",
                                  }}
                                  type="submit"
                                  isLoading={isLoading}
                                  loadingText="Sending"
                                >
                                  Send OTP
                                </Button>
                              </Stack>
                            </form>
                          )}
                        </Formik>
                      </Box>
                      <Stack
                        spacing={10}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems="center"
                      ></Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {showOtpModal && (
        <OtpModal
          email={email}
          showOtpModal={showOtpModal}
          setShowOtpModal={setShowOtpModal}
          onClose={() => {
            // Close the OTP modal and set showOtpModal to false
            setShowOtpModal(false);
          }}
        />
      )}
    </>
  );
};
export default Login;
