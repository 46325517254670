import { Button } from "@chakra-ui/button";
import { Card, CardFooter, CardHeader } from "@chakra-ui/card";
import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import { FC } from "react"
import Navbar from "../../GlobalComponents/Navbar/Navbar";
import { Avatar } from "@chakra-ui/avatar";
import { useNavigate } from "react-router";
import { ShowAlert } from "../../GlobalComponents/ShowAlert/ShowAlert";
import { useGeolocated } from "react-geolocated";
import { raiseEmergencyAction } from "../DashBoard/Action/callAction";

// @ts-ignore
export const PwaDashboard: FC = () => {
  const navigate = useNavigate();
  const data = localStorage.getItem("pwaBen");
  const beneficiary = JSON.parse(data || "{}");
  if (!beneficiary.length) {
    ShowAlert("warning", "Please login First")
    navigate("/");
  }

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: Infinity,
  });

  const handleEmergencyBtnClicked = (el: any) => {
    raiseEmergency(el._id, String(el?.personalInfo?.mobile?.number));
  }

  const raiseEmergency = async (benId:string, mobile: string) => {
    if (!mobile) {
      ShowAlert('warning', 'Please update mobile number to account')
    }

    try {
      let res = await raiseEmergencyAction(benId, mobile, String(coords?.latitude) || "", String(coords?.longitude) || "")
      if (res.data.message.includes('ticket already created ticketId')) {
        ShowAlert('success', 'Ticket has already created.')
      } else {

        ShowAlert('success', 'Please wait, Our team will reach out to you soon!')
      }
    } catch (e: any) {
      console.log(e)
    }
  };

  const handleRefreshBtn = () => {
    let userData = localStorage.getItem("userData");
    if(userData) {
      localStorage.setItem("pwaBen", userData);
      window.location.reload();
    }
    ShowAlert('warning', "Please login first to refresh the list");
    navigate("/");
  }

  return (
    <>
      <Navbar/>
      {
        beneficiary.length ? <>
          <Flex wrap='wrap' justifyContent='center' gap={"20px"} mt={"20"}>
            {
              beneficiary.map((el: any) => {
                return (
                  <>
                    <Card maxW='md' width={"400px"}>
                      <CardHeader>
                        {/* @ts-ignore */}
                        <Flex spacing='4'>
                          <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                            <Avatar name='Segun Adebayo' src={el?.personalInfo?.image || "https://t4.ftcdn.net/jpg/03/32/59/65/240_F_332596535_lAdLhf6KzbW6PWXBWeIFTovTii1drkbT.jpg" } />

                            <Box>
                              <Heading size='sm'>{el?.personalInfo?.fullName}</Heading>
                              <Text>{el?.personalInfo?.relationshipToSubscriber}</Text>
                            </Box>
                          </Flex>
                        </Flex>
                      </CardHeader>

                      <CardFooter
                        justify='space-between'
                        flexWrap='wrap'
                        sx={{
                          '& > button': {
                            minW: '136px',
                          },
                        }}
                      >
                        <Button onClick={() => handleEmergencyBtnClicked(el)} width={"100%"} flex='1' variant='solid' colorScheme="red" >
                          Alert Emergency
                        </Button>
                      </CardFooter>
                    </Card>
                  </>
                )
              })
            }
          </Flex>
          <Box w={"80%"} m={"auto"} mt={"10"} mb={"10"} textAlign={"center"}>
            <Button onClick={() => handleRefreshBtn()} variant={"solid"} colorScheme="twitter" >Refresh List</Button>
          </Box>
        </> : <>
            <Flex>
              <Button>Login</Button>
            </Flex>
        </>
      }
    </>
  )
}
