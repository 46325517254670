import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const TermsAndConitionText = () => {
  const [externalHTML, setExternalHTML] = useState("");

  useEffect(() => {
    fetch("TermsCondition_Pococare.html")
      .then((response) => response.text())
      .then((data) => setExternalHTML(data))
      .catch((error) => console.error("Error fetching external HTML:", error));
  }, []);
  return (
    <div>
      <Box>
        <Box>
          <Box dangerouslySetInnerHTML={{ __html: externalHTML }} />
        </Box>
      </Box>
    </div>
  );
};

export default TermsAndConitionText;
