import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import subscriberReducer from "./Subscriber-Slice/Subscriber-slice";
import beneficiaryReducer  from "./Beneficiary-Slice/Beneficiary-slice";
import useReducer  from "./User-slice/User-slice";

import { persistStore } from "redux-persist";
const persistConfig = {
    key: "MainData",
    storage,
};

const reducers = combineReducers({
    subscriber: subscriberReducer,
    beneficiary: beneficiaryReducer,
    user:useReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            customSerializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});
const persister = persistStore(store);
export { store, persister };