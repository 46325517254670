import { checkConsentStatus, raisedEmergencyWithCall, saveConsentInfo } from "../../../http";

export const raiseEmergencyAction = async (benId:string,mobile:string,lat:string|null,lng:string|null) => {
    
    try {
        console.log(benId,mobile,lat,lng)
       let data = await raisedEmergencyWithCall(benId,mobile,lat,lng)
       return data;

    } catch (error:any) {

      throw error;
      
    }
  };
  
  export const saveConsentInfoAction = async (payload : any) =>{
    try {
      const res = await saveConsentInfo(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
  
  export const checkConsentStatusAction = async (benId : any) =>{
    try {
      const res = await checkConsentStatus(benId);
      return res.data;
    } catch (error) {
      throw error;
    }
  }