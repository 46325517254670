export const parseUserAgent = (userAgent: string): any => {
  const data: { os: string; browser: string } = { os: "", browser: "" };

  // Operating System
  if (/Windows/.test(userAgent)) {
    data.os = "Windows";
  } else if (/Macintosh|Mac OS/.test(userAgent)) {
    data.os = "macOS";
  } else if (/iPhone/.test(userAgent)) {
    data.os = "iOS";
  } else if (/iPad/.test(userAgent)) {
    data.os = "iPadOS";
  } else if (/Android/.test(userAgent)) {
    data.os = "Android";
  } else if (/Linux/.test(userAgent)) {
    data.os = "Linux";
  } else {
    data.os = "Unknown OS";
  }

  // Browser
  const browserMatch = userAgent.match(
    /(Chrome|Firefox|Safari|Edge|Opera)\/(\S+)/
  );
  if (browserMatch && browserMatch.length >= 3) {
    data.browser = browserMatch[1];
  } else {
    data.browser = "Unknown Browser";
  }
  // return combined form browser+os;
  let device = (data.browser+"_"+data.os).toUpperCase();
  return device;
};
