import React from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
  Image,
} from "@chakra-ui/react";
import Footer from "../../GlobalComponents/Footer/Footer";
import Navbar from "../../GlobalComponents/Navbar/Navbar";
import { ShowAlert } from "../../GlobalComponents/ShowAlert/ShowAlert";
import style from "./Contactus.module.css"
import { sendContactUs } from "./Action/contactUs";
const locationData = [
  { path: "Images/location.svg", title: "Bengaluru, India" },
  { path: "Images/phone.svg", title: "+91 80560 66766" },
  { path: "Images/mail.svg", title: "info@pococare.com" },
];
export default function ContactUs() {
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const handleContactUs = async () => {

    if (!fullName || !email || !subject || !message ||!mobile) {
      return ShowAlert("warning", "Please fill all the fields");
    }

    const payload = {
      fullName,
    email,
      message,
      subject,
      mobile
    };
  try{
    await sendContactUs(payload);
    ShowAlert("success", "Your message has been sent successfully");
    setEmail("");
    setFullName("");
    setSubject("");
    setMobile("")
    setMessage("");
  }catch(e:any){
    console.log(e);
    return ShowAlert("warning", "Something went wrong, please try again later");
  }


  };
  return (
    <>
      <Navbar />
      <Box
        bg=""
        w={"100%"}
        margin={"auto"}
        mt={"1rem"}
        mb={"8rem"}
        overflow="hidden"
      >
        <Flex justify={"center"} align={"center"}>
          <Box
            width={"90%"}
            bg="#E1E8EB"
            color="white"
            borderRadius="lg"
            m={{ sm: 4, md: 16, lg: 10 }}
            p={{ sm: 5, md: 8, lg: 10 }}
          >
            <Box
              display={"flex"}
              flexDirection={{ base: "column", lg: "row" }}
              justifyContent={"space-between"}
              alignItems={{ lg: "center" }}
              p={3}
            >
              <Box w={{ lg: "40%", base: "90%" }}>
                <Box>
                  <Image mb={"1.5rem"} src="Images/logo.png" />
                </Box>
                <Text w={"100%"} className={style.heading_Text}>
                  Do you have any questions about Pococare?
                </Text>
                <Text
                  className={style.sub_Text}
                  mt={{ sm: 3, md: 3, lg: 5 }}
                  color="white.500"
                >
                  We're here to help, use the form below to contact us.
                </Text>
                <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                  <VStack spacing={3} alignItems="flex-start">
                    {locationData.map((el,i) => {
                      return (
                        <React.Fragment key={i + 1}>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            gap={"1rem"}
                          >
                            <Box mb={"1rem"}>
                              <Image src={el.path} alt={el.title} />
                            </Box>
                            <Box mb={"1rem"} className={style.sub_Text}>
                              <Text>{el.title}</Text>
                            </Box>
                          </Box>
                        </React.Fragment>
                      );
                    })}
                  </VStack>
                </Box>
              </Box>

              <Box w={{ lg: "55%", base: "90%" }}>
                <Box m={{ lg: 8, base: 0 }} color="#0B0E3F">
                  <VStack spacing={4}>
                    <FormControl id="name">
                      <FormLabel className={style.form_Text}>
                        Full Name
                      </FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <Input
                          height={"3.25rem"}
                          backgroundColor={"#fff"}
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          type="text"
                          placeholder="Name"
                        />
                      </InputGroup>
                    </FormControl>
                    <Box className={style.input_flex}>
                      <Box className={style.input_width}>
                        <FormControl id="name">
                          <FormLabel>Mail</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <Input
                              height={"3.25rem"}
                              backgroundColor={"#fff"}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              size="md"
                              placeholder="Email"
                            />
                          </InputGroup>
                        </FormControl>
                      </Box>
                      <Box className={style.input_width}>
                        <FormControl id="name">
                          <FormLabel>Phone Number</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <Input
                              height={"3.25rem"}
                              backgroundColor={"#fff"}
                              value={mobile}
                              onChange={(e) => {
                                console.log(" e:", e.target.value);
                                const sanitizedInput = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                                const limitedInput = sanitizedInput.slice(
                                  0,
                                  10
                                );
                                setMobile(limitedInput);
                              }}
                              type="text"
                              size="md"
                              placeholder="Phone Number"
                            />
                          </InputGroup>
                        </FormControl>
                      </Box>
                    </Box>
                    <FormControl id="name">
                      <FormLabel>Subject</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <Input
                          height={"3.25rem"}
                          backgroundColor={"#fff"}
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          type="text"
                          size="md"
                          placeholder="Subject"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl id="name">
                      <FormLabel>Message</FormLabel>
                      <Textarea
                        height={"11rem"}
                        backgroundColor={"#fff"}
                        borderColor="gray.300"
                        _hover={{
                          borderRadius: "gray.300",
                        }}
                        placeholder="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </FormControl>

                    <Button
                      w={"100%"}
                      variant="solid"
                      bg="#E961AB"
                      color="white"
                      _hover={{}}
                      onClick={() => {
                        handleContactUs();
                      }}
                      className={style.button_Text}
                    >
                      Send Message
                    </Button>
                  </VStack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Footer />
    </>
  );
}