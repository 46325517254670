import { Box, Heading, Image, Text } from "@chakra-ui/react";
import style from "./Careers.module.css";
import Navbar from "../../GlobalComponents/Navbar/Navbar";
import Footer from "../../GlobalComponents/Footer/Footer";

const data = [
  {
    id: "123",
    title: "MAKE A DIFFERENCE TO THE WORLD",
    image: "/Images/light-bulb.png",
  },
  {
    id: "124",
    title: "SHOW A LITTLE EXTRA CARE MORE THAN ANYBODY ELSE",
    image: "/Images/solidarity.png",
  },
  {
    id: "125",
    title: "PASSIONATE CURIOSITY - FOR CONTINUOUS LEARNING",
    image: "/Images/idea.png    ",
  },
  {
    id: "126",
    title: "DO WHAT IS RIGHT NOT WHAT IS EASY",
    image: "/Images/choice.png",
  },
  {
    id: "127",
    title: "DREAM ON - IMAGINATION LEADS TO NEW POSSIBILITIES",
    image: "/Images/imagination.png",
  },
  {
    id: "128",
    title: "POSITIVE CONVERSATIONS TO BUILD THE WORLD WE WANT",
    image: "/Images/chat.png",
  },
];

// const jobOpening = [
//   {
//     title: "Devloper",
//     location: "bangalore",
//   },
//   {
//     title: "Devloper",
//     location: "bangalore",
//   },
//   {
//     title: "Devloper",
//     location: "bangalore",
//   },
// ];

const Careers = () => {
  return (
    <>
      <Navbar />
      <Box>

      <Box className={style.wrapper}>
        <Box className={style.container}>
          <Heading className={style.primary_Color} as="h1" size="xl">
           Careers
          </Heading>
          <Text className={style.text_Style}>
            Simply put, Pococare is in the business of saving lives. All
            activities – our thoughts, ideas, actions, and conversations reflect
            this reason of our existence.<br></br>
            <br></br>
            We are an early-stage company looking for dreamers who make things
            happen, believe in pushing the boundaries every day across multiple
            dimensions of work and life. We are positive conversationists who
            integrate innovation, technology, and purpose in whatever we do.
          </Text>
        </Box>
      </Box>
      <Box className={style.mission_section}>
        <Box className={style.mission_container}>
          <Box>
            <Text textAlign={"center"} className={style.section_text}>
              OUR MISSION
            </Text>
            <Text className={style.section_text_2}>
              Redefining Medical Emergency Response In India.
            </Text>
          </Box>
        </Box>
      </Box>
      <Box className={style.vision_section}>
        <Box className={style.vision_container}>
          <Box className={style.center_Text}>
            <Text
              mt={"2rem"}
              color={"black"}
              textAlign={"center"}
              className={style.section_text_Values}
            >
              OUR VALUES
            </Text>
          </Box>
          <Box className={style.screen_visible}>
            <Box mt="1.5rem" className={style.grid_System}>
              {data.map((el) => {
                return (
                  <Box  height={"160px"}  key={el.id} textAlign={"center"}>
                    <Box className={style.image_wrapper}>
                      <Image src={el.image} alt="icon" />
                    </Box>
                    <Box mt="1rem">
                      <Text fontFamily={"Poppins"} fontSize={"1rem"}>{el.title}</Text>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        <Box className={style.team_Wrapper}>
          <Box className={style.container}>
            <Heading
              textAlign={"center"}
              className={style.primary_Color}
              as="h1"
              size="xl"
            >
              PocoTeam traits
            </Heading>
            <Text  mb={"8rem"} className={style.text_Style}>
              We believe diversity is critical to create a culture that brims
              with ideas. All lead roles need to have a strong sense of
              execution, grit, detail orientation, empathy, and high levels of
              curiosity to keep questioning what it takes to deliver the best
              experiences and outcomes to our customers. And making them even
              better.
            </Text>
          </Box>
          {/* <Box className={style.container}>
            <Heading
              textAlign={"center"}
              className={style.primary_Color}
              as="h1"
              size="xl"
            >
              CURRENT JOB OPENINGS
            </Heading>
            {jobOpening.map((el, i) => {
              return (
                <Box key={i} className={style.opening_warper}>
                  <Box mt="2rem" className={style.opening_flex}>
                    <Box>
                      <Text className={style.jobName}>{el.title}</Text>
                    </Box>
                    <Box>
                      <Text className={style.jobName}>{el.location}</Text>
                    </Box>
                  </Box>
                  <Box className={style.border}></Box>
                </Box>
              );
            })}
          </Box> */}
        </Box>
      </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Careers
