import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import style from "./Flex.module.css"

interface FlexCardProps{
    imageUrl:string
    content:string
    title:string

}

const FlexCard:React.FC<FlexCardProps>=({imageUrl,content,title})=>{
    return(
        <>
        <Box  height={"100%"} className={style.wrapper}>
            <Box height={"100%"} className={style.flex_Box}>
                <Box  height={"205px"}  display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Image height={"100%"}  src={imageUrl} alt="doctor.img"/>
                </Box>
                <Box >
                    {/* {time&&<Text></Text>} */}
                    
                    {title!==""&&<Text  ><span className={style.heading}>{title}</span><span className={style.text}>{content}</span></Text>}
                    {title===""&&<Text className={style.text}>{content}</Text>}
                </Box>

            </Box>
        </Box>
        </>
    )
}

export default FlexCard;