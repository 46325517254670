
import React, { useEffect, Dispatch, SetStateAction } from 'react';
interface TimerProps {
    timer: number;
    setSeconds: Dispatch<SetStateAction<number>>;
  }
  
const Timer: React.FC<TimerProps> = ({timer,setSeconds}:any) => {

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setSeconds((prevSeconds:any) => prevSeconds - 1);
      } else {
        clearInterval(interval);
        console.log("Time's up!");
      }
    }, 1000);
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [timer]);

  return (
    <div>
      <p>Time remaining: {timer} seconds</p>
    </div>
  );
};

export default Timer;
