import React from "react";
import style from "./AddBeneficiaryMinDetails.module.css";
import {
    Box,
    Button,
    ChakraProvider,
    FormControl,
    FormLabel,
    Image,
    Input,
    Stack,
    Text,
    extendTheme,
    InputGroup,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure
} from "@chakra-ui/react";
import PhoneInput from 'react-phone-input-2';
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAddress, updateUserMobileNo } from "./Action/updateAddress";
import { CreatePayload, createBeneficiaries } from "./Action/createBenficiaries";
import { benRouting } from "./Action/benRouting";
import { BenDataProps } from "../../Helper/BenInterface";
import { getAllBen } from "../Landing/Otp/Action/getAllBen";
import 'react-phone-input-2/lib/material.css'
import { ShowAlert } from "../../GlobalComponents/ShowAlert/ShowAlert";
import { updatePersonalInfo } from "../BeneficiaryDetails/Actions/beneficiaryDetails.action";
import { createAddress } from "./Action/createAddress";

const activeLabelStyles = {
    transform: "scale(0.85) translateY(-28px)",
    backgroundColor: "white",
    color: "#008470",
    fontWeight: "bold",
};
export const theme = extendTheme({
    components: {
        Form: {
            variants: {
                floating: {
                    container: {
                        _focusWithin: {
                            label: {
                                ...activeLabelStyles,
                            },
                        },
                        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
                        {
                            ...activeLabelStyles,
                        },
                        label: {
                            top: 2,
                            left: 0,
                            zIndex: 2,
                            position: "absolute",
                            color: "#6F6F6F",
                            fontSize: "16px",

                            pointerEvents: "none",
                            mx: 3,
                            px: 1,
                            my: 2,
                            transformOrigin: "left top",
                        },
                    },
                },
            },
        },
    },
});
interface SubscriberData {
    subscriber: BenDataProps
}

const AddBeneficiaryMinDetails: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const subscriberData = useSelector((state: SubscriberData) => state.subscriber);
    const userData = useSelector(((state: any) => state.user))
    const benData = useSelector((state: any) => state.beneficiary);
    const [beneficiariesList, setBeneficiariesList] = useState<any[]>([]);

    function checkIfAnyDataAdded(beneficiariesList: any) {
        let result = false;
        for (let i = 0; i < beneficiariesList.length; i++) {
            if (
                beneficiariesList[i].mobile?.number?.toString().length !== 10 ||
                beneficiariesList[i].pinCode?.toString().length !== 6 ||
                beneficiariesList[i].city?.length < 3
            ) {
                result = true;
                // break;
            }
        }
        setButtonDisabled(result);
    }
    console.log('beneficiariesList:', beneficiariesList);
    const handleSubmit = async () => {
        //so here i have all the details from the
        // beneficiariesList---first element is sub so i need to update using that benId
        //all other i need to create ben calling the create ben api that is if both of them successful need to redirect home 
        // if suppose ben_creation failed what should i do??
        //if sub updating failed what should i do??
        setLoading(true)
        if (beneficiariesList[0]?._id) {
            if (beneficiariesList[0].relationshipToSubscriber === 'Self' && userData.mobileNo === 'undefined-undefined') {
                try {

                    await updateUserMobileNo(userData.userId, {
                        mobileNo: {
                            countryCode: beneficiariesList[0]?.mobile?.countryCode,
                            number: beneficiariesList[0]?.mobile?.number
                        }
                    })
                } catch (error: any) {
                    if (error.response.status === 409) {
                        let newAllBenData = [...beneficiariesList];
                        newAllBenData[0].mobile.number = "";
                        setBeneficiariesList(newAllBenData);
                        ShowAlert("warning", "Self beneficiary number is already exist!")
                        setLoading(false)
                        return
                    }
                }
            }

            await updateIncompletedData(beneficiariesList)
        } else {
            try {
                const duplicationData: any = [...beneficiariesList];
                //if benId is present need to update//

                const subData = duplicationData.shift();
                const subAddress = {
                    address: {
                        ...(subscriberData?.address[0]?._id && { _id: subscriberData.address[0]?._id }),
                        city: subData.city,
                        pinCode: Number(subData.pinCode)
                    }
                }

                const newBenData: CreatePayload = {
                    data: duplicationData,
                    subscriberId: {
                        "ID": subscriberData.subscriberId
                    }
                }

                const benPayload = {

                    mobile: {
                        number: Number(subData?.mobile?.number),
                        countryCode: Number(subData?.mobile?.countryCode)
                    }
                };

                if (userData.mobileNo === 'undefined-undefined') {
                    try {

                        await updateUserMobileNo(userData.userId, {
                            mobileNo: {
                                countryCode: subData?.mobile?.countryCode,
                                number: subData?.mobile?.number
                            }
                        })
                    } catch (error: any) {
                        if (error.response.status === 409) {
                            let newAllBenData = [...beneficiariesList];
                            newAllBenData[0].mobile.number = "";
                            setBeneficiariesList(newAllBenData);
                            ShowAlert("warning", "Self beneficiary number is already exist!")
                            setLoading(false)
                            return
                        }
                    }
                }
                await updatePersonalInfo(benData[0]?._id, benPayload);
                subscriberData?.address[0]?._id ? await updateAddress(subData.id, subAddress.address) : await createAddress(subData.id, subAddress.address);
                await createBeneficiaries(newBenData, dispatch)
                await getAllBen(subscriberData.subscriberId, dispatch)
                ShowAlert("success", "Beneficiary details updated Successfully");
                setLoading(false)
                onOpen();
            } catch (error: any) {
                console.log(error)
                error.response?.data?.message ? ShowAlert('warning', error.response?.data?.message) :
                    ShowAlert('warning', "Please try again later!")
                setLoading(false)
            }
        }

    };

    const updateIncompletedData = async (benData: any) => {
        console.log('benData:', benData);
        for (const el of benData) {
            const benPayload = {
                fullName: el.fullName,
                mobile: el.mobile,
                relationToSubscriber: el.relationshipToSubscriber
            };
            const addresPayload = {
                ...(el.addressId && { _id: el.addressId }),
                city: el.city,
                pinCode: el.pinCode
            }

            await updatePersonalInfo(el._id, benPayload);
            addresPayload?._id ? await updateAddress(el._id, addresPayload) : await createAddress(el._id, addresPayload);


        }
        onOpen();
    }
    useEffect(() => {

        if (!benData.length) {
            return navigate("/")
        }

        benRouting(subscriberData.subscriberId, navigate)
        const localStorageData = localStorage.getItem("beneficiariesData");
        if (localStorageData) {
            const parsedData = JSON.parse(localStorageData);
            setBeneficiariesList(parsedData);
        }
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <ChakraProvider theme={theme}>
                <Box className={style.benBasicDetail_wrapper}>
                    <Box className={style.benBasicDetail_container}>
                        <Box className={style.benBasicDetail_Section}>
                            <Box className={style.ben_banner_image}>
                                <Image src="/Images/pococare_login.jpg" alt="banner_Image" />
                            </Box>
                            <Box>
                                <Box>
                                    <Text className={style.heading}>
                                        Let us know more about your beneficiaries
                                    </Text>
                                </Box>

                                <Box className={style.benMinDetail}>
                                    {beneficiariesList?.map((el, i) => {
                                        return (

                                            <Box className={style.benAddForm} key={i}>
                                                <Box mb={"10px"}>
                                                    <Text>Name:{el.fullName}</Text>
                                                    <Text>{el.relationToSubscriber || el.relationshipToSubscriber}</Text>
                                                </Box>

                                                <Stack spacing={"10px"}>
                                                    <FormControl>
                                                        <InputGroup>
                                                            <PhoneInput
                                                                inputStyle={{ height: '55px' }}
                                                                onlyCountries={["in"]}
                                                                country="in"
                                                                autoFormat={true}
                                                                disableCountryCode={true}
                                                                placeholder="Phone Number"
                                                                value={`${el?.mobile?.number}`}
                                                                onChange={(
                                                                    value: string
                                                                ) => {
                                                                    const updatedEl = {
                                                                        ...el,
                                                                        mobile: {
                                                                            countryCode: 91,
                                                                            number: Number(value),
                                                                        }
                                                                    };
                                                                    let newBenList;
                                                                    setBeneficiariesList((prev) => {
                                                                        const newList = [...prev];
                                                                        newList[i] = updatedEl;
                                                                        newBenList = newList
                                                                        return newList
                                                                    })
                                                                    checkIfAnyDataAdded(newBenList);
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        {el.mobile?.number?.toString().length !== 10 && (
                                                            <Text color="red" fontSize="sm">
                                                                Mobile should be 10 digits
                                                            </Text>
                                                        )}
                                                    </FormControl>
                                                    <FormControl variant="floating" id="name">
                                                        <Input
                                                            boxShadow="white"
                                                            _hover={{ borderColor: "white" }}
                                                            _focus={{ borderColor: "green.100" }}
                                                            fontSize={"18px"}
                                                            name={"city"}
                                                            height={"55px"}
                                                            type="text"
                                                            value={el.city}
                                                            onChange={(e) => {
                                                                let cityWithOnlyChars = e.target.value.replace(/[^A-Za-z]/g, '');
                                                                let newBen = [...beneficiariesList];
                                                                newBen[el.index].city = cityWithOnlyChars;
                                                                setBeneficiariesList(newBen);
                                                                checkIfAnyDataAdded(newBen);
                                                            }}
                                                            bg="white"
                                                            placeholder=" "
                                                        />
                                                        <FormLabel>City</FormLabel>
                                                        {el.city?.length < 3 && (
                                                            <Text color="red" fontSize="sm">
                                                                {/* City should have at least 3 characters */}
                                                                City is mandatory
                                                            </Text>
                                                        )}
                                                    </FormControl>
                                                    <FormControl variant="floating" id="name">
                                                        <Input
                                                            boxShadow="white"
                                                            _hover={{ borderColor: "white" }}
                                                            _focus={{ borderColor: "green.100" }}
                                                            fontSize={"18px"}
                                                            name={"pinCode"}
                                                            height={"55px"}
                                                            type="number"
                                                            value={"" + el.pinCode}
                                                            onChange={(e) => {
                                                                if (e.target.value.length <= 6) {

                                                                    let newBen = [...beneficiariesList];
                                                                    newBen[el.index].pinCode = Number(e.target.value);
                                                                    setBeneficiariesList(newBen);
                                                                    checkIfAnyDataAdded(newBen);
                                                                }
                                                            }}
                                                            bg="white"
                                                            placeholder=" "
                                                        />
                                                        <FormLabel>PinCode</FormLabel>
                                                        {el.pinCode?.toString().length !== 6 && (
                                                            <Text color="red" fontSize="sm">
                                                                Pin code should be 6 digits
                                                            </Text>
                                                        )}
                                                    </FormControl>
                                                </Stack>
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <Button
                                    w={"40%"}
                                    mt={"15px"}
                                    borderRadius={"46px"}
                                    bg={"#008470"}
                                    color={"white"}
                                    onClick={handleSubmit}
                                    isDisabled={isButtonDisabled}
                                    _hover={{
                                        bg: "#008470",
                                    }}
                                    isLoading={loading}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    {/* <Button isDisabled={true}>hello</Button> */}
                </Box>
            </ChakraProvider>

            {/* Download app modal */}

            <Modal isOpen={isOpen} onClose={() => {
                navigate("/dashboard")
                onClose()
            }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Congratulations!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Congratulations! Your Profile has been activated successfully.
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme='blue'
                            mr={3}
                            onClick={() => {
                                window.open("https://play.google.com/store/apps/details?id=com.pococare.pocoemurgency&hl=en_IN", "_blank");
                                navigate("/dashboard");
                                onClose();
                            }}>
                            Download App
                        </Button>
                        <Button variant='ghost' onClick={() => navigate("/dashboard")}>OK</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddBeneficiaryMinDetails;
