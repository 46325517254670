import {  raisedEmergencyWithQrWithMobile } from "../../../http"

export const qrEmergencyWithMobile = async(benId:string,mobile:string, lat:string |null, lng:string|null) => {

    try{
        const emergencyCall = await raisedEmergencyWithQrWithMobile(benId,mobile, lat, lng);
        return emergencyCall
    }catch(e:any){
        throw e;
    }
}
