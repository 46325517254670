import React from "react";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { MdIntegrationInstructions } from "react-icons/md";

const AdhaarLoginTermCondition = ({
  isChecked,
  handleCheckboxChange,
  setStatusModal,
}: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const handleNext = () => {
    setStatusModal({
      termsConditions: false,
      healthIdInputStatus: true,
      verifyOtpModal: false,
    });
  };
  return (
    <>
      <Box>
        <Button
          bg="#008470"
          width={{ base: "100%" }}
          color="white"
          _hover={{
            bg: "#008470",
          }}
          onClick={onOpen}
        >
          <div style={{ padding: "5px" }}>
            <MdIntegrationInstructions />
          </div>
          Link Using ABHA ID
        </Button>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          size={{ base: "sm", xl: "md" }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign="left">
              Agree Terms and Conditions
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody minW={400}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
               <Box width={"100%"}>
               <TermsAndConditions
                  isChecked={isChecked}
                  handleCheckboxChange={handleCheckboxChange}
                />
               </Box>
                <Button
                  onClick={handleNext}
                  bg="#008470"
                  color="white"
                  mr={3}
                  mt={6}
                  isDisabled={!isChecked}
                  _hover={{
                    bg: "#008470",
                  }}
                >
                  Next
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default AdhaarLoginTermCondition;
