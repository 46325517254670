import { Box,  Divider, Text } from "@chakra-ui/react";
import style from "./Steps.module.css";
import {  useLayoutEffect, useState } from "react";

const Steps = () => {
  const data = [
    {
      step: "1",
      content: "Panic",
    },
    {
      step: "2",
      content:
        "Call friends, family, who themselves may not know what to do",
    },
    
    {
      step: "3",
      content: "Call random ambulance services/hospital",
    },
    
    {
      step: "4",
      content:
        "Wait with uncertainty for the appropriately staffed and equipped ambulance to arrive and take you to the nearest hospital with the required facilities to do with that kind of emergency ",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 3000);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <Box position={"relative"} className={style.wrapper}>
        <Box
          width={{ base: "0px", md: "28px" }}
          height={{ base: "450px", md: "370px" }}
          left={{ base: "25px", md: "21px" }}
          top={{ base: "0px", md: "28px" }}
          position="absolute"
          opacity="0.50"
          borderLeft={{
            base: "2px #293A41 dashed",
            md: "2px #293A41 dotted",
          }}
        ></Box>
        <Box className={style.list}>
          {data?.map((el: any, i) => {
            return (
              <Box key={i + 1} className={style.flex_List}>
                <Box className={style.steps_width}>
                  <Box
                    className={
                      currentIndex === i
                        ? style.circle_selected
                        : style.circle_unselcted
                    }
                  >
                    {el.step}
                  </Box>
                  <Divider
                    orientation="vertical"
                    borderColor="red.500" // Set your desired color
                    height="100%" // Set the height to match the container height
                  />
                </Box>
                <Box w={"100%"}>
                  <Text
                    className={
                      currentIndex === i
                        ? style.text_Selected
                        : style.text_Unselected
                    }
                  >
                    {el.content}
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default Steps;
