import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, Flex, FormControl, FormLabel, HStack, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Switch, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ContactStyle from "./EmergencyContact.module.css"
import { IoLogoWhatsapp } from 'react-icons/io5'
import { BiMobile } from 'react-icons/bi'
import { RiDeleteBinLine } from 'react-icons/ri';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr';
import { MdOutlineEdit } from 'react-icons/md'
import PhoneInput from 'react-phone-input-2';
import classNames from "classnames";
import { ShowAlert } from '../../../../GlobalComponents/ShowAlert/ShowAlert';
import { addEmergencyContact, deleteEmergencyContact, getSingleBeneficiaryData, updateEmergencyContact } from '../../Actions/beneficiaryDetails.action';
import { useSelector } from 'react-redux'

interface EmergencyContactProps {
  changeStep: (step: number) => void;
  setData: (data: any) => void;
  data: any;
}
interface BeneficiaryData {
  personalInfo?: {
    fullName?: string;
    mobile?: {
      number?: string;
      countryCode?: string;
    };
    email?: string;
    relationshipToSubscriber: string;
    whatsappMobile?: {
      number?: string;
      countryCode?: string;
    },
    gender?: string;
  };
}

export const EmergencyContact: React.FC<EmergencyContactProps> = ({
  changeStep,
  setData,
  data,
}) => {
  const [sameWhatsapp, setSameWhatsapp] = useState<boolean>(false);
  const [emergencyCoontact, setEmergencyContact] = useState('')
  const [otherBeneficiaryData, setOtherBeneficiaryData] = useState<BeneficiaryData[]>([])
  const phoneInputClass = classNames(ContactStyle.myPhoneInputClass);
  const beneficiaryData = useSelector((state: any) => state.beneficiary);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [mobile, setMobile] = useState<string>('');
  const [whatsapp, setWhatsapp] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [relation, setRelation] = useState<string>('');
  const [priority, setPriority] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number | undefined>();
  const initialRef = React.useRef(null);
  const cancelRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>();
  const [deleteData, setDeleteData] = useState<any>({});

  const handlePhone = (value: string) => {
    setMobile(value);
  };

  const handleSameWhatsapp = () => {
    setSameWhatsapp(!sameWhatsapp);

    if (!sameWhatsapp) {
      setWhatsapp(mobile);
    }
  };

  const handleWhatsapp = (value: string) => {
    setWhatsapp(value);
  };

  const addContact = async () => {
    if (!name || name.trim() === '') {
      return ShowAlert('warning', 'Name is required');
    }

    if (!relation) {
      return ShowAlert('warning', 'Relationship is required');
    }

    if (!mobile) {
      return ShowAlert('warning', 'Mobile number is required');
    }

    if (!sameWhatsapp && !whatsapp) {
      return ShowAlert('warning', 'Whatsapp number is required');
    }

    if (whatsapp.length !== 10) {
      return ShowAlert('warning', 'Whatsapp number must be 10 digits');
    }

    if (mobile.length !== 10) {
      return ShowAlert('warning', 'Mobile number must be 10 digits');
    }

    if (mobile.toString() === data.personalInfo?.mobile?.number.toString()) {
      return ShowAlert('warning', 'You cannot add your own contact');
    }

    let contactPayload = {
      fullName: name,
      mobile: {
        countryCode: 91,
        number: Number(mobile)
      },
      whatsappMobile: {
        countryCode: 91,
        number: Number(whatsapp)
      },
      isPrimary: priority,
      relationshipToBeneficiary: relation
    }
    if (sameWhatsapp) {
      contactPayload = { ...contactPayload, whatsappMobile: { countryCode: 91, number: contactPayload.mobile.number } }
    }


    try {
      await addEmergencyContact(data._id, contactPayload);
      let updatedData = await getSingleBeneficiaryData(data._id);
      ShowAlert('success', 'Contact Successfully Added');
      setName('');
      setMobile('');
      setRelation('');
      setWhatsapp('');
      setEmergencyContact('');
      setPriority(false);
      setSameWhatsapp(false);
      setData(updatedData);
      onClose();
    } catch (error: any) {
      console.log("Err while creating contact", error);
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
        return ShowAlert("warning", error.response.data.message);
      }
      ShowAlert('warning', 'Something went wrong, Please try again later');
    }
  };

  const openEdit = (data: any, i: number) => {
    setName(data.fullName);
    setMobile(String(data.mobile.number));
    setRelation(data?.relationshipToBeneficiary);
    setWhatsapp(String(data?.whatsappMobile?.number));
    setSameWhatsapp(data?.mobile?.number === data?.whatsappMobile?.number ? true : false);
    setPriority(data?.isPrimary || false);
    setEditIndex(i);
    onEditOpen();
  };

  const openDelete = (el: any, i: number) => {
    setDeleteIndex(i);
    setDeleteData(el);
    onDeleteOpen();
  };

  const editContact = async () => {
    if (!name || !relation || !mobile || name.trim() === "") {
      return ShowAlert('warning', 'All Fields are required');
    }

    if (mobile.toString() === data.personalInfo?.mobile?.number.toString()) {
      return ShowAlert('warning', 'You cannot add your own contact');
    }

    console.log(data.emergencyContacts[0]._id, "data")
    const contactPayload = {
      _id: data.emergencyContacts[editIndex || 0]._id,
      fullName: name,
      mobile: {
        countryCode: 91,
        number: Number(mobile)
      },
      email: "test@gmail.com",
      whatsappMobile: {
        countryCode: 91,
        number: Number(whatsapp)
      },
      isPrimary: priority,
      relationshipToBeneficiary: relation
    }

    let newContactState = [];
    for (let i = 0; i < data.emergencyContacts.length; i++) {
      if (i !== editIndex) {
        newContactState.push(data.emergencyContacts[i]);
      } else {
        newContactState.push(contactPayload);
      }
    }
    let emergencyContacts = newContactState;
    console.log(contactPayload, "contactPayload");
    try {
      await updateEmergencyContact(data._id, contactPayload);
      setData({ ...data, emergencyContacts });
      ShowAlert('success', 'Contact Successfully Updated');
      setName('');
      setMobile('');
      setRelation('');
      setWhatsapp('');
      setPriority(false);
      setSameWhatsapp(false);
      onEditClose();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }

        return ShowAlert("warning", error.response.data.message);
      }
      return ShowAlert('warning', 'Something went wrong, Please try again later');
    }
  };

  const deleteContact = async () => {
    let newContactState = [];
    for (let i = 0; i < data.emergencyContacts.length; i++) {
      if (i !== deleteIndex) {
        newContactState.push(data.emergencyContacts[i]);
      }
    }
    let emergencyContacts = newContactState;

    const deletePayload = {
      countryCode: deleteData.mobile.countryCode,
      number: deleteData.mobile.number,
    }

    try {
      await deleteEmergencyContact(data._id, deletePayload);
      ShowAlert('success', 'Contact Successfully Deleted');
      setData({ ...data, emergencyContacts });
      onEditClose();
      onDeleteClose();
    } catch (error: any) {
      console.log("Err while deleting contact", error);
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
      }
      ShowAlert('warning', 'Something went wrong, Please try again later');
      onEditClose();
      onDeleteClose();
    }
  };

  const handleCancel = () => {
    setName('');
    setMobile('');
    setRelation('');
    setWhatsapp('');
    setPriority(false);
    setSameWhatsapp(false);
    onEditClose();
  };

  const filterOtherBeneficiary = () => {
    setOtherBeneficiaryData(beneficiaryData.filter((el: any) => el._id !== data._id));
  };

  const selectEmergencyContact = (e: any) => {
    if (!e.target.value) {
      setEmergencyContact('')
      setWhatsapp('')
      setRelation('')
      setMobile('')
      setName('')
      setSameWhatsapp(false)
      return
    }
    setEmergencyContact(e.target.value)
    setSameWhatsapp(false)
    const relationShip =
      otherBeneficiaryData[e.target.value].personalInfo?.relationshipToSubscriber === 'Self'
        ? findRelationshipWithSelfBen(
          data?.personalInfo?.relationshipToSubscriber || "",
          otherBeneficiaryData[e.target.value].personalInfo?.gender || ""
        )
        : otherBeneficiaryData[e.target.value].personalInfo?.relationshipToSubscriber === 'Spouse' && data?.personalInfo?.relationshipToSubscriber !== 'Self'
          ? 'Others' :
          data?.personalInfo?.relationshipToSubscriber === 'Spouse' && otherBeneficiaryData[e.target.value].personalInfo?.relationshipToSubscriber !== 'Self' ? "Others" :
            data?.personalInfo?.relationshipToSubscriber === 'Others' ? "Others" :
              data?.personalInfo?.relationshipToSubscriber === 'Mother' && otherBeneficiaryData[e.target.value].personalInfo?.relationshipToSubscriber === 'Father' ? 'Spouse' :
                data?.personalInfo?.relationshipToSubscriber === 'Father' && otherBeneficiaryData[e.target.value].personalInfo?.relationshipToSubscriber === 'Mother' ? 'Spouse' :
                  data?.personalInfo?.relationshipToSubscriber === 'Father-in-law' && otherBeneficiaryData[e.target.value].personalInfo?.relationshipToSubscriber === 'Mother-in-law' ? 'Spouse' :
                    data?.personalInfo?.relationshipToSubscriber === 'Mother-in-law' && otherBeneficiaryData[e.target.value].personalInfo?.relationshipToSubscriber === 'Father-in-law' ? 'Spouse'
                      : otherBeneficiaryData[e.target.value].personalInfo?.relationshipToSubscriber || "";

    setName(otherBeneficiaryData[e.target.value].personalInfo?.fullName || "");
    setMobile(`${otherBeneficiaryData[e.target.value].personalInfo?.mobile?.number}` || "");
    setRelation(relationShip === null ? "" : relationShip);
    setWhatsapp(`${otherBeneficiaryData[e.target.value].personalInfo?.whatsappMobile?.number}` || "");
  }

  const findRelationshipWithSelfBen = (relationShip: string, gender: string) => {
    if (!gender || !relationShip) {
      return null
    }

    switch (relationShip) {
      case "Mother":
        return gender === 'Male' ? 'Son' : 'Daughter';
      case "Father":
        return gender === 'Male' ? 'Son' : 'Daughter';
      case "Daughter":
        return gender === 'Male' ? 'Father' : 'Mother';
      case "Son":
        return gender === 'Male' ? 'Father' : 'Mother';
      case "Sister":
        return gender === 'Male' ? 'Brother' : 'Sister';
      case "Brother":
        return gender === 'Male' ? 'Brother' : 'Sister';
      case "Spouse":
        return 'Spouse'
      default:
        return 'Others';
    }

  }

  useEffect(() => {
    filterOtherBeneficiary();// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {data.emergencyContacts.length ? (
        <>
          <div className={ContactStyle.contactCardContainer}>
            {data.emergencyContacts.map((el: any, i: number) => (
              <div key={i}>
                <Card width={'90%'} marginBottom={'10px'} bg={'#F5F9F9'}>
                  <CardHeader paddingBottom={'0px'}>
                    {/* @ts-ignore */}
                    <Flex spacing="4">
                      <Flex flex="1" gap="4" flexWrap="wrap" alignItems='flex-start'>
                        <Avatar name={el.fullName} src="https://bit.ly/sage-adebayoz" />
                        <Box>
                          <Heading size="sm">{el.fullName}</Heading>
                          <Text>{el.relationshipToBeneficiary}</Text>
                        </Box>
                      </Flex>
                    </Flex>
                  </CardHeader>
                  <CardBody>
                    <Flex flex="1" gap="4" flexWrap="wrap" alignItems='flex-start' justifyContent={el.isPrimary ? 'space-between' : 'none'}>
                      <Box>
                        {el.whatsappMobile ? (
                          <HStack marginBottom={'10px'} gap={'5px'}>
                            <IoLogoWhatsapp fontSize={'25px'} color="green" />
                            <Text fontWeight="500">
                              {el.whatsappMobile.number}
                            </Text>
                          </HStack>
                        ) : (
                          ''
                        )}

                        {el.mobile ? (
                          <HStack gap={'5px'}>
                            <BiMobile fontSize={'25px'} color="red" />
                            <Text fontWeight="500">
                              {el.mobile.number}
                            </Text>
                          </HStack>
                        ) : (
                          ''
                        )}
                      </Box>
                      {el.isPrimary ? <Box bg='#f68524' color='white' padding='2px 5px' borderRadius='5px'>Primary</Box> : ""}
                    </Flex>
                  </CardBody>
                  <CardFooter justify="space-between" flexWrap="wrap" paddingTop={'0px'}>
                    <MdOutlineEdit
                      className={ContactStyle.greenIcon}
                      onClick={() => openEdit(el, i)}
                    />
                    <RiDeleteBinLine
                      className={ContactStyle.redIcon}
                      onClick={() => openDelete(el, i)}
                    />
                  </CardFooter>
                </Card>
                <Modal
                  initialFocusRef={initialRef}
                  finalFocusRef={finalRef}
                  isOpen={isEditOpen}
                  onClose={handleCancel}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Edit Contact</ModalHeader>
                    <ModalCloseButton onClick={handleCancel} />
                    <ModalBody pb={6}>
                      <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        marginTop={'20px'}
                        bottom={'20px'}
                        size={'md'}
                        placeholder="Full Name"
                        type="text"
                      />
                      <Select
                        value={relation}
                        onChange={(e) => setRelation(e.target.value)}
                        marginBottom={'8px'}
                        bottom={'8px'}
                        size={'md'}
                        width={'100%'}
                        placeholder="Relationship to beneficiary"
                      >
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Son">Son</option>
                        <option value="Daughter">Daughter</option>
                        <option value="Brother">Brother</option>
                        <option value="Spouse">Spouse</option>
                        <option value="Grand Mother">Grand Mother</option>
                        <option value="Grand Father">Grand Father</option>
                        <option value="Father-in-law">Father-In-Law</option>
                        <option value="Mother-In-Law">Mother-In-Law</option>
                        <option value="Others">Others</option>
                      </Select>
                      <PhoneInput
                        country="in"
                        onlyCountries={["in"]}
                        autoFormat={false}
                        disableCountryCode={true}
                        inputClass={phoneInputClass}
                        value={mobile}
                        placeholder="Mobile Number"
                        //  @ts-ignore
                        name="Mobile Number"
                        onChange={(value) => {
                          handlePhone(value);
                        }}
                      />
                      <FormControl marginBottom={'15px'} gap="20px" display="flex" alignItems="center">
                        <Switch
                          isDisabled={!mobile ? true : false}
                          onChange={() => handleSameWhatsapp()}
                          isChecked={sameWhatsapp ? true : false}
                          id="same-whatsappNumber"
                        />
                        <FormLabel color="#1C2B2F" htmlFor="same-whatsappNumber" mb="0">
                          WhatsApp number is the same as the mobile number
                        </FormLabel>
                      </FormControl>
                      {sameWhatsapp ? (
                        ''
                      ) : (
                        <PhoneInput
                          country="in"
                          onlyCountries={["in"]}
                          autoFormat={false}
                          disableCountryCode={true}
                          inputClass={phoneInputClass}
                          value={whatsapp}
                          placeholder='Whatsapp Number'
                          //  @ts-ignore
                          name="Whatsapp Number"
                          onChange={(value) => {
                            handleWhatsapp(value);
                          }}
                        />
                      )}
                      <FormControl marginBottom={'15px'} gap="20px" display="flex" alignItems="center">
                        <Switch
                          onChange={() => setPriority(!priority)}
                          isChecked={String(priority) === String('true') ? true : false}
                          id="priority"
                        />
                        <FormLabel color="#1C2B2F" htmlFor="priority" mb="0">
                          Set as a priority contact
                        </FormLabel>
                      </FormControl>
                    </ModalBody>

                    <ModalFooter>
                      <Button onClick={editContact} bgColor="#38a169" color={"white"} mr={3}>
                        Save Changes
                      </Button>
                      <Button onClick={handleCancel}>Cancel</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div>
            ))}
          </div>
          <HStack marginTop={'30px'} justifyContent="space-between">
            <GrFormPrevious
              className={ContactStyle.redIcon}
              onClick={() => changeStep(-1)}
            />
            <Button
              onClick={onOpen}
              isDisabled={data.emergencyContacts.length > 2 ? true : false}
              colorScheme="blue"
              variant={'solid'}
            >
              Add Another Contact
            </Button>
            <GrFormNext
              onClick={() => changeStep(+1)}
              className={ContactStyle.greenIcon}
            />
          </HStack>
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add New Contact</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl marginBottom={'15px'} alignItems="center">
                  <FormLabel color="#1C2B2F" htmlFor="same-whatsappNumber" width={'100%'} mb={3}>
                    Choose emergencyContacts from existing beneficiary
                  </FormLabel>
                  <Select
                    value={emergencyCoontact}
                    marginBottom={'8px'}
                    bottom={'8px'}
                    size={'md'}
                    width={'100%'}
                    placeholder="Choose emergency contact"
                    onChange={selectEmergencyContact}
                  >
                    {otherBeneficiaryData.map((el: any, i: number) => (
                      el.personalInfo?.mobile?.number ? (
                        <option value={i.toString()} key={i}>
                          {el.personalInfo?.fullName || ""} {" -"}
                          {` +${el.personalInfo?.mobile?.countryCode} ${el.personalInfo?.mobile?.number}`}
                        </option>
                      ) : null
                    ))}
                  </Select>
                </FormControl>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  marginTop={'20px'}
                  bottom={'20px'}
                  size={'md'}
                  placeholder="Full Name"
                  type="text"
                />
                <Select
                  value={relation}
                  onChange={(e) => setRelation(e.target.value)}
                  marginBottom={'8px'}
                  bottom={'8px'}
                  size={'md'}
                  width={'100%'}
                  placeholder="Relationship to beneficiary"
                >
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Son">Son</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Brother">Brother</option>
                  <option value="Sister">Sister</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Grand Mother">Grand Mother</option>
                  <option value="Grand Father">Grand Father</option>
                  <option value="Father-in-law">Father-In-Law</option>
                  <option value="Mother-In-Law">Mother-In-Law</option>
                  <option value="Others">Others</option>
                </Select>
                <PhoneInput
                  country="in"
                  onlyCountries={["in"]}
                  autoFormat={true}
                  disableCountryCode={true}
                  inputClass={phoneInputClass}
                  value={mobile}
                  placeholder="Mobile Number"
                  //  @ts-ignore
                  name="Phone Number"
                  onChange={(value) => {
                    handlePhone(value);
                  }}
                />
                <FormControl marginBottom={'15px'} gap="20px" display="flex" alignItems="center">
                  <Switch
                    isDisabled={!mobile ? true : false}
                    onChange={() => handleSameWhatsapp()}
                    isChecked={sameWhatsapp ? true : false}
                    id="same-whatsappNumber"
                  />
                  <FormLabel color="#1C2B2F" htmlFor="same-whatsappNumber" mb="0">
                    WhatsApp number is the same as the mobile number
                  </FormLabel>
                </FormControl>
                {sameWhatsapp ? (
                  ''
                ) : (
                  <PhoneInput
                    country="in"
                    onlyCountries={["in"]}
                    autoFormat={true}
                    disableCountryCode={true}
                    inputClass={phoneInputClass}
                    value={whatsapp}
                    placeholder='Whatsapp Number'
                    //  @ts-ignore
                    name="Whatsapp Number"
                    onChange={(value) => {
                      handleWhatsapp(value);
                    }}
                  />
                )}
                <FormControl marginBottom={'15px'} gap="20px" display="flex" alignItems="center">
                  <Switch
                    onChange={() => setPriority(!priority)}
                    isChecked={priority ? true : false}
                    id="priority"
                  />
                  <FormLabel color="#1C2B2F" htmlFor="priority" mb="0">
                    Set as a priority contact
                  </FormLabel>
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button onClick={addContact} bgColor="#38a169"  color="white" mr={3}>
                  Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <>
          <>
            <FormControl marginBottom={'15px'} alignItems="center">
              <FormLabel color="#1C2B2F" htmlFor="same-whatsappNumber" width={'100%'} mb={3}>
                Choose emergencyContacts from existing beneficiary
              </FormLabel>
              <Select
                value={emergencyCoontact}
                marginBottom={'8px'}
                bottom={'8px'}
                size={'md'}
                width={'100%'}
                placeholder="Choose emergency contact"
                onChange={selectEmergencyContact}
              >
                {otherBeneficiaryData.map((el: any, i: number) => (
                  el.personalInfo?.mobile?.number ? (
                    <option value={i.toString()} key={i}>
                      {el.personalInfo?.fullName || ""} {" -"}
                      {` +${el.personalInfo?.mobile?.countryCode} ${el.personalInfo?.mobile?.number}`}
                    </option>
                  ) : null
                ))}
              </Select>
            </FormControl>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              marginTop={'20px'}
              bottom={'20px'}
              size={'md'}
              placeholder="Full Name"
              type="text"
            />
            <Select
              value={relation}
              onChange={(e) => setRelation(e.target.value)}
              marginBottom={'8px'}
              bottom={'8px'}
              size={'md'}
              width={'100%'}
              placeholder="Relationship to beneficiary"
            >
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Brother">Brother</option>
              <option value="Sister">Sister</option>
              <option value="Spouse">Spouse</option>
              <option value="Grand Mother">Grand Mother</option>
              <option value="Grand Father">Grand Father</option>
              <option value="Father-in-law">Father-In-Law</option>
              <option value="Mother-In-Law">Mother-In-Law</option>
              <option value="Others">Others</option>
            </Select>

            <PhoneInput
              country="in"
              onlyCountries={["in"]}
              autoFormat={true}
              disableCountryCode={true}
              inputClass={phoneInputClass}
              value={mobile}
              placeholder="Mobile Number"
              //  @ts-ignore
              name="Phone Number"
              onChange={(value) => {
                handlePhone(value);
              }}
            />
            <FormControl marginBottom={'15px'} gap="20px" display="flex" alignItems="center">
              <Switch
                isDisabled={!mobile ? true : false}
                onChange={() => handleSameWhatsapp()}
                isChecked={sameWhatsapp ? true : false}
                id="same-whatsappNumber"
              />
              <FormLabel color="#1C2B2F" htmlFor="same-whatsappNumber" mb="0">
                WhatsApp number is the same as the mobile number
              </FormLabel>
            </FormControl>
            {sameWhatsapp ? (
              ''
            ) : (
              <PhoneInput
                country="in"
                onlyCountries={["in"]}
                autoFormat={true}
                disableCountryCode={true}
                inputClass={phoneInputClass}
                value={whatsapp}
                placeholder='Whatsapp Number'
                //  @ts-ignore
                name="Whatsapp Number"
                onChange={(value) => {
                  handleWhatsapp(value);
                }}
              />
            )}
            <FormControl marginBottom={'15px'} gap="20px" display="flex" alignItems="center">
              <Switch
                onChange={() => setPriority(!priority)}
                isChecked={priority ? true : false}
                id="priority"
              />
              <FormLabel color="#1C2B2F" htmlFor="priority" mb="0">
                Set as a priority contact
              </FormLabel>
            </FormControl>
          </>
          <HStack justifyContent="space-between" spacing={12}>
            <GrFormPrevious
              className={ContactStyle.redIcon}
              onClick={() => changeStep(-1)}
            />
            <Button onClick={addContact} colorScheme="green" variant={'solid'}>
              Save Contact
            </Button>
          </HStack>
        </>
      )}
      {/* deleteAlert */}
      <AlertDialog isOpen={isDeleteOpen} leastDestructiveRef={cancelRef} onClose={onDeleteClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Contact
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteContact} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
