import { Box, HStack, Image, Text, useMediaQuery } from "@chakra-ui/react";
import style from "./Er.module.css";
import ListIteams from "../ListItems/ListItems";
interface ErCardProps {
  cardName: string;
}
const ErCard: React.FC<ErCardProps> = ({ cardName }) => {
  const [isLessThan540] = useMediaQuery("(min-width: 540px)");

  const erData = [
    {
      imageUrl: "selected.svg",
      content:
        "Register patient in Pococare system with details (addresses,emergency contacts, medical information)",
    },
    {
      imageUrl: "selected.svg",
      content: "Provide physical and online alert material",
    },
    { imageUrl: "selected.svg", content: "Add to Poco system" },
    {
      imageUrl: "selected.svg",
      content: "Scan the QR code to generate a call",
    },
  ];

  return (
    <>
      <Box className={style.container}>
        <Box className={style.wrapper}>
          <Box className={style.card_flex}>
            <Box className={style.card_container}>
              <Text className={style.card_heading_style}>
                Emergency Response Readiness
              </Text>
            </Box>
            <Box className={style.card_container}>
              <Text className={style.card_name_style}>Patient</Text>
            </Box>
            <Box>
              {erData.map((el:any,i:number) => {
                return (
                  <>
                    <Box key={i+1} className={style.card_space}>
                      <ListIteams
                      
                        imageUrl={`Images/${el.imageUrl}`}
                        content={el.content}
                        imageWidth={isLessThan540?"5%":"8%"}
                        contentWidth="92%"
                      />
                    </Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </Box>
        <HStack w={"100%"} justify={"center"} mt={"0"}>
          <Image className={style.qr_img}  src="Images/Pococare qr Code.png" />
        </HStack>
      </Box>
    </>
  );
};

export default ErCard;
