import { getHospitalById } from "../../../http";


export const getHospitalByIdAction = async (id: string) => {
  try {

    const { data } = await getHospitalById(id);
    return data.data;

  } catch (e:any) {
      console.error("error from getting single ben data");
      throw e
  }
};
