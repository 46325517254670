import React, { useState, useEffect } from 'react';

interface TimerProps {
  time: number;
  setResendAllowed?: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  handleCall?: () => void;
}

const Timer: React.FC<TimerProps> = ({ time, setResendAllowed, handleCall, type }) => {
  const [timer, setTimer] = useState(time);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else {
      if (type === 'call' && handleCall) {
        handleCall(); // Call handleCall if it exists and type is 'call'
      } else if (type === 'otp' && setResendAllowed) {
        setResendAllowed(true); // Set resend allowed to true if type is 'otp'
      }
    }
  }, [timer, setResendAllowed, handleCall, type]);

  return <>{timer < 10 ? ` 0${timer}` : ` ${timer}`}</>;
};

export default Timer;
