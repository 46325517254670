import { Box, Text } from "@chakra-ui/react";
import React from "react";
import style from "./Paragraph.module.css";

interface ParagraphProps {
    title:string,
    content:string
}
export const Paragraph: React.FC<ParagraphProps> = ({ title, content }) => {
  return (
    <>
      <Box className={style.wrapper}>
        <Text className={style.headingStyle}>{title}</Text>
        <Text className={style.text_Style}>{content}</Text>
      </Box>
    </>
  );
};