import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import style from "./Er.module.css";
import ListIteams from "../ListItems/ListItems";
import React from "react";

const ErmCard = () => {
  const [isLessThan540] = useMediaQuery("(min-width: 540px)");
  const erData = [
    {
      heading: "Scan/Call/SOS button",
      imageUrl: "selected.svg",
      content: [
        "Notify emergency contacts",
        "Keep updated with messages till hospital drop",
      ],
    },
    {
      heading: "Virtual doctor",
      imageUrl: "selected.svg",
      content: [
        "Consultation with virtual doctor for primary ERM",
        "Share part medical data with doctor",
      ],
    },
    {
      heading: "Ambulance",
      imageUrl: "selected.svg",
      content: [
        "Arrange ambulance",
        "Patient taken to nearest appropriately equipped hospital",
      ],
    },
    {
      heading: "Early visibility",
      imageUrl: "selected.svg",
      content: [
        "Send past and current medical information to hospital ER before ambulance reaches ER",
      ],
    },
  ];
  return (
    <>
      <Box className={style.container}>
        <Box className={style.wrapper}>
          <Box  className={style.card_flex}>
            <Box className={style.card_container}>
              <Text className={style.card_heading_style}>
                Emergency Response Management
              </Text>
            </Box>

            <Box>
              {erData.map((item,i) => {
                return (
                  <>
                    <Box className={style.card_heading_space_em} key={i}>
                      <Box className={style.card_Text_container}>
                        <Box className={style.card_name_style}>
                       { isLessThan540?<Text >
                         {item.heading}
                        </Text>: <ListIteams
                              imageUrl={`Images/${item.imageUrl}`}
                              content={item.heading}
                              imageWidth="8%"
                              contentWidth="92%"
                            />}
                        </Box>
                      </Box>
                      {item.content.map((el:any,i:number) => {
                        return (
                          <React.Fragment key={i+1}>

                          <Box  className={style.card_space_em}>
                            <ListIteams
                              imageUrl={isLessThan540?`Images/${item.imageUrl}`:""}
                              content={el}
                              imageWidth="5%"
                              contentWidth="92%"
                            />
                          </Box>
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </Box>
             </Box>
    </>
  );
};

export default ErmCard;
