const config = {
    development: {
        api: {
            baseURL: process.env.REACT_APP_API_BASE_URL,
            frontEndURL: process.env.REACT_APP_FRONT_END_URL
        }
    },
    production: {
        api: {
            baseURL: process.env.REACT_APP_API_BASE_URL,
            frontEndURL: process.env.REACT_APP_FRONT_END_URL
        },
    }
};


export default config[process.env.NODE_ENV];