import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
export const beneficiarySlice = createSlice({
    name: "beneficiary",
    initialState,
    reducers: {
        saveBeneficiary: (state, action) => {
           state=action.payload
            return state;
        },
        addBeneficiary:(state, action) =>{
            state=[...state,action.payload]
            return state
        },
        removeBeneficiary: (state) => {
            state = [];
            return state;
        },
    },
});
export const { saveBeneficiary, addBeneficiary, removeBeneficiary } = beneficiarySlice.actions;
export default beneficiarySlice.reducer;
