import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Landing from '../Pages/Landing/Landing'
import DashBoard from '../Pages/DashBoard/DashBoard'
import Profile from '../Pages/Profile/Profile'
import AddBeneficiary from '../Pages/AddBeneficiary/AddBeneficiary'
import AddBeneficiaryMinDetails from '../Pages/AddBeneficiaryMinDetails/AddBeneficiaryMinDetails'
import BeneficiaryDetails from '../Pages/BeneficiaryDetails/BeneficiaryDetails'
import TriggerEmergency from '../Pages/TriggerEmergency/TriggerEmergency'
import PrivacyPolicy from '../Pages/PrivacyPolicy/PrivacyPolicy'
import NotFound from '../Pages/NotFound/NotFound'
import Careers from '../Pages/Careers/Careers'
import PrivateRoute from './PrivateRoute/PrivateRoute'
import GuestRoute from './GuestRoute/GuestRoute'
import AboutUs from '../Pages/Aboutus/About'
import ContactUs from '../Pages/ContactUs/ContactUs'
import { PwaDashboard } from '../Pages/PwaDashboard/PwaDashboard'
import TermsAndConditions from '../Pages/PrivacyPolicy/TermsAndConditions'
import Activation from '../Pages/Activation/Activation'
import ReactGA from 'react-ga';

const MainRoute: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  if (location.pathname !== "/" && location.pathname !== "/companyNotFound") {
    let path = location.pathname.split("/")
    if (path.includes("addBenFullDetails")) {
      sessionStorage.setItem("lastVisitedPage", JSON.stringify(location.pathname))
    } else if (path.includes("profile") && path.includes("view")) {
      sessionStorage.setItem("lastVisitedPage", JSON.stringify(location.pathname))
    }
  }

  return (
    <Routes>
      <Route path="/" element={
        <GuestRoute>
          <Landing />
        </GuestRoute>
      } />
      <Route path="/addBeneficiaries" element={<AddBeneficiary />} />
      <Route path="/addBenMinDetails" element={<AddBeneficiaryMinDetails />} />

      <Route path="/dashboard" element={
        <PrivateRoute>
          <DashBoard />
        </PrivateRoute>
      } />
      <Route path="/profile/view/:profileId" element={
        <PrivateRoute><Profile /></PrivateRoute>
      } />
      <Route
        path="/:companyName"
        element={
          <GuestRoute>
            <Landing />
          </GuestRoute>
        }
      />
      <Route path="/addBenFullDetails/:benId/:step?" element={<PrivateRoute><BeneficiaryDetails /></PrivateRoute>} />
      <Route path="/trigger-emergency/:companyId" element={<TriggerEmergency />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/terms&condition" element={<TermsAndConditions />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      <Route path='/emergency' element={<PwaDashboard />} />
      <Route path="/companyNotFound" element={<NotFound />} />
      <Route path='/activateAccount/:tempBenId' element={<Activation />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default MainRoute
