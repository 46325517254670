// ScrollContext.js
import React, { createContext, useContext, useState } from "react";

const ScrollContext = createContext();
export const useScroll = () => useContext(ScrollContext);

export const ScrollSection = ({ children }) => {
    const [scrollToSection, setScrollToSection] = useState(null);
    console.log('children:', children);
    return (
        <ScrollContext.Provider value={{ scrollToSection, setScrollToSection }}>
            {children}
        </ScrollContext.Provider>
    );
};


