import { Box, Image, Text } from "@chakra-ui/react";
import style from "./ListItems.module.css"

interface ListIteamsProps{
    imageUrl:string;
    content:string;
    imageWidth:string;
    contentWidth:string;
}

const ListIteams:React.FC<ListIteamsProps>=({imageUrl,content,imageWidth,contentWidth})=>{
    return(<>
    <Box className={style.wrapper}>
        <Box className={style.flex_list}>
           <Box  w={imageWidth}  className={style.image_wrapper}>
                {imageUrl!==""&& <Image src={imageUrl} alt="Icon" />}
            </Box>
            <Box w={contentWidth} >
                <Text className={style.text_wrapper}>{content}</Text>
            </Box>
        </Box>
    </Box>
    </>)

  }
  export default ListIteams;