import {
  Box,
  Flex,
  Text,  VStack,  useMediaQuery,
} from "@chakra-ui/react";
import  { useEffect } from "react";
import styleMid from "./MidSection.module.css";
import MidCard from "./MidCard/MidCard";
// import Accordions from "./Accordion/Accordion";
import { useScroll } from "../../../GlobalComponents/Footer/scroll/ScrollSection";
import Steps from "./Steps/Step";
import FlexCard from "./FlexCard/FlexCard";
import PocoBridge from "./PocoBridge/PocoBridge";
import ListIteams from "./ListItems/ListItems";
import ErCard from "./ErCard/ErCard";
import ErmCard from "./ErCard/EmCard";

const preciousTimeDatas = [
  {
    imageUrl: "precious_img_1.svg",
    content:
      "Minutes = “Golden Hour” Life / Death​ Severity impact",
      title:"60"
  },
  {
    imageUrl: "precious_img_2.svg",
    content:
      "People do not get timely medical attention in emergencies",
      title:"90%"
  },
  {
    imageUrl: "precious_img_3.svg",
    content:
      "Deaths per day due to delayed attention",
      title:"24,000+"
  },
  {
    imageUrl: "precious_img_4.svg",
    content:
      "Avoidable deaths by the time you finish reading this page",
      title:"10+"
  },
];
const impactDatas = [
  {
    imageUrl: "impact_img_1.jpg",
    content: "Fastest response from incident to ER. Reliable every time",
  },  
  {
    imageUrl: "impact_img_3.svg",
    content:
      "Immediate assessment by Virtual Doctor. Possible immediate interventions on Doctor’s instructions",
  },
  {
    imageUrl: "impact_img_4.svg",
    content:
      "Patients Medical Record’s, current condition and virtual doctor’s diagnosis sent to hospital ER pre arrival",
  },
  {
    imageUrl: "impact_img_2.svg",
    content: "Patient bought to nearest appropriately equipped hospital every time, No diversion to other hospitals",
  },
];
interface MidSectionProps{
  secondSectionRef:any
}
const MidSection:React.FC<MidSectionProps> = ({secondSectionRef}) => {
  const { scrollToSection } = useScroll();

  useEffect(() => {
    if (scrollToSection) {
      const targetSection = document.getElementById(scrollToSection);
      if (targetSection) {
        const additionalOffset = 100; // You can adjust this value as needed
        const scrollPosition = targetSection.offsetTop - additionalOffset;
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [scrollToSection]);
  const [isLessThan540] = useMediaQuery("(min-width: 540px)");
  return (
    <>
      {/* sec-1*/}
      <Box className={styleMid.wrapper}>
        <Box>
          <Box id="section1"  className={styleMid.container_parent}>
            <Box ref={secondSectionRef} className={styleMid.container}>
              <Box>
                <Text className={styleMid.heading_text}>
                What does a person do when a medical emergency occurs at home, work, or outside ? 
                </Text>
              </Box>
            </Box>
            <Box>
            <Box className={styleMid.inner_Container}>
                <Box className={styleMid.steps}>
                  <Steps />
                </Box>
                <Box className={styleMid.right_parent}>
                 { isLessThan540? <Box className={styleMid.right_Container}>
                    {/* <Box w="100%"  className={styleMid.flex_Container}>
                      <Box  w={"45%"}>
                        <Vector content={"Who can help?"} />
                      </Box>
                      <Box w={"45%"}>
                      <Vector content={"Who is he or she?"} />
                      </Box>
                    </Box> */}
                    <Box className={styleMid.flex_Container}>
                      <Box h={"100%"} w={"45%"}>
                        <MidCard
                          percentage={85}
                          content={
                            "Friends/colleagues, roadside strangers do not have details of the patient (past relevant history, blood type, insurance etc...)"
                          }
                        />
                      </Box>
                      <Box w={"45%"}>
                        <MidCard
                          percentage={95}
                          content={
                            "People lack awareness of basic emergency support procedures "
                          }
                        />
                      </Box>
                    </Box>
                  </Box>: 
                  <Box className={styleMid.right_Container} >
                    <Box   w={"100%"}>
                      <VStack  margin={"auto"} w={"80%"}>
                        <MidCard
                          percentage={85}
                          content={
                            "Friends/colleagues, roadside strangers do not have details of the patient (past relevant history, blood type, insurance etc...) "
                          }
                        />
                      </VStack>
                      {/* <Box w={"70%"} position={"absolute"} top={"-80px"} >
                      <Vector content={"Who can help?"} />
                      </Box> */}
                    </Box>
                    <Box   w={"100%"}>
                      <Box margin={"auto"} w={"80%"}>
                        <MidCard
                          percentage={95}
                          content={
                            "People lack awareness of basic emergency support procedures "
                          }
                        />
                      </Box>
                      {/* <Box w={"70%"} position={"absolute"} top={"-80px"} >
                      <Vector content={"Who is he or she?"} />
                      </Box> */}
                    </Box>
                  </Box>
}             
                </Box>
              </Box>
            </Box>

          </Box>
        </Box>
        {/* sec-2 */}
        <Box className={styleMid.container2}>
          <Box id="section2">
            <Text className={styleMid.heading_text}>
            Precious time wasted, resulting in life/death severity impact.
            </Text>
          </Box>
          <Box className={styleMid.grid_Wrapper}>
            <Box className={styleMid.grid}>
              {preciousTimeDatas.map((el: any,i:number) => {
                return (
                  <Box display={"flex"} alignItems={"center"}  justifyContent={"center"} key={i+1}>
                    <FlexCard
                        title={el.title||""}
                      imageUrl={`Images/${el.imageUrl}`}
                      content={el.content}
                      key={i}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>

        {/* sec-3 */}
        <Box className={styleMid.container3}>
          <Box className={styleMid.background}>
            <Box id="section3" className={styleMid.heading_sec3} >
              <Text className={styleMid.heading_style}>what we do</Text>
              <Text className={styleMid.sub_head_style}>PocoBridge</Text>
            </Box>
            <Box className={styleMid.flex_section3}>
              <Box className={styleMid.poco_bridge_width}>
                <PocoBridge />
              </Box>
              <Box className={styleMid.poco_equ_width}>
                <Box>
                  <Text className={styleMid.sub_head_style}>
                    Fastest transfer to nearest properly equipped hospital with{" "}
                  </Text>
                </Box>
                <Flex direction={"column"} gap={"1rem"} mt="2.5rem">
                  <ListIteams
                    imageUrl={`Images/youtubeIcon.svg`}
                    content={
                      "Video treatment and monitoring by doctor from point of emergency till admission to ER"
                    }
                    imageWidth={"20%"}
                    contentWidth={"100%"}
                  />
                    <ListIteams
                      imageUrl={`Images/fileIcon.svg `}
                      content={
                        "Quick, appropriately equipped ambulance"
                      }
                      imageWidth={"20%"}
                      contentWidth={"100%"}
                    />
                  <ListIteams
                    imageUrl={`Images/fileIcon.svg`}
                    content={
                      "Medical history, Insurance info and video doc’s diagnosis sent before patient’s arrival"
                    }
                    imageWidth={"20%"}
                    contentWidth={"100%"}
                  />
                </Flex>
              </Box>
            </Box>
            <Box mb={"1rem"}>
              <Text className={styleMid.sub_head_style}>
                Our service has two components
              </Text>
            </Box>
            <Box className={styleMid.card_flex}>
              <Box className={styleMid.er_card_width}>
                <ErCard cardName="er" />
              </Box>
              <Box className={styleMid.em_card_width}>
                <ErmCard />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* sec-4 */}
        <Box className={styleMid.container4}>
          <Box id="section4" className={styleMid.heading_sec3}>
            <Text className={styleMid.heading_style}>Our Impact</Text>
            <Text className={styleMid.sub_head_style}>PocoBridge</Text>
          </Box>
          <Box className={styleMid.grid_Wrapper}>
            <Box className={styleMid.grid}>
              {impactDatas.map((el: any,i:number) => {
                return (
                  <Box display={"flex"} alignItems={"center"} justifyContent={"center"}  key={i+1}>
                    <FlexCard
                    title={el.title||""}
                      imageUrl={`Images/${el.imageUrl}`}
                      content={el.content}
                      key={i}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>

        {/* sec-5 */}
        {/* <Box id="section4" className={styleMid.container5}>
          <Box className={styleMid.heading_sec3}>
            <Box>
              <Text className={styleMid.heading_style}>client testimonial</Text>
              <Text className={styleMid.sub_head_style}>
                See what clients have said about us
              </Text>
            </Box>
          </Box>
          <Box className={styleMid.accordion_wrapper}>
            <Box w={"100%"} margin={"auto"}>
              <Accordions />
            </Box>
          </Box>
        </Box> */}
      </Box>
    </>
  );
};

export default MidSection;
