import React from 'react'
import insuranceStyle from "./ProfileInsurance.module.css";
import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { BenDataProps } from '../../../Helper/BenInterface';
const ProfileInsurance: React.FC<{ data: BenDataProps }> = ({ data }) => {
    const navigate = useNavigate();
    let SaSToken = localStorage.getItem("sasToken");
    const { insurancePolicies } = data;
    const handleAddInsuranceDetails = () => {
        navigate(`/addBenFullDetails/${data._id}/5`);
    };
    const handleEdit = () => {
        navigate(`/addBenFullDetails/${data._id}/5`);
    };
    return (
        <>
            <Box className={insuranceStyle.insurance_wrapper}>
                <Box className={insuranceStyle.insurance_container}>
                    <Box>
                        <Text className={insuranceStyle.subTitle}>Insurance Info</Text>
                    </Box>
                    {insurancePolicies.length < 1 ? (
                        <Box>
                            <Box
                                onClick={handleAddInsuranceDetails}
                                className={insuranceStyle.insuranceCard}
                            >
                                <AiOutlinePlus className={insuranceStyle.addInsuranceDet} />
                                <Text className={insuranceStyle.addInsuranceDet}>
                                    Add InsuranceInfo
                                </Text>
                            </Box>
                        </Box>
                    ) : (
                        <Grid
                            // mt={".575rem"}
                            className={insuranceStyle.gridSystem}
                            rowGap={".5rem"}
                        >
                            {insurancePolicies.map((item, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <GridItem
                                            key={item._id + i}
                                            className={
                                                i + 1 < insurancePolicies.length
                                                    ? insuranceStyle.withBorder_gray
                                                    : insuranceStyle.withoutBorder
                                            }
                                            mt={".575rem"}
                                        >
                                            <Flex direction={"column"}>
                                                <Text className={insuranceStyle.insurance_field_key}>
                                                    Policy ID
                                                </Text>
                                                <Text className={insuranceStyle.insurance_field_value}>
                                                    {item.policyId || "--"}
                                                </Text>
                                            </Flex>
                                        </GridItem>
                                        <GridItem
                                            key={item._id + "insurance" + i}
                                            className={
                                                i + 1 < insurancePolicies.length
                                                    ? insuranceStyle.withBorder_gray
                                                    : insuranceStyle.withoutBorder
                                            }
                                            mt={".575rem"}
                                        >
                                            <Flex direction={"column"}>
                                                <Text className={insuranceStyle.insurance_field_key}>
                                                    Name of the Insurance
                                                </Text>
                                                <Text className={insuranceStyle.insurance_field_value}>
                                                    {item.nameOfInsurer || "--"}
                                                </Text>
                                            </Flex>
                                        </GridItem>
                                        <GridItem
                                            key={item._id + "expiry" + i}
                                            className={
                                                i + 1 < insurancePolicies.length
                                                    ? insuranceStyle.withBorder_gray
                                                    : insuranceStyle.withoutBorder
                                            }
                                        ></GridItem>
                                        <GridItem
                                            key={item._id + "docs_url" + i}
                                            className={
                                                i + 1 < insurancePolicies.length
                                                    ? insuranceStyle.withBorder_gray
                                                    : insuranceStyle.withoutBorder
                                            }
                                            mt={".575rem"}
                                        >
                                            <Flex direction={"column"}>
                                                <Text className={insuranceStyle.insurance_field_key}>
                                                    Document
                                                </Text>
                                                <Box marginTop={"10px"} display={"flex"}>
                                                    {item.docUrl ? (
                                                        <a
                                                            style={{
                                                                padding: "7px",
                                                                background: "#e2e8f0",
                                                                borderRadius: "8px",
                                                                color: "blue",
                                                                fontWeight: "500",
                                                                width: "140px",
                                                            }}
                                                            href={item.docUrl + "?" + SaSToken}
                                                            target="blank"
                                                        >
                                                            Open Document
                                                        </a>
                                                    ) : (
                                                        "N/A"
                                                    )}
                                                </Box>
                                            </Flex>
                                        </GridItem>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    )}
                </Box>
                {insurancePolicies.length > 0 && (
                    <Box onClick={handleEdit} className={insuranceStyle.editIcon}>
                        <HiOutlinePencilSquare color={"#008470"} fontSize={"23px"} />
                    </Box>
                )}
            </Box>
        </>
    )
}

export default ProfileInsurance
