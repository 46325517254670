import { sendOtpReq } from "../../../../http";

export interface LoginOtp {
  countryCode: string;
  number: string;
}
  
export const sendOtp = async (payload:any) => {
    
  try {
    const { data } = await sendOtpReq(payload)
    sessionStorage.setItem('loginCredential',JSON.stringify(payload))
    sessionStorage.setItem('otpCredential', JSON.stringify(data))
    return data;
  } catch (error:any) {
    throw error;
  }
};


// import { addBeneficiaries } from "../../../http"

// export const createBeneficiaries=async(payload)=>{

//     const { data } = await addBeneficiaries(payload)
//     if(data.data.length){
//         return data.data
//     }else if(data.code===404){
//         alert("some Technical Issue")
//     }else{
//         alert("some Technical Issue")
//     }

// }