import React from 'react'
import { Box, Card, CardBody, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { AiOutlinePlus } from 'react-icons/ai';
import AddressStyles from "./ProfileAddressDetails.module.css"
import { BenDataProps } from '../../../Helper/BenInterface';
import { useNavigate } from 'react-router-dom';
import { HiOutlinePencilSquare } from 'react-icons/hi2';

const ProfileAddressDetails: React.FC<{ data: BenDataProps }> = ({ data }) => {
    const { address } = data;
    const navigate = useNavigate();
    console.log('data:', data)
    //******************************************************************************/
    ////* in feature i need to check the available address if it should have all field fully no issue if any filed is empty i need to mention is pending
    //added point for suppose if google api is not returning lat lon for particular address, that time also need to say pending////
    // without lat lon we cant find best matchAddress in EM platform
    ///if user address is not found in google api ???///////
    // const check = (address) => {};
    //******************************************************************************/
    const handleAddAddress = () => {
        // it navigate to add address page
        // it should contain benId and subsId
        // from data i get the basicInfo inside we got the benId,subsId
        navigate(`/addBenFullDetails/${data._id}/2`);
    };
    const handleEdit = () => {
        navigate(`/addBenFullDetails/${data._id}/2`);
    };
    return (
        <>
            <Box className={AddressStyles.address_Wrapper}>
                <Box className={AddressStyles.address_container}>
                    <Box>
                        <Text className={AddressStyles.subTitle}>Address</Text>
                    </Box>
                    <Box className={AddressStyles.address_card_container}>
                        {address.length < 1 ? (
                            <Box>
                                <Box
                                    onClick={handleAddAddress}
                                    className={AddressStyles.addressCard}
                                >
                                    <AiOutlinePlus className={AddressStyles.addAddress} />
                                    <Text className={AddressStyles.addAddress}>Add Address</Text>
                                </Box>
                            </Box>
                        ) : (
                            address.map((el, index) => {
                                return (
                                    <Box key={el._id}>
                                        <Card bg={"#F5F9F9"} position={"relative"}>
                                            <CardBody>
                                                <Flex align={"center"} gap={"6px"}>
                                                    <Box>
                                                        <Image src="/Images/home.svg" />
                                                    </Box>
                                                    {/* // yet now we don't have type of address but in feature we need to take care this
                                                  field in our backend */}
                                                    <Box>
                                                        <Text className={AddressStyles.typeAddress}>
                                                            {el.label || "Ex Home or Office"}
                                                        </Text>
                                                    </Box>
                                                </Flex>
                                                <Flex
                                                    direction={"column"}
                                                    marginTop={"10px"}
                                                    gap={"5px"}
                                                >
                                                    <Heading
                                                        as="h5"
                                                        size="sm"
                                                        className={
                                                            el.addressLine1
                                                                ? AddressStyles.addressDetails
                                                                : AddressStyles.typeAddress
                                                        }
                                                    >
                                                        {el.addressLine1 || ""}
                                                    </Heading>
                                                    <Text
                                                        className={
                                                            el.addressLine2
                                                                ? AddressStyles.addressDetails
                                                                : AddressStyles.typeAddress
                                                        }
                                                    >
                                                        {el.addressLine2 || ""}
                                                    </Text>
                                                    <Text
                                                        className={
                                                            el.landmark
                                                                ? AddressStyles.addressDetails
                                                                : AddressStyles.typeAddress
                                                        }
                                                    >
                                                        {el.landmark || ""}
                                                    </Text>
                                                    <Text
                                                        className={
                                                            el.city
                                                                ? AddressStyles.addressDetails
                                                                : AddressStyles.typeAddress
                                                        }
                                                    >
                                                        {el.city || ""} {el.pinCode || ""}
                                                    </Text>
                                                    <Text
                                                        fontWeight={"600"}
                                                        className={
                                                            el.isReachableByAmbulance
                                                                ? AddressStyles.addressDetails
                                                                : AddressStyles.typeAddress
                                                        }
                                                    >
                                                        {el.isReachableByAmbulance
                                                            ? `Ambulance Reachable: ${el.isReachableByAmbulance}`
                                                            : ""}
                                                    </Text>
                                                    <Text
                                                        fontWeight={"600"}
                                                        className={
                                                            el.isLiftAvailable
                                                                ? AddressStyles.addressDetails
                                                                : AddressStyles.typeAddress
                                                        }
                                                    >
                                                        {el.isLiftAvailable
                                                            ? `Lift Available: ${el.isLiftAvailable}`
                                                            : ""}
                                                    </Text>
                                                </Flex>
                                            </CardBody>
                                        </Card>
                                    </Box>
                                );
                            })
                        )}
                    </Box>
                </Box>
                {address.length > 0 && (
                    <Box onClick={handleEdit} className={AddressStyles.editIcon}>
                        <HiOutlinePencilSquare color={"#008470"} fontSize={"23px"} />
                    </Box>
                )}
            </Box>
        </>
    )
}

export default ProfileAddressDetails
