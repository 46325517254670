import { Box, Text } from "@chakra-ui/react";
import React from "react";
import style from "./ContactCard.module.css";
import { useNavigate } from "react-router-dom";
const ContactUsCard = () => {
    const navigate = useNavigate();
    return (
        <>
            <Box className={style.wrapper}>
                <Box className={style.contactUs_Card}>
                    <Box w={"100%"} display={"flex"} flexDirection={"column"} gap="1rem" >
                        <Text className={style.top_text}>need help?</Text>
                        <Text className={style.heading_text}>We are here for you</Text>
                        <Text  className={style.bottom_text}>
                        From Reactive to P <span style={{position:"relative"}}><span className={style.strike_word}>ro</span>  <span className={style.new_word}>oco</span></span> active
                       
                        </Text>
                   
                    </Box>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        w={"100%"}
                    >
                        <button
                            //   _hover={{ bg: "#ebedf0" }}
                            className={style.button}
                            onClick={() => navigate("/contact")}
                         
                        >
                            Contact us Now
                        </button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ContactUsCard;
