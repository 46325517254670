export const formateHealthIdInput = (input: string) => {
  input = input.toString();
  const formattedId =
    input.slice(0, 2) +
    "-" +
    input.slice(2, 6) +
    "-" +
    input.slice(6, 10) +
    "-" +
    input.slice(10);

  return formattedId;
};
