import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";

const GenerateMobileOtp = ({
  loading,
  handleChangeMobileOtp,
  mobileNo,
  setStatus,
  handleSubmitMobileOtp,
}: any) => {
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
    setStatus({
      termsConditionsStatus: false,
      generateMobileOtpStatus: false,
      VerifyMobileOtpStatus: false,
      selectAccountStatus: false,
    });
    window.location.reload();
  };
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <>
      {/* <Button
        bg="#008470"
        width={{base:"100%"}}
        color="white"
        _hover={{
          bg: "#008470",
        }}
        onClick={onOpen}
      >
        <div style={{ padding: "5px" }}>
        <MdIntegrationInstructions />
        </div>
        Link ABHA By Mobile
      </Button> */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "sm", xl: "md" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> ABHA Integration </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" alignItems="center">
              <Box width="60%" m={2} p={3}>
                <Image
                  boxSize="150px"
                  src="https://developmentbucket.blob.core.windows.net/logos/Ayushman_Bharat_logo.png"
                />
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Enter Mobile Number</FormLabel>
                  <Input
                    ref={initialRef}
                    placeholder="7999XXXXXX"
                    value={mobileNo}
                    type="text"
                    maxLength={10}
                    onChange={(e) => handleChangeMobileOtp(e.target.value)}
                  />
                </FormControl>
                <Button
                  mt={5}
                  onClick={handleSubmitMobileOtp}
                  bg="#008470"
                  color="white"
                  mr={3}
                  isLoading={loading}
                  loadingText="Sending"
                >
                  Generate OTP
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GenerateMobileOtp;
