import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Card, CardBody, CardFooter, Flex, HStack, Heading, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react'
import { ShowAlert } from '../../../../GlobalComponents/ShowAlert/ShowAlert';
import LeafLetMap from './Map';
import AddressStyle from "./Address.module.css";
import { addAddressAction, deleteAddressAction, getAllAddresses, getSingleBeneficiaryData, updateAddressAction } from '../../Actions/beneficiaryDetails.action';
import { MdOutlineEditLocationAlt } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { getGMapAddress } from '../../../../http';

interface AddressProps {
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
  changeStep: (step: number) => void;
}
export const NewAddress: FC<AddressProps> = ({ changeStep, data, setData }) => {
  const [allAddresses, setAllAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [addressLabel, setAddressLabel] = useState("");
  const [addressLabelCustom, setAddressLabelCustom] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [liftAvailable, setLiftAvailable] = useState("");
  const [ambulanceReachable, setAmbulanceReachable] = useState("");
  const [markerPos, setMarkerPos] = useState<[number, number] | null>(null);
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const [editIndex, setEditIndex] = useState();
  const [isOpenAddress, setIsOpenAddress] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const editInitialRef = React.useRef(null);
  const editFinalRef = React.useRef(null);

  const handleAddressLabelSelectChange = (e: any) => {
    setAddressLabel(e.target.value);
    if (e.target.value !== "others") {
      setAddressLabelCustom("");
    }
  }

  const editAddress = async () => {
    if (String(pincode).length !== 6) {
      return ShowAlert("warning", "Pincode must be 6 digits");
    }
    if (
      !address1 ||
      !address2 ||
      !ambulanceReachable ||
      !liftAvailable ||
      !city ||
      !pincode ||
      !state ||
      !addressLabel
    ) {
      return ShowAlert("warning", "All Fields are required");
    }
    let addressPayload = {
      _id: data.address[editIndex || 0]._id,
      addressLine1: address1,
      addressLine2: address2,
      landmark: landmark || "",
      isLiftAvailable: liftAvailable === "Yes" ? true : false,
      isReachableByAmbulance: ambulanceReachable === "Yes" ? true : false,
      nearestLocationWhereAmbulanceCanReach: {
        latitude: markerPos ? markerPos[0] : Number(data.address[editIndex || 0].nearestLocationWhereAmbulanceCanReach?.latitude) || 0,
        longitude: markerPos ? markerPos[1] : Number(data.address[editIndex || 0].nearestLocationWhereAmbulanceCanReach?.longitude) || 0
      },
      label: addressLabel !== "others" ? addressLabel : addressLabelCustom,
      city: city,
      state: state,
      country: "India",
      pinCode: Number(pincode) || 111111,
      preferredHospital: [
      ],
      location: [
        {
          latitude: markerPos ? markerPos[0] : Number(data?.address[editIndex || 0]?.location?.latitude) || 0,
          longitude: markerPos ? markerPos[1] : Number(data?.address[editIndex || 0]?.location?.longitude) || 0
        }
      ]
    };

    // console.log("addressPayload:", addressPayload)
    let newAddressState = [];
    for (let i = 0; i < data.address.length; i++) {
      if (i !== editIndex) {
        newAddressState.push(data.address[i]);
      } else {
        newAddressState.push(addressPayload);
      }
    }


    try {
      await updateAddressAction(data._id, addressPayload);
      let address = newAddressState;
      setData({ ...data, address });
      // clearing Data
      clearInputField();
      ShowAlert("success", "Address Updated Successfully");
      onEditClose();
    } catch (error: any) {
      console.log("error while editing address:", error);
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
      }
      ShowAlert("warning", "Something Bad Happened, Please try again later")
    }
  }

  const openEdit = (data: any, i: any) => {
    setAddress1(data.addressLine1);
    setAddress2(data.addressLine2);
    setCity(data.city);
    setLandmark(data.landmark || "");
    setState(data.state);
    setPincode(data.pinCode);
    setAddressLabel(
      data.label === "Work" || data.label === "Home" ? data.label : "others"
    );
    setAmbulanceReachable(data.isReachableByAmbulance === true ? "Yes" : "No");
    setLiftAvailable(data.isLiftAvailable === true ? "Yes" : "No");
    if (data?.location?.length > 0) {
      const latitude = data?.location[0]?.latitude || 0;
      const longitude = data?.location[0]?.longitude || 0;
      setMarkerPos([latitude, longitude])
    } else {
      setMarkerPos([0, 0])
    }
    setAddressLabelCustom(data.label);
    setEditIndex(i);
    onEditOpen();
  };

  const openDelete = (el: any, i: any) => {
    setDeleteIndex(i);
    onDeleteOpen();
  };

  const deleteAddress = async () => {
    let newAddressState = [];
    let addressToBeDeleted = data.address[deleteIndex || 0];
    for (let i = 0; i < data.address.length; i++) {
      if (i !== deleteIndex) {
        newAddressState.push(data.address[i]);
      }
    }

    let address = newAddressState;

    try {
      await deleteAddressAction(data._id, addressToBeDeleted._id);
      setData({ ...data, address });
      ShowAlert("success", "Address Deleted Successfully");
    } catch (error: any) {
      console.log("error while deleting address:", error);
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
      }
      ShowAlert("warning", "Error while deleting address");
    }

    onEditClose();
    onDeleteClose();
  };

  const handleCloseModal = () => {
    clearInputField()
    setIsOpenAddress(false);
  };

  const saveAddress = async () => {
    if (pincode.length !== 6) {
      return ShowAlert("warning", "Pincode must be 6 digits");
    }
    if (!address1 || !address2 || !pincode || !city || !addressLabel || !state) {
      return ShowAlert("warning", "All fields are required");
    }
    let addressPayload = {
      addressLine1: address1,
      addressLine2: address2,
      landmark: landmark || "",
      isLiftAvailable: liftAvailable === "Yes" ? true : false,
      isReachableByAmbulance: ambulanceReachable === "Yes" ? true : false,
      nearestLocationWhereAmbulanceCanReach: {
        latitude: markerPos ? markerPos[0] : 0,
        longitude: markerPos ? markerPos[1] : 0
      },
      label: addressLabel !== "others" ? addressLabel : addressLabelCustom,
      city: city,
      state: state,
      country: "India",
      pinCode: Number(pincode) || 111111,
      preferredHospital: [
      ],
      location: [
        {
          latitude: markerPos ? markerPos[0] : 0,
          longitude: markerPos ? markerPos[1] : 0
        }]
    }

    try {
      await addAddressAction(data._id, addressPayload);
      let updatedData = await getSingleBeneficiaryData(data._id);
      setData(updatedData);
      clearInputField();
      ShowAlert("success", "Address Added Successfully");
      handleCloseModal();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
      }
      ShowAlert("warning", "Something Bad Happened, Please try again later")
    }

  }
  const handleOpenModal = () => {
    setIsOpenAddress(true);
    setAddressLabel("");
    setAddressLabelCustom("");
  };

  const clearInputField = () => {
    setAddress1("");
    setAddress2("");
    setCity("");
    setLandmark("");
    setState("");
    setPincode("");
    setAmbulanceReachable("");
    setLiftAvailable("");
    setAddressLabelCustom("");
    setAddressLabel("");
    setMarkerPos(null);
    return;
  }

  const getCoordinates = async () => {
    if (String(pincode).length !== 6) {
      return ShowAlert("warning", "Pincode must be 6 digits");
    }
    if (!addressLabel || !address2 || !pincode || !city || !state || !address1) {
      return ShowAlert("warning", "All fields are required");
    }
    try {
      const response = await getGMapAddress(address1, address2, city, state, pincode);
      let data = response.data
      console.log(data, "++++++++++++++++++++")
      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setMarkerPos([lat, lng]);
      } else {
        throw new Error('No results found');
      }
    } catch (error: any) {
      console.error(error.message);
      ShowAlert("warning", "Failed to load map");
    }
  };

  const handleCancel = () => {
    clearInputField()
    onEditClose();
  }

  const getAllBenAddresses = async (subId: string) => {
    let data = await getAllAddresses(subId);
    setAllAddresses(data);
  }

  const saveSelectedAddress = async () => {
    if (!selectedAddress) {
      return ShowAlert("warning", "Please select an address");
    }

    let addressPayload = {
      addressLine1: selectedAddress.addressLine1,
      addressLine2: selectedAddress.addressLine2,
      landmark: selectedAddress?.landmark || "",
      isLiftAvailable: selectedAddress.isLiftAvailable === "Yes" ? true : false,
      isReachableByAmbulance: selectedAddress.isReachableByAmbulance === "Yes" ? true : false,
      nearestLocationWhereAmbulanceCanReach: selectedAddress?.nearestLocationWhereAmbulanceCanReach || {
        latitude: 0,
        longitude: 0
      },
      label: selectedAddress?.label || "Home",
      city: selectedAddress?.city,
      state: selectedAddress?.state,
      country: "India",
      pinCode: selectedAddress?.pinCode || 111111,
      preferredHospital: selectedAddress?.preferredHospital || [],
      location: selectedAddress?.location || [
        { latitude: 0, longitude: 0 }
      ]
    }

    try {
      await addAddressAction(data._id, addressPayload);
      let updatedData = await getSingleBeneficiaryData(data._id);
      setData(updatedData);
      clearInputField();
      ShowAlert("success", "Address Added Successfully");
      handleCloseModal();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
      }
      ShowAlert("warning", "Something Bad Happened, Please try again later")
    }

  }

  useEffect(() => {
    getAllBenAddresses(data.subscriberId);
  }, []) // eslint-disable-line

  return (
    <>
      {
        data?.address?.length ? (
          <>
            <div className={AddressStyle.addressCardContainer}>
              {data.address.map((el: any, i: any) => (
                <React.Fragment key={el._id}>
                  <Card
                    width={"90%"}
                    marginBottom={"10px"}
                    key={i}
                    bg={"#F5F9F9"}
                  >
                    <CardBody>
                      <Flex align={"center"} gap={"6px"}>
                        <Box>
                          <Image src="/Images/home.svg" />
                        </Box>
                        <Box>
                          <Text>{el.label || "No Label"}</Text>
                        </Box>
                      </Flex>
                      <Text>
                        {el?.addressLine1 + ","}
                      </Text>
                      <Text>
                        {el?.addressLine2 + "," + el?.landmark + ","}
                      </Text>
                      <Text>
                        {el?.city + "," + el?.state + "," + el?.pinCode}
                      </Text>
                      <Text fontWeight={"500"}>
                        {el.isReachableByAmbulance === true
                          ? "Ambulance Reachable: Yes"
                          : "Ambulance Reachable: No"}
                      </Text>
                      <Text fontWeight={"500"}>
                        {el.isLiftAvailable === true
                          ? "Lift Available: Yes"
                          : "Lift Available: No"}
                      </Text>
                    </CardBody>
                    <CardFooter
                      marginTop={"-1.5rem"}
                      justify="space-between"
                      flexWrap="wrap"
                    >
                      <MdOutlineEditLocationAlt
                        className={AddressStyle.greenIcon}
                        onClick={() => openEdit(el, i)}
                      />
                      <RiDeleteBinLine
                        className={AddressStyle.redIcon}
                        onClick={() => openDelete(el, i)}
                      />
                    </CardFooter>
                  </Card>
                </React.Fragment>
              ))}
            </div>
            <HStack marginTop={"30px"} justifyContent={"space-between"}>
              <GrFormPrevious
                className={AddressStyle.redIcon}
                onClick={() => changeStep(-1)}
              />
              <Button
                isDisabled={data.address.length > 2 ? true : false}
                onClick={handleOpenModal}
                colorScheme="blue"
                variant={"solid"}
              >
                Add Another Address
              </Button>
              <GrFormNext
                onClick={() => changeStep(+1)}
                className={AddressStyle.greenIcon}
              />
            </HStack>
            <Modal
              initialFocusRef={initialRef}
              finalFocusRef={finalRef}
              isOpen={isOpenAddress}
              onClose={handleCloseModal}
              size={"xl"}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add Address</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                          <Heading as='h4' size='md'>Select From Existing Addresses</Heading>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        {/* @ts-ignore */}
                        <Box gap={"10px"} display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"}>
                          {
                            allAddresses.map((address: any, index: number) =>
                              <Card
                                marginBottom={"10px"}
                                key={index}
                                bg={address === selectedAddress ? "lightblue" : "white"}
                                style={{
                                  cursor: "pointer",
                                  width: "300px"
                                }}
                                onClick={() => { setSelectedAddress(address); console.log("address", address) }}
                              >
                                <CardBody>
                                  <Flex align={"center"} gap={"6px"}>
                                    <Box>
                                      <Image src="/Images/home.svg" />
                                    </Box>
                                    <Box>
                                      <Text>{address.label || "No Label"}</Text>
                                    </Box>
                                  </Flex>
                                  <Text>
                                    {address?.addressLine1 + ","}
                                  </Text>
                                  <Text>
                                    {address?.addressLine2 + "," + address?.landmark + ","}
                                  </Text>
                                  <Text>
                                    {address?.city + "," + address?.state + "," + address?.pinCode}
                                  </Text>
                                  <Text fontWeight={"500"}>
                                    {address.isReachableByAmbulance === true
                                      ? "Ambulance Reachable: Yes"
                                      : "Ambulance Reachable: No"}
                                  </Text>
                                  <Text fontWeight={"500"}>
                                    {address.isLiftAvailable === true
                                      ? "Lift Available: Yes"
                                      : "Lift Available: No"}
                                  </Text>
                                </CardBody>
                              </Card>
                            )
                          }
                        </Box>
                        <Box textAlign={"center"} mt={"20px"}>
                          <Button bgColor='#38a169' color={"white"} onClick={saveSelectedAddress}>Save Address</Button>
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                          <Heading as='h4' size='md'>Add New</Heading>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>

                        <Select
                          value={addressLabel}
                          onChange={(e) => handleAddressLabelSelectChange(e)}
                          marginBottom={"10px"}
                          size={"md"}
                          placeholder="Address Label"
                        >
                          <option value="Home">Home</option>
                          <option value="Work">Work</option>
                          <option value="others">Others</option>
                        </Select>

                        {addressLabel === "others" ? (
                          <Input
                            value={addressLabelCustom}
                            onChange={(e) => setAddressLabelCustom(e.target.value)}
                            marginBottom={"10px"}
                            placeholder="Enter Label"
                            size="md"
                          />
                        ) : null}

                        <Input
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          marginBottom={"10px"}
                          placeholder='Flat No. / House No. / Building / Company / Apartment'
                          size="md"
                        />
                        <Input
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                          marginBottom={"10px"}
                          placeholder='Area, Street, Sector, Village'
                          size="md"
                        />
                        <Input
                          value={landmark}
                          onChange={(e) => setLandmark(e.target.value)}
                          placeholder='Landmark'
                          size="md"
                          mb={"10px"}
                        />
                        <Flex
                          direction={{ base: 'column', md: 'row' }}
                          justify="space-between"
                          align={{ base: 'stretch', md: 'center' }}
                          wrap="wrap"
                          // @ts-ignore
                          gap={"10px"}
                        >
                          <Box flex="1">
                            <Input
                              value={pincode}
                              onChange={(e) => setPincode(e.target.value)}
                              placeholder="Pincode"
                              size="md"
                              style={{ width: '100%' }}
                              type="number"
                            />
                          </Box>
                          <Box flex="1">
                            <Input
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              placeholder="Town / City"
                              size="md"
                              style={{ width: '100%' }}
                            />
                          </Box>
                          <Box flex="1">
                            <Input
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              placeholder="State"
                              size="md"
                              style={{ width: '100%' }}
                            />
                          </Box>
                        </Flex>

                        <Flex
                          direction={{ base: 'column', md: 'row' }}
                          justify="space-between"
                          align={{ base: 'stretch', md: 'center' }}
                          wrap="wrap"
                          // @ts-ignore
                          gap={"10px"}
                          mt={"10px"}
                        >
                          <Box flex={"1"}>
                            <Select
                              value={ambulanceReachable}
                              onChange={(e) => setAmbulanceReachable(e.target.value)}
                              marginBottom={"10px"}
                              size={"md"}
                              placeholder="Is Ambulance Reachable?"
                            >
                              <option value="Yes">Yes, ambulance reachable</option>
                              <option value="No">No, ambulance not reachable</option>
                            </Select>
                          </Box>

                          <Box flex={"1"}>
                            <Select
                              value={liftAvailable}
                              onChange={(e) => setLiftAvailable(e.target.value)}
                              marginBottom={"10px"}
                              size={"md"}
                              placeholder="Is Lift Available?"
                            >
                              <option value="Yes">Yes, lift available</option>
                              <option value="No">No, lift is not available</option>
                            </Select>
                          </Box>
                        </Flex>

                        {
                          markerPos && markerPos.length > 0 ? <>
                            <Box mt={"20px"} textAlign={"center"} >
                              <Button mr={"10"} onClick={getCoordinates} colorScheme='green'>Locate On Map</Button>
                              <Button onClick={saveAddress} colorScheme='green'>Save Address</Button>
                            </Box>
                          </> : <Box mt={"20px"} textAlign={"center"} >
                            <Button onClick={getCoordinates} bgColor='#38a169' color={"white"}>Locate On Map</Button>
                          </Box>
                        }

                        {
                          markerPos && markerPos.length > 0 ? <> <Box mt={"20px"}>
                            <LeafLetMap markerPos={markerPos} setMarkerPos={setMarkerPos} />
                          </Box>
                          </> : null
                        }
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </ModalBody>

                <ModalFooter>
                  {markerPos && markerPos.length > 0 ? <Button onClick={saveAddress} colorScheme="blue" mr={3}>
                    Save
                  </Button> : null}
                  <Button onClick={handleCloseModal}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
          /////
        ) :
          <>
            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    <Heading as='h4' size='md'>Select From Existing Addresses</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {/* @ts-ignore */}
                  <Box gap={"10px"} display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"}>
                    {
                      allAddresses.map((address: any, index: number) =>
                        <Card
                          marginBottom={"10px"}
                          key={index}
                          bg={address === selectedAddress ? "lightblue" : "white"}
                          style={{
                            cursor: "pointer",
                            width: "400px",
                          }}
                          onClick={() => { setSelectedAddress(address); console.log("address", address) }}
                        >
                          <CardBody>
                            <HStack align={"center"} gap={"6px"}>
                              <Box>
                                <Image src="/Images/home.svg" />
                              </Box>
                              <Box>
                                <Text>{address.label || "No Label"}</Text>
                              </Box>
                            </HStack>
                            <Text>
                              {address?.addressLine1 + ","}
                            </Text>
                            <Text>
                              {address?.addressLine2 + "," + address?.landmark + ","}
                            </Text>
                            <Text>
                              {address?.city + "," + address?.state + "," + address?.pinCode}
                            </Text>
                            <Text fontWeight={"500"}>
                              {address.isReachableByAmbulance === true
                                ? "Ambulance Reachable: Yes"
                                : "Ambulance Reachable: No"}
                            </Text>
                            <Text fontWeight={"500"}>
                              {address.isLiftAvailable === true
                                ? "Lift Available: Yes"
                                : "Lift Available: No"}
                            </Text>
                          </CardBody>
                        </Card>
                      )
                    }
                  </Box>
                  <Box textAlign={"center"} mt={"20px"}>
                    <Button colorScheme='whatsapp' onClick={saveSelectedAddress}>Save Address</Button>
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    <Heading as='h4' size='md'>Add New</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>

                  <Select
                    value={addressLabel}
                    onChange={(e) => handleAddressLabelSelectChange(e)}
                    marginBottom={"10px"}
                    size={"md"}
                    placeholder="Address Label"
                  >
                    <option value="Home">Home</option>
                    <option value="Work">Work</option>
                    <option value="others">Others</option>
                  </Select>

                  {addressLabel === "others" ? (
                    <Input
                      value={addressLabelCustom}
                      onChange={(e) => setAddressLabelCustom(e.target.value)}
                      marginBottom={"10px"}
                      placeholder="Enter Label"
                      size="md"
                    />
                  ) : null}

                  <Input
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    marginBottom={"10px"}
                    placeholder='Flat No. / House No. / Building / Company / Apartment'
                    size="md"
                  />
                  <Input
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                    marginBottom={"10px"}
                    placeholder='Area, Street, Sector, Village'
                    size="md"
                  />
                  <Input
                    value={landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                    placeholder='Landmark'
                    size="md"
                    mb={"10px"}
                  />
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    justify="space-between"
                    align={{ base: 'stretch', md: 'center' }}
                    wrap="wrap"
                    // @ts-ignore
                    gap={"10px"}
                  >
                    <Box flex="1">
                      <Input
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        placeholder="Pincode"
                        size="md"
                        style={{ width: '100%' }}
                        type="number"
                      />
                    </Box>
                    <Box flex="1">
                      <Input
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Town / City"
                        size="md"
                        style={{ width: '100%' }}
                      />
                    </Box>
                    <Box flex="1">
                      <Input
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        placeholder="State"
                        size="md"
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Flex>

                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    justify="space-between"
                    align={{ base: 'stretch', md: 'center' }}
                    wrap="wrap"
                    // @ts-ignore
                    gap={"10px"}
                    mt={"10px"}
                  >
                    <Box flex={"1"}>
                      <Select
                        value={ambulanceReachable}
                        onChange={(e) => setAmbulanceReachable(e.target.value)}
                        marginBottom={"10px"}
                        size={"md"}
                        placeholder="Is Ambulance Reachable?"
                      >
                        <option value="Yes">Yes, ambulance reachable</option>
                        <option value="No">No, ambulance not reachable</option>
                      </Select>
                    </Box>

                    <Box flex={"1"}>
                      <Select
                        value={liftAvailable}
                        onChange={(e) => setLiftAvailable(e.target.value)}
                        marginBottom={"10px"}
                        size={"md"}
                        placeholder="Is Lift Available?"
                      >
                        <option value="Yes">Yes, lift available</option>
                        <option value="No">No, lift is not available</option>
                      </Select>
                    </Box>
                  </Flex>

                  {
                    markerPos && markerPos.length > 0 ? <>
                      <Box mt={"20px"} textAlign={"center"} >
                        <Button mr={"10"} onClick={getCoordinates} colorScheme='green'>Locate On Map</Button>
                        <Button onClick={saveAddress} colorScheme='green'>Save Address</Button>
                      </Box>
                    </> : <Box mt={"20px"} textAlign={"center"} >
                      <Button onClick={getCoordinates} colorScheme='green'>Locate On Map</Button>
                    </Box>
                  }

                  {
                    markerPos && markerPos.length > 0 ? <> <Box mt={"20px"}>
                      <LeafLetMap markerPos={markerPos} setMarkerPos={setMarkerPos} />
                    </Box>
                    </> : null
                  }
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
      }

      {/* deleteAlert */}
      <AlertDialog
        isOpen={isDeleteOpen}
        //  @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Address
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              {/* @ts-ignore */}
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteAddress} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Edit Modal */}
      <Modal
        initialFocusRef={editInitialRef}
        finalFocusRef={editFinalRef}
        isOpen={isEditOpen}
        onClose={handleCancel}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Address</ModalHeader>
          <ModalCloseButton onClick={handleCancel} />
          <ModalBody pb={6}>
            <Select
              value={addressLabel}
              onChange={(e) => handleAddressLabelSelectChange(e)}
              marginBottom={"10px"}
              size={"md"}
              placeholder="Address Label"
            >
              <option value="Home">Home</option>
              <option value="Work">Work</option>
              <option value="others">Others</option>
            </Select>

            {addressLabel === "others" ? (
              <Input
                value={addressLabelCustom}
                onChange={(e) => setAddressLabelCustom(e.target.value)}
                marginBottom={"10px"}
                placeholder="Enter Label"
                size="md"
              />
            ) : null}

            <Input
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              marginBottom={"10px"}
              placeholder='Flat No. / House No. / Building / Company / Apartment'
              size="md"
            />
            <Input
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              marginBottom={"10px"}
              placeholder='Area, Street, Sector, Village'
              size="md"
            />
            <Input
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              placeholder='Landmark'
              size="md"
              mb={"10px"}
            />
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justify="space-between"
              align={{ base: 'stretch', md: 'center' }}
              wrap="wrap"
              // @ts-ignore
              gap={"10px"}
            >
              <Box flex="1">
                <Input
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  placeholder="Pincode"
                  size="md"
                  style={{ width: '100%' }}
                  type="number"
                />
              </Box>
              <Box flex="1">
                <Input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Town / City"
                  size="md"
                  style={{ width: '100%' }}
                />
              </Box>
              <Box flex="1">
                <Input
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  placeholder="State"
                  size="md"
                  style={{ width: '100%' }}
                />
              </Box>
            </Flex>

            <Flex
              direction={{ base: 'column', md: 'row' }}
              justify="space-between"
              align={{ base: 'stretch', md: 'center' }}
              wrap="wrap"
              // @ts-ignore
              gap={"10px"}
              mt={"10px"}
            >
              <Box flex={"1"}>
                <Select
                  value={ambulanceReachable}
                  onChange={(e) => setAmbulanceReachable(e.target.value)}
                  marginBottom={"10px"}
                  size={"md"}
                  placeholder="Is Ambulance Reachable?"
                >
                  <option value="Yes">Yes, ambulance reachable</option>
                  <option value="No">No, ambulance not reachable</option>
                </Select>
              </Box>

              <Box flex={"1"}>
                <Select
                  value={liftAvailable}
                  onChange={(e) => setLiftAvailable(e.target.value)}
                  marginBottom={"10px"}
                  size={"md"}
                  placeholder="Is Lift Available?"
                >
                  <option value="Yes">Yes, lift available</option>
                  <option value="No">No, lift is not available</option>
                </Select>
              </Box>
            </Flex>

            {
              <Box mt={"20px"} textAlign={"center"}>
                <Button mr={"10"} onClick={getCoordinates} colorScheme='red'>Locate On Map</Button>
                {
                  markerPos && markerPos.length > 0 ? <Button onClick={editAddress} bgColor='#38a169' color={"white"}>Save Changes</Button> : null
                }
              </Box>
            }

            {
              markerPos && markerPos.length > 0 ? <> <Box mt={"20px"}>
                <LeafLetMap markerPos={markerPos} setMarkerPos={setMarkerPos} />
              </Box>
              </> : null
            }
          </ModalBody>
          <ModalFooter>
            <Button onClick={editAddress} bgColor="#38a169" color={"white"} mr={3}>
              Save Changes
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
