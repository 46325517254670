import { ShowAlert } from "../../GlobalComponents/ShowAlert/ShowAlert"
import { activateTempBen, sendOtpForActivation, verifyOtpForActivation } from "../../http"

export const sendOtpForActivateUser = async (payload: any) => {
    try {
        if (!payload.mobile) {
            ShowAlert("warning", "Mobile Number is required")
            throw new Error()
        }
        await sendOtpForActivation(payload)
    } catch (error: any) {
        if (error?.response?.data?.message) {
            if (Array.isArray(error.response.data.message)) {
                ShowAlert("warning", error.response.data.message[0]);
                throw new Error(error)
            } else {
                ShowAlert("warning", error.response.data.message);
                throw new Error(error)
            }
        }

        ShowAlert("warning", "Something Went Wrong");
        throw new Error(error)
    }
}

export const verifyOtpForActivationAction = async (payload: any) => {
    try {
        if (!payload.mobile) {
            ShowAlert("warning", "Mobile Number is required")
            throw new Error()
        }
        if (!payload.otp) {
            ShowAlert("warning", "OTP  is required")
            throw new Error()
        }
        let otpResponse = await verifyOtpForActivation(payload);
        return otpResponse.data;
    } catch (error: any) {
        if (error?.response?.data?.message) {
            if (Array.isArray(error.response.data.message)) {
                ShowAlert("warning", error.response.data.message[0]);
                throw error
            } else {
                ShowAlert("warning", error.response.data.message);
                throw error
            }
        }
        ShowAlert("warning", "Something Went Wrong")
        throw error
    }
}

export const activateTempBenAction = async (payload: any) => {
    try {
        let response = await activateTempBen(payload);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message) {
            if (Array.isArray(error.response.data.message)) {
                ShowAlert("warning", error.response.data.message[0]);
                throw new Error()
            } else {
                ShowAlert("warning", error.response.data.message);
                throw new Error()
            }
        }
        ShowAlert("warning", "Something Went Wrong")
        throw new Error()
    }
}