import {
  Box,
  Button,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { handleConfirmAbhaLoginOtpAction } from "../../../Actions/beneficiaryDetails.action";
import { ShowAlert } from "../../../../../GlobalComponents/ShowAlert/ShowAlert";

const AbhaLogin = ({ abhaDetails, sessionStatus, setSessionStatus }: any) => {
  const modalSize = useBreakpointValue({ base: "md", md: "lg", lg: "xl" });

  const [isOpen, setIsOpen] = useState(sessionStatus);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSubmitAbhaLogin = async (otp: any) => {
    try {
      setLoading(true);
      if (/^\d{6}$/.test(otp)) {
        const prepareData = {
          abhaId: abhaDetails.abhaAddress,
          credential: {
            authCode: otp,
          },
        };
        await handleConfirmAbhaLoginOtpAction(prepareData);
        setLoading(false);
        setSessionStatus(false);
        ShowAlert("success", "ABHA Log in successfull");
      } else {
        setLoading(false);
        ShowAlert("warning", "OTP must be exactly 6 digits");
      }
    } catch (error) {
      console.log(error, "error in validating the abha otp....");
      setLoading(false);
      setSessionStatus(false);
      return ShowAlert("warning", "Error in validating the OTP from ABHA.");
    }
  };

  const onClose = () => {
    setIsOpen(false);
    window.location.reload(); // Reload the current page
  };

  return (
    <Box p={4} overflow="hidden">
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={modalSize}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton mt="2px" right={"40px"} top={"8"} />
          <ModalBody margin={"auto"} mt="25px" mb="25px">
            <Box>
              <Box
                border={"1px solid #C7C4C4"}
                height={"430px"}
                width={"500px"}
                justifyContent={"center"}
              >
                <Box
                  mt={"30px"}
                  display={"flex"}
                  flexDir={"column"}
                  justifyContent={"center"}
                  textAlign={"center"}
                  alignContent={"center"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Image
                      width={"60.332px"}
                      src="https://developmentbucket.blob.core.windows.net/logos/abhaLogo.png"
                      alt="AbhaLogo"
                    />
                  </Box>
                  <Box marginTop={"12px"}>
                    <Heading size="xs" fontSize="20px">
                      Session Expired
                    </Heading>
                  </Box>
                  <Box
                    margin={"auto"}
                    marginTop={"14px"}
                    width={"372.493px"}
                    height={"64.93px"}
                  >
                    {" "}
                    <Text
                      sx={{
                        color: "#919598",
                        textAlign: "center",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "24px", // 171.429%
                        textTransform: "camelCase",
                      }}
                    >
                      Please enter One time password( OTP) sent to your
                      registered mobile linked with ABHA-Id
                    </Text>
                  </Box>
                </Box>
                <Box
                  margin={"auto"}
                  mt="26px"
                  textAlign={"center"}
                  borderRadius={"8px"}
                  //   border={"1px solid #C7C4C4"}
                >
                  <Box position={"relative"}>
                    <Text
                      position={"absolute"}
                      bg="white"
                      top={"-3"}
                      zIndex={10}
                      left={"65px"}
                      width={"40px"}
                    >
                      OTP
                    </Text>
                    <Input
                      width={"398.281px"}
                      height={"51.213px"}
                      variant="outline"
                      placeholder="* * * *"
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box margin={"auto"} mt="25px" textAlign={"center"}>
                  <Button
                    height={"51.213px"}
                    width={"398.281px"}
                    color={"white"}
                    isLoading={loading}
                    loadingText={"Logging in progress..."}
                    borderRadius="8px"
                    background="#00A7B5"
                    onClick={() => {
                      handleSubmitAbhaLogin(otp);
                    }}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AbhaLogin;
