import { updateBenAddressById, updateUserMobileNumber } from "../../../http"

export const updateAddress = async (benId, addressPayload) => {
    try {
        console.log('benId:', benId)
        const { data } = await updateBenAddressById(benId, addressPayload)
        console.log('data:', data)
        if (data.code === 200) {
            return data;
        }
    } catch (e) {
        throw e
    }


}

export const updateUserMobileNo = async (userId,payload) => {
    try{
        const { data } =await updateUserMobileNumber(userId,payload)
        return data
    }catch(e){
        throw e
    }
}