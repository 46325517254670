import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const PrivacypolicyText = () => {
  const [externalHTML, setExternalHTML] = useState("");

  useEffect(() => {
    fetch("/PrivacyPolicy_LandingPage.html")
      .then((response) => response.text())
      .then((data) => setExternalHTML(data))
      .catch((error) => console.error("Error fetching external HTML:", error));
  }, []);
  return (
    <div>
      <Box>
        <Box dangerouslySetInnerHTML={{ __html: externalHTML }} />
      </Box>
    </div>
  );
};

export default PrivacypolicyText;
