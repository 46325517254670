export const isValidEmail = (email:string) => {
    // Regular expression to validate email format
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/;
    return emailRegex.test(email);
};

export const isValidDomain = (email:string,emailPattern:string) => {
    //Check if the email domain is apple.com
    if(emailPattern===""){
        return true
    }else{
        const domain = email.split('@')[1];
        return domain === emailPattern;

    }
};