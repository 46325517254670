import { Box, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";
import style from "./Aboutus.module.css";
import Navbar from "../../GlobalComponents/Navbar/Navbar";
import Footer from "../../GlobalComponents/Footer/Footer";
import { Paragraph } from "./Paragraph";
// import { Paragraph } from "../../Components/Paragraph/Paragraph";
// import Footer from "../../Components/Footer/Footer";
// import Navbar from "../../Components/Navbar/Navbar";

const data = [
  {
    title: "Mission",
    content:
      "Approximately 90% of people in India do not get medical attention on time. Our mission is to reduce this to at least 50% in the next 3 years.",
  },
  {
    title: "What do we do",
    content:
      "We help people become emergency ready so that they are able to swiftly respond to emergencies. In case of an emergency we ensure that medical help is available in the shortest possible time. Our tech-based solution ensures that an ambulance is available at the patient’s location at the earliest, emergency contacts are informed automatically and a doctor is available to give the necessary guidance till the patient reaches the hospital.",
  },
  {
    title: "Where We Operate",
    content:
      "We are headquartered in Bangalore and have service coverage in over 800 towns and cities across India..",
  },
];

const AboutUs = () => {
  return (
    <>
      <Navbar/>
      <Box className={style.wrapper}>
        <Box className={style.container}>
          <Heading className={style.primary_Color} as="h1" size="xl">
            About Us
          </Heading>
          <Text className={style.text_Style}>
            Pococare (short form of Care with Positive Conversations) is a
            technology-driven healthcare organization that’s on a mission to
            redefine medical emergency response in India.
          </Text>
          {data.map((el, i) => {
            return (
              <Box key={i}>
                <Paragraph title={el.title} content={el.content} />
              </Box>
            );
          })}
          <Text fontFamily={"Poppins"} mt={"1rem"} fontSize="1.6rem" fontWeight={"600"}>
            Why did we start Pococare?
          </Text>
          <Text className={style.text_Style}>
            Having worked several years in the medical emergency space working
            with hospitals, ambulance providers, doctors and over half a million
            patients, we learnt that:
          </Text>
          <Box ml={"1rem"}>
            <UnorderedList>
              <ListItem className={style.text_Style_li}>
                The emergency infrastructure in India is equivalent to what the
                US had in the 1950s.
              </ListItem>
              <ListItem className={style.text_Style_li}>
                Medical emergencies need smooth coordination between the
                patient, doctor, emergency contacts, treating hospital and
                ambulance providers
              </ListItem>
              <ListItem className={style.text_Style_li}>
                Ground realities such as unavailability of medical records,
                ambulance accessibility, lack of a lift, invalid insurance,
                incorrect hospital add to the complexity of handling medical
                emergencies.
              </ListItem>
              <ListItem className={style.text_Style_li}>
                A reactive approach often leads to poor decisions, that in turn
                lead to loss of life, lifelong disabilities, high medical
                expenses and emotional trauma to patients and their loved ones.
              </ListItem>
            </UnorderedList>
            <Text className={style.text_Style}>
              We believe that the only way to effectively address a medical
              emergency is to have an integrated solution that co-ordinates
              across all stakeholders and acts as a single point of contact for
              all involved.
            </Text>
          </Box>
        </Box>
      </Box>
      <Box marginTop={"5rem"}>
        <Footer  />
      </Box>
    </>
  );
};

export default AboutUs;