import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import style from "./ProfileProgress.module.css";
interface ProfileProgressProps{
    percentage:number,
    strokeWidth:number,
    circleSize:string
}
const ProfileProgress: React.FC<ProfileProgressProps> = ({ percentage, strokeWidth, circleSize }) => {
    const [color, setColor] = useState("red");
    const percentageText = `${percentage}%`;
    const changeColor = () => {
        const check_Percentage = percentageText.split("%")[0];
        if (Number(check_Percentage) > 70) {
            setColor("green");
        } else if (Number(check_Percentage) >= 50) {
            setColor("yellow");
        }
    };
    useEffect(() => {
        changeColor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percentage]);

    const styles = buildStyles({
        strokeLinecap: "round",
        textColor: "#000",
        pathColor: `${color}`,
        trailColor: "#f2f2f2",
        textSize: `${strokeWidth / 2}px`,
    });
    return (
        <div>
            <div style={{ position: "relative", width: circleSize }}>
                <CircularProgressbar
                    value={percentage}
                    strokeWidth={strokeWidth}
                    styles={styles}
                />
                <div
                    style={{
                        backgroundColor: color,
                        color: color === "yellow" ? "black" : "white",
                    }}
                    className={style.circleProgress}
                >
                    <span>{percentageText}</span>
                </div>
            </div>
        </div>
    );
};

export default ProfileProgress;
