import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  HStack,
  Heading,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import getLatLong from "../../../../GlobalActions/getGeoCode";
import {
  getDistanceFromLatLonInKm,
  getNearByHospitals,
} from "../../Actions/beneficiaryDetails.action";
import { ShowAlert } from "../../../../GlobalComponents/ShowAlert/ShowAlert";
import { updateBeneficiaryAddress } from "../../../../http";
import { useNavigate } from "react-router-dom";
import FindHospital from "./FIndHospital";
import { Address } from "../../../../Helper/BenInterface";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { abhaFeatureFlags } from "../../../../featConfig/featAbha";

interface PreferredHospitalProps {
  changeStep: (step: number) => void;
  setData: (data: any) => void;
  data: any;
  setCurrentStep: (step: number) => void;
}

export const PreferredHospital: React.FC<PreferredHospitalProps> = ({
  changeStep,
  setData,
  data,
  setCurrentStep,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(data.address[0]);
  const [changeBox, setChangeBox] = useState<boolean>(false);
  const [hospitals, setHospitals] = useState<any>([]);
  const [sliderValue, setSliderValue] = React.useState(5);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [geoCode, setGeoCode] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hospitalLoading, setHospitalLoading] = useState<boolean>(false);
  const [selectedHospital, setSelectedHospital] = useState<any>(
    data.address[0]?.preferredHospital
      ? data.address[0].preferredHospital[0]
      : ""
  );
  const [hospitalName, setHospitalName] = useState(""); // eslint-disable-line
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();
  const { address } = data;
  const getGeoCode = async (address: any) => {
    let geoData = await getLatLong(
      address?.addressLine1 + " " + address?.city + " " + address?.pinCode
    );
    setGeoCode(geoData);
    getHospital(5, geoData);
  };

  const handleSelect = async (address: any) => {
    if (selectedAddress === address) {
      setSelectedAddress(null);
    } else {
      setSelectedAddress(address);
    }
    getGeoCode(address);
  };

  const getHospital = async (radius: number, geoCode: any) => {
    setHospitalLoading(true);
    try {
      if (!geoCode) {
        return setHospitals([]);
      }
      let hospitals = await getNearByHospitals({
        latitude: geoCode.lat,
        longitude: geoCode.lng,
        radius: radius * 1000,
      });

      // ** Preferred hospital data sort according to handleEmergency key.
      // ** return -> sorted data based on isHandleEmergency key true.
      hospitals = hospitals.sort((a: any, b: any) =>
        a.handleEmergency === b.handleEmergency ? 0 : a.handleEmergency ? -1 : 1
      );
      console.log(hospitals, "hospitals");

      let hospitalDataWithDistance = hospitals.map((hospital: any) => {
        let distance = getDistanceFromLatLonInKm(
          geoCode.lat,
          geoCode.lng,
          hospital.location.coordinates[1],
          hospital.location.coordinates[0]
        );
        hospital.distance = distance;
        return hospital;
      });

      if (selectedHospital) {
        const filteredHospitals = hospitalDataWithDistance.filter(
          (hosp: any) => hosp._id !== selectedHospital.id
        );
        const filteredSelectHospitals = hospitalDataWithDistance.filter(
          (hosp: any) => hosp._id === selectedHospital.id
        );
        setHospitals([...filteredSelectHospitals, ...filteredHospitals]);
      } else {
        setHospitals(hospitalDataWithDistance);
      }
      setHospitalLoading(false);
    } catch (error: any) {
      setHospitals([]);
      setHospitalLoading(false);
    }
  };

  const handleSliderValue = async (value: any) => {
    setSliderValue(value);
    getHospital(value, geoCode);
  };

  const handleHospitalSelect = (hospital: any) => {
    if (selectedHospital?.id === hospital?._id) {
      setSelectedHospital(null);
    } else {
      setSelectedHospital({
        id: hospital._id,
        hospitalName: hospital.name,
        distance: hospital.distance.toFixed(2) || "Not Available",
      });
    }
  };

  const savePreferredHospital = async () => {
    let hospitalPayLoad = selectedHospital;

    try {
      await updateBeneficiaryAddress(data._id, {
        _id: selectedAddress._id,
        preferredHospital: [hospitalPayLoad],
      });
      ShowAlert("success", "Preferred Hospital Saved Successfully!");
      setTimeout(() => {
        navigate(`/profile/view/${data._id}`);
      }, 1500);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
      }
      ShowAlert("warning", "Something went wrong, please try again later");
    }
  };
  useEffect(() => {
    getGeoCode(selectedAddress);
  }, [changeBox]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {data.address.length < 1 ? (
        <>
          <Heading color={"red"}>Please add address first!</Heading>
        </>
      ) : (
        <>
          <></>
          {/* @ts-ignore */}
          <>
            <Box
              p={"5"}
              border={"1px solid #cecece"}
              mb={"5"}
              borderRadius={"10"}
            >
              <Text fontWeight={"1000"} mb={"3"}>
                Increase Search Radius
              </Text>
              <Slider
                id="slider"
                defaultValue={5}
                min={5}
                max={50}
                colorScheme="teal"
                onChange={(v) => handleSliderValue(v)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <SliderMark value={10} mt="1" ml="-2.5" fontSize="sm">
                  10 KM
                </SliderMark>
                <SliderMark value={20} mt="1" ml="-2.5" fontSize="sm">
                  20 KM
                </SliderMark>
                <SliderMark value={30} mt="1" ml="-2.5" fontSize="sm">
                  30 KM
                </SliderMark>
                <SliderMark value={40} mt="1" ml="-2.5" fontSize="sm">
                  40 KM
                </SliderMark>
                <SliderTrack height={"2"}>
                  <SliderFilledTrack />
                </SliderTrack>
                <Tooltip
                  hasArrow
                  bg="teal.500"
                  color="white"
                  placement="top"
                  isOpen={showTooltip}
                  label={`${sliderValue} KM`}
                >
                  <SliderThumb />
                </Tooltip>
              </Slider>
            </Box>
            <Flex wrap={"wrap"} direction="row" gap={"10"} mb={"10"} mt={"10"}>
              {data.address.length > 0 &&
                data.address.map((address: Address, index: number) => {
                  return (
                    <SimpleGrid width="100%" columns={2} spacing={2}>
                      <Box width="100%">
                        <Checkbox
                          defaultChecked={true}
                          key={address._id}
                          isChecked={selectedAddress === address}
                          onChange={() => {
                            handleSelect(address);
                            setChangeBox((prevChangeBox) => !prevChangeBox);
                            setSelectedHospital(
                              address.preferredHospital?.[0]
                                ? address.preferredHospital[0]
                                : ""
                            );
                          }}
                          border="1px solid #cecece"
                          p={3}
                          borderRadius="10"
                          width={"100%"}
                          // flex={3}
                        >
                          {address?.addressLine1} <br />
                          {address?.city}, {address.pinCode}
                          <br />
                        </Checkbox>
                      </Box>
                      {address?.preferredHospital &&
                      address.preferredHospital.length > 0 ? (
                        <Box
                          width="100%"
                          border="1px solid #cecece"
                          p={3}
                          borderRadius="10"
                          // flex={2}
                        >
                          <Text>
                            {address.preferredHospital[0]?.hospitalName || ""}
                          </Text>
                        </Box>
                      ) : null}
                    </SimpleGrid>
                  );
                })}
            </Flex>
          </>
          <Box
            border={"1px solid #cecece"}
            mb={"10"}
            p={"5"}
            borderRadius={"10"}
          >
            {hospitalLoading ? (
              <Heading>Loading...</Heading>
            ) : hospitals.length < 1 ? (
              <Heading color={"red"}>No Hospital Found!</Heading>
            ) : (
              <>
                <Flex justifyContent={"space-around"}>
                  <Heading mb={"3"}>Select Nearby Hospital</Heading>
                  {selectedHospital ? (
                    <Button
                      bgColor={"#38a169"}
                      color={"white"}
                      onClick={savePreferredHospital}
                    >
                      Save Preferred Hospital
                    </Button>
                  ) : (
                    <></>
                  )}
                </Flex>
                <Box height={"48vh"} overflowY={"scroll"} p={"5"}>
                  <Grid
                    templateColumns={[
                      "repeat(1, 1fr)",
                      "repeat(2, 1fr)",
                      "repeat(3, 1fr)",
                    ]}
                    gap={[4]}
                  >
                    {hospitals.map((hospital: any, index: number) => {
                      return (
                        <Checkbox
                          border="1px solid #cecece"
                          p={4}
                          borderRadius="10"
                          // position="relative"
                          sx={{
                            width: ["100%", "100%", "100%"],
                            whiteSpace: "normal",
                            overflow: "hidden",
                          }}
                          height="auto"
                          key={hospital._id}
                          onChange={() => handleHospitalSelect(hospital)}
                          isChecked={
                            selectedHospital && hospital
                              ? selectedHospital.id === hospital._id
                              : false
                          }
                        >
                          {hospital.name}
                          <br /> <br />
                          {hospital.address}
                          <br /> <br />
                          {"Distance: " + hospital.distance.toFixed(2) + " KM"}
                        </Checkbox>
                      );
                    })}
                  </Grid>
                </Box>
              </>
            )}
          </Box>
          <Box width={"80%"} margin={"auto"} textAlign={"center"}>
            <Heading as={"h2"} size={"md"} mb={"5"}>
              Hospital Not Found?
            </Heading>
            <Button
              color={"white"}
              bgColor={"#3182ce"}
              onClick={() => setShowModal(true)}
            >
              Add Hospital
            </Button>
          </Box>
        </>
      )}

      {/* add hospital modal */}

      {showModal && (
        <FindHospital
          data={data}
          addresses={address}
          closeModal={handleCloseModal}
          setData={setData}
          selectedAddress={selectedAddress}
        />
      )}
      <HStack marginTop={"30px"} justifyContent={"space-between"}>
        <GrFormPrevious
          style={{
            fontSize: "40px",
            padding: "10px",
            borderRadius: "50px",
            backgroundColor: "#cecece",
            color: "black",
            cursor: "pointer",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
          }}
          onClick={() => changeStep(-1)}
        />
        {abhaFeatureFlags["abhaIntegration"] && (
          <GrFormNext
            style={{
              fontSize: "40px",
              padding: "10px",
              borderRadius: "50px",
              backgroundColor: "#cecece",
              color: "black",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
            }}
            onClick={() => changeStep(+1)}
          />
        )}
      </HStack>
    </>
  );
};
