import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

const SelectAccount = ({ accounts, setStatus, handleSelectedAccount }: any) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
    window.location.reload();
    setStatus({
      termsConditionsStatus: true,
      generateMobileOtpStatus: false,
      VerifyMobileOtpStatus: false,
      selectAccountStatus: false,
    });
  };

  return (
    <div style={{padding:"4",background:"transparent"}}  >
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "sm", xl: "md" }}  
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mr={4} textAlign="left">
            Select the ABHA account you want to link with Pococare
          </ModalHeader>
          <ModalCloseButton ml={4} />
          <ModalBody>
            <Box>
              <Flex flexDirection="column" justifyContent="space-evenly">
                {accounts.map((acc: any, ind: any) => (
                  <Box
                    boxShadow="xl"
                    p="6"
                    rounded="md"
                    bg="#f5f9f9"
                    borderWidth="2px"
                    borderRadius="xl"
                    key={ind + "***"}
                    mt={2}
                    onClick={() => handleSelectedAccount(acc)}
                    _hover={{
                      bg: "#008470",
                      borderColor: "gray.200",
                      color: "white",
                    }}
                    cursor="pointer"
                  >
                    <Text
                      padding={1}
                      fontSize="xl"
                      fontWeight="semibold"
                      lineHeight="short"
                    >
                      Account {ind + 1}
                    </Text>
                    <Text ml={1} fontWeight={"500"}>
                      HealthIdNumber : {acc.healthIdNumber}
                    </Text>
                    <Text ml={1} fontWeight={"500"}>
                      HealthId: {acc.healthId}
                    </Text>
                    <Text ml={1} fontWeight={"500"}>
                      Name : {acc.name}
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SelectAccount;
