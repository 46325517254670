import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    PinInput,
    PinInputField,
    Stack,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { ShowAlert } from "../../GlobalComponents/ShowAlert/ShowAlert";
import { activateTempBenAction, sendOtpForActivateUser, verifyOtpForActivationAction } from "./actiivation.action";

const Activation: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { tempBenId } = useParams<{ tempBenId: string }>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [pin, setPin] = useState(['', '', '', '', '', '']);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobileNumber: "",
        benId: tempBenId,
    });
    const [resendCountdown, setResendCountdown] = useState(30);

    const [showPrompt, setShowPrompt] = useState(false);

    const messagePrompt = "Invalid Email";

    const handleSubmit = async () => {
        console.log("Form Data:", formData);
        let { email, name, mobileNumber } = formData;

        if (email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(email)) {
                setShowPrompt(true);
                return;
            }
        }

        if (!name || !mobileNumber || !email) {
            return ShowAlert("warning", "All fields are required");
        }
        setLoading(true);
        setResendCountdown(30);
        try {
            if (email) {
                await sendOtpForActivateUser({ email, mobile: String(mobileNumber) });
                setLoading(false);
                onOpen();
            } else {
                await sendOtpForActivateUser({ mobile: String(mobileNumber) });
                setLoading(false);
                onOpen();
            }
        } catch (error) {
            console.log(error, "Error while sending otp");
            setLoading(false);
            onClose();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        // @ts-ignore
        if (id === 'mobileNumber') {
            const isInvalidValue = !/^[0-9]+$/.test(value);
            if (isInvalidValue) {
                setFormData((prevData) => ({
                    ...prevData,
                    [id]: value.slice(0, -1), // Remove the last character
                }));
                return;
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const decrementCountdown = () => {
        setResendCountdown((prevCount) => Math.max(0, prevCount - 1));
    };

    useEffect(() => {
        let timerId: NodeJS.Timeout;

        if (resendCountdown > 0) {
            timerId = setInterval(() => {
                decrementCountdown();
                if (resendCountdown === 0) {
                    clearInterval(timerId);
                }
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [resendCountdown]);

    const handleResendOTP = async () => {
        // Add your logic to resend OTP here

        const { email, mobileNumber } = formData

        try {
            await sendOtpForActivateUser({ email, mobile: String(mobileNumber) });
        } catch (error) {
            console.log(error, "Error while sending otp")
        }

        // Reset countdown to 30 seconds
        setResendCountdown(30);

        // Start the timer again
        let timerId: NodeJS.Timeout;
        timerId = setInterval(() => {
            decrementCountdown();
            if (resendCountdown === 0) {
                clearInterval(timerId);
            }
        }, 1000);

        // Show a success message or alert
        ShowAlert("success", "OTP resent successfully");
    };

    const handlePinChange = (index: any, value: any) => {
        const newPin = [...pin];
        newPin[index] = value;
        setPin(newPin);
    };

    const submitOtp = async () => {
        let { mobileNumber } = formData;
        try {
            let otpValue = pin.join("");
            let response = await verifyOtpForActivationAction({ mobile: mobileNumber, otp: otpValue });
            let activateToken = response?.activateToken;
            if (activateToken) {
                await activateAccount(activateToken);
            }
        } catch (error) {
            console.log("Error while validating otp", error)
        }
    }

    const activateAccount = async (activateToken: string) => {
        let { email, name, mobileNumber, benId } = formData;

        if (!name || !mobileNumber) {
            return ShowAlert("warning", "All fields are required");
        };

        const activationPayload = {
            benId: benId,
            name: name,
            email: email,
            mobileNo: {
                countryCode: 91,
                number: Number(mobileNumber)
            },
            activateToken: activateToken
        }

        try {
            await activateTempBenAction(activationPayload);
            ShowAlert("success", "Account Succeffuly activated, please login");
            return navigate("/");
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <>
            {/* Logo */}
            <HStack mt={"10px"} justifyContent={"space-between"} p={"20px"}>
                <Image width={"130px"} minW={"100px"} objectFit="cover" src="/Images/logo.png" alt="Logo" />
                <Image width={"130px"} maxW={"100px"} objectFit="cover" src={sessionStorage.getItem('companyLogo') || "/Images/logo.png"} alt="Logo" />
            </HStack>

            <Box
                minHeight="60vh"
                display="flex"
                flexDirection={["column", "column", "column", "row"]}
            // border={"1px"}
            >
                {window.innerWidth > 1000 && (
                    <Stack justify="center" alignSelf="center">
                        <Image objectFit="cover" src="/Images/ak.png" alt="Signup" />
                    </Stack>
                )}
                <Box width="100%">
                    {/* <Stack direction="row" pl={16} pr={16} pt={8} pb={10} align="center" justify="flex-end">
                    </Stack> */}
                    <Stack pl={16} pr={16} mb={5}>
                        <Text m={0} fontSize="3xl" fontWeight="bold" fontFamily="body">
                            Hello there!
                        </Text>
                        <Text
                            m={0}
                            fontSize="sm"
                            fontFamily="body"
                            color="gray.400"
                            lineHeight="tall"
                        >
                            Activate your account
                        </Text>
                    </Stack>
                    <Stack pl={16} pr={24} spacing={6}>
                        <FormControl id="name">
                            <FormLabel
                                fontSize="sm"
                                lineHeight="tall"
                                fontFamily="body"
                                fontWeight="normal"
                            // pb={"0.5rem"}
                            >
                                Name
                            </FormLabel>
                            <Input
                                type="text"
                                id="name"
                                size="md"
                                borderColor="gray.200"
                                focusBorderColor="green.500"
                                lineHeight="tall"
                                placeholder="Atul Kumar"
                                fontFamily="body"
                                _placeholder={{ color: "gray.400" }}
                                onChange={handleInputChange}
                                value={formData.name}
                                required={true}
                            />
                        </FormControl>
                        <FormControl id="email">
                            <FormLabel
                                fontSize="sm"
                                lineHeight="tall"
                                fontFamily="body"
                                fontWeight="normal"
                            // pb={"0.5rem"}
                            >
                                Email{" "}
                            </FormLabel>
                            <Input
                                type="email"
                                id="email"
                                size="md"
                                borderColor="gray.200"
                                focusBorderColor="green.500"
                                lineHeight="tall"
                                placeholder="atul.k@pococare.com"
                                fontFamily="body"
                                _placeholder={{ color: "gray.400" }}
                                onChange={handleInputChange}
                                value={formData.email}
                            />
                            {showPrompt ? <Text color={"red"}>{messagePrompt}</Text> : ""}
                        </FormControl>
                        {/* <FormControl id="city">
                            <FormLabel
                                fontSize="sm"
                                lineHeight="tall"
                                fontFamily="body"
                                fontWeight="normal"
                            // pb={"0.5rem"}
                            >
                                City{" "}
                            </FormLabel>
                            <Input
                                type="text"
                                id="city"
                                size="md"
                                borderColor="gray.200"
                                focusBorderColor="green.500"
                                lineHeight="tall"
                                placeholder="Bengaluru"
                                fontFamily="body"
                                _placeholder={{ color: "gray.400" }}
                                onChange={handleInputChange}
                                value={formData.city}
                                required={true}
                            />
                        </FormControl>
                        <FormControl id="Pincode">
                            <FormLabel fontSize="sm" lineHeight="tall" fontFamily="body" fontWeight="normal">
                                Pincode{" "}
                            </FormLabel>
                            <Input
                                type="tel"
                                id="pincode"
                                size="md"
                                maxLength={6}
                                borderColor="gray.200"
                                focusBorderColor="green.500"
                                lineHeight="tall"
                                placeholder="560103"
                                fontFamily="body"
                                _placeholder={{ color: "gray.400" }}
                                onChange={handleInputChange}
                                value={formData.pincode}
                                required={true}
                            />
                        </FormControl> */}
                        <FormControl id="Mobile Number">
                            <FormLabel fontSize="sm" lineHeight="tall" fontFamily="body" fontWeight="normal">
                                Mobile Number{" "}
                            </FormLabel>
                            <Input
                                type="tel" // type="tel" for input that should contain a telephone number
                                id="mobileNumber"
                                size="md"
                                maxLength={10}
                                borderColor="gray.200"
                                focusBorderColor="green.500"
                                lineHeight="tall"
                                placeholder="1234567890"
                                fontFamily="body"
                                _placeholder={{ color: "gray.400" }}
                                onChange={handleInputChange}
                                value={formData.mobileNumber}
                                required={true}
                            />
                        </FormControl>
                        <Button
                            isLoading={loading ? true : false}
                            loadingText="Please Wait"
                            my={3}
                            size="md"
                            w={170}
                            h={45}
                            borderRadius="40px"
                            bg="green.500"
                            color="white"
                            border="0"
                            fontWeight="400"
                            fontFamily="body"
                            fontSize="sm"
                            cursor="pointer"
                            _focus={{
                                outline: "none",
                            }}
                            _hover={{
                                bg: "green.500",
                            }}
                            boxShadow="lg"
                            onClick={handleSubmit}
                        >
                            Send OTP
                        </Button>

                    </Stack>
                </Box>
            </Box>

            {/* OTP Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Enter OTP</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack gap={"20px"} justifyContent={"center"}>
                            <PinInput size="lg" placeholder="" focusBorderColor="green.500">
                                {pin.map((value, index) => (
                                    <PinInputField
                                        key={index}
                                        value={value}
                                        onChange={(e) => handlePinChange(index, e.target.value)}
                                    />
                                ))}
                            </PinInput>
                        </HStack>
                    </ModalBody>

                    <HStack justifyContent={"center"} mt={"10px"}>
                        <Button onClick={submitOtp} colorScheme="green">Verify OTP</Button>
                    </HStack >

                    <HStack justifyContent={"center"}>
                        {resendCountdown > 0
                            ? <Text mt={"10px"} textAlign={"left"}>Resend OTP in {resendCountdown} seconds</Text>
                            : <Box textAlign={"left"} marginTop={"10px"}><Button variant="ghost" onClick={handleResendOTP}>Resend OTP</Button></Box>
                        }
                    </HStack>

                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Activation;
