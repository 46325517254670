// import { removeBeneficiary } from "../../../Redux/Beneficiary-Slice/Beneficiary-slice"
// import { removeSubscriber } from "../../../Redux/Subscriber-Slice/Subscriber-slice"
// import { logOutUser } from "../../../http"

import { logOutUser } from "../../../http"

export const LogOutAction = async (id: string, dispatch: any) => {
    try {
        console.log(id)
        // localStorage.clear()
        logOutUser();
        sessionStorage.removeItem("lastVisitedPage")
        localStorage.removeItem("beneficiariesData")
        localStorage.removeItem("userData")
        localStorage.removeItem("persist:MainData")
        window.location.reload();

        // dispatch(removeBeneficiary())
        // dispatch(removeSubscriber())
    } catch (e: any) {
        console.log(e)
        throw e
    }
}
