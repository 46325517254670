import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
  Wrap,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  checkAndGenerateMobileOtp,
  createHealthIdWithPreVerified,
} from "../../../../../http";
import { ShowAlert } from "../../../../../GlobalComponents/ShowAlert/ShowAlert";
import {
  generateAdhaarOtpAction,
  getSingleBenDataAction,
  resendAdhaarOtpAction,
  resenedPrimaryMobileOtpAction,
  saveAbhaDetailsAction,
  verifyAdhaarOtpAction,
  verifyPrimaryMobileOtpAction,
} from "../../../Actions/beneficiaryDetails.action";
import { useParams } from "react-router-dom";
import { suggestThreeUserName } from "../Shared/SuggestThreeUserName";
import { FaHospitalUser } from "react-icons/fa";
import Timer from "../Shared/Timer";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

const CreateAbhaIdByAadhaar = ({ setIntegrateModalStatus }: any) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { benId } = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState<any>(30);
  const [isOpenGlobal, setIsOpenGlobalState] = useState(false);
  const [isAadhaarloading, setAadhaarLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [suggestHealtIds, setSuggestedHealthIds] = useState<string[]>([]);
  const [crtHealthIdPreverifiedData, setHealthIdPreverifiedFormData] = useState(
    {
      healthId: "",
      password: "",
    }
  );
  const [aadhaarOtp, setOtp] = useState(new Array(6).fill(" "));
  const [primaryMobileOtp, setPrimaryMobileOtp] = useState(
    new Array(6).fill(" ")
  );
  const [aadhaarNumber, setAadhaar] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [modalStatus, setModalStatus] = useState({
    termsConditionStatus: true,
    genrateAadhaarOtpModalStatus: false,
    otpModalStatus: false,
    createAbhaFormStatus: false,
    checkMobileLinkWithAadhaarModalStatus: false,
    verifyPrimaryMobileModalStatus: false,
  });
  const handleCheckboxChange = () => {
    setIsChecked(isChecked ? false : true); // Toggle the isChecked state
  };
  const fetchBenficiaryDetails = async (benId: any) => {
    try {
      const response = await getSingleBenDataAction(benId);
      const threeName = suggestThreeUserName(
        response.data.data.personalInfo.fullName
      );
      console.log(threeName, "threeName threeName");
      setSuggestedHealthIds(threeName);
    } catch (error: any) {
      ShowAlert(
        "warning",
        error.response?.data?.message || "Error fetching Beneficiary details"
      );
    }
  };
  const onOpendGlobal = () => {
    setIsOpenGlobalState(true);
  };
  const onCloseGlobal = () => {
    setIsOpenGlobalState(false);
  };
  const onClose = () => {
    setIsOpen(false);
    window.location.reload();
    setIntegrateModalStatus({
      linkAbhaStatus: true,
      createAbhaStatus: true,
    });
  };

  const handleAadhaarOtp = (value: any, index: any) => {
    const newOtp = [...aadhaarOtp];
    newOtp[index] = value;
    setOtp(newOtp);
  };
  const handlePrimaryMobileOtp = (value: any, index: any) => {
    const newOtp = [...primaryMobileOtp];
    newOtp[index] = value;
    setPrimaryMobileOtp(newOtp);
  };
  const handleSubmitVerifyAadhaarOtp = async () => {
    setIsLoading(false);
    if (aadhaarOtp.length < 6) {
      return ShowAlert("warning", "Fill all the fields");
    }
    if (aadhaarOtp.length === 6) {
      try {
        setIsLoading(true);
        let parsedTxnId;
        let transactionId = localStorage.getItem("txnId");
        if (transactionId) {
          parsedTxnId = JSON.parse(transactionId);
        }
        await verifyAdhaarOtpAction({
          otp: aadhaarOtp.join(""),
          txnId: parsedTxnId.txnId,
        });
        setIsLoading(false);
        setModalStatus((prevState) => ({
          ...prevState,
          genrateAadhaarOtpModalStatus: false,
          otpModalStatus: false,
          checkMobileLinkWithAadhaarModalStatus: true,
          createAbhaFormStatus: false,
          verifyPrimaryMobileModalStatus: false,
        }));
      } catch (error: any) {
        setIsLoading(false);
        return ShowAlert("warning", error.response.data.message);
      }
    }
  };
  const handleSubmitVerifyPrimaryMobileOtp = async () => {
    setIsLoading(false);
    if (primaryMobileOtp.length < 6) {
      return ShowAlert("warning", "Fill all the fields");
    }
    if (primaryMobileOtp.length === 6) {
      try {
        setIsLoading(true);
        let parsedTxnId;
        let transactionId = localStorage.getItem("txnId");
        if (transactionId) {
          parsedTxnId = JSON.parse(transactionId);
        }
        await verifyPrimaryMobileOtpAction({
          otp: primaryMobileOtp.join(""),
          txnId: parsedTxnId.txnId,
        });
        setIsLoading(false);
        setModalStatus((prevState) => ({
          ...prevState,
          genrateAadhaarOtpModalStatus: false,
          otpModalStatus: false,
          checkMobileLinkWithAadhaarModalStatus: false,
          verifyPrimaryMobileModalStatus: false,
          createAbhaFormStatus: true,
        }));
      } catch (error: any) {
        setIsLoading(false);
        return ShowAlert("warning", error.response.data.message);
      }
    }
  };
  const handleSubmitGenerateOtp = async () => {
    const extractNumbersOnly = aadhaarNumber.replace(/\D/g, "");
    setAadhaarLoading(true);
    if (extractNumbersOnly.length < 12 || extractNumbersOnly.length > 12) {
      setAadhaarLoading(false);
      return ShowAlert(
        "warning",
        "Adhaar no must be of 12 digit which should contains only digits"
      );
    }
    if (extractNumbersOnly.length === 12) {
      try {
        setAadhaarLoading(true);
        let response = await generateAdhaarOtpAction({
          aadhaar: extractNumbersOnly,
        });
        setAadhaarLoading(false);
        localStorage.setItem("txnId", JSON.stringify(response.data));
        setModalStatus((prevState) => ({
          ...prevState,
          genrateAadhaarOtpModalStatus: false,
          otpModalStatus: true,
          createAbhaFormStatus: false,
          checkMobileLinkWithAadhaarModalStatus: false,
          verifyPrimaryMobileModalStatus: false,
        }));
      } catch (error: any) {
        setAadhaarLoading(false);
        return ShowAlert("warning", error.response.data.message);
      }
    }
    setAadhaar("");
  };
  const handleChangeAdhaar = (value: any) => {
    setAadhaar(value);
  };
  const handleNext = () => {
    setModalStatus((prevState) => ({
      ...prevState,
      termsConditionStatus: false,
      genrateAadhaarOtpModalStatus: true,
      otpModalStatus: false,
      checkMobileLinkWithAadhaarModalStatus: false,
      createAbhaFormStatus: false,
      verifyPrimaryMobileModalStatus: false,
    }));
  };
  const handleChangeMobileNumber = (value: any) => {
    setMobileNumber(value);
  };
  const handleSubmitMobileLinkWithAadhaar = async () => {
    setIsLoading(false);
    if (mobileNumber.length === 10) {
      try {
        setIsLoading(true);
        let parsedTxnId;
        let transactionId = localStorage.getItem("txnId");
        if (transactionId) {
          parsedTxnId = JSON.parse(transactionId);
        }
        let response = await checkAndGenerateMobileOtp({
          mobile: mobileNumber.toString(),
          txnId: parsedTxnId.txnId,
        });
        if (response.data.mobileLinked) {
          setIsLoading(false);
          setModalStatus((prevState) => ({
            ...prevState,
            genrateAadhaarOtpModalStatus: false,
            otpModalStatus: false,
            checkMobileLinkWithAadhaarModalStatus: false,
            createAbhaFormStatus: true,
            verifyPrimaryMobileModalStatus: false,
          }));
        } else {
          setIsLoading(false);
          setModalStatus((prevState) => ({
            ...prevState,
            genrateAadhaarOtpModalStatus: false,
            otpModalStatus: false,
            checkMobileLinkWithAadhaarModalStatus: false,
            createAbhaFormStatus: false,
            verifyPrimaryMobileModalStatus: true,
          }));
          return ShowAlert(
            "warning",
            "Entered Mobile No is not linked with aadhaar"
          );
        }
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        return ShowAlert("warning", error.response.data.message);
      }
    } else {
      setIsLoading(false);
      return ShowAlert("warning", "Mobile number must be of 10 digit");
    }
  };
  const handleChangeHealthIdPreverified = (e: any) => {
    const { name, value } = e.target;
    setHealthIdPreverifiedFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCreateHealthIdPreVerifiedSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (!crtHealthIdPreverifiedData.password.trim()) {
        return ShowAlert("warning", "HealthId and Password can not be empty.");
      }
      if (crtHealthIdPreverifiedData) {
        setIsLoading(true);
        let parsedTxnId;
        let transactionId = localStorage.getItem("txnId");
        if (transactionId) {
          parsedTxnId = JSON.parse(transactionId);
        }
        const payload = {
          ...crtHealthIdPreverifiedData,
          txnId: parsedTxnId.txnId,
        };
        const response = await createHealthIdWithPreVerified(payload);
        const abhaDetails = {
          abhaLinked: true,
          beneficiaryId: benId,
          abhaMobileNumber: response?.data?.mobile,
          abhaHealthId: response?.data?.healthId,
          abhaHealthNumber: response?.data?.healthIdNumber,
          abhaAddress: response?.data?.healthId,
          txnId: parsedTxnId?.txnId,
        };
        await saveAbhaDetailsAction(abhaDetails);
        setIsLoading(false);
        setIsOpen(false);
        ShowAlert("success", "SuccessFully Created Your the Abha Account");
        window.location.reload();
        return;
      }
    } catch (error: any) {
      setIsLoading(false);
      return ShowAlert("warning", error.response.data.message);
    }
  };
  const handleResendAadhaarOtp = async () => {
    try {
      let parsedTxnId;
      let transactionId = localStorage.getItem("txnId");
      if (transactionId) {
        parsedTxnId = JSON.parse(transactionId);
      }
      if (parsedTxnId) {
        let payload = { txnId: parsedTxnId.txnId };
        await resendAdhaarOtpAction(payload);
        ShowAlert(
          "success",
          "Resend otp successfully to your registered mobile no."
        );
      }
    } catch (error: any) {
      ShowAlert("warning", error.response.data.message);
    }
  };
  const handleResendPrimaryMobileOtp = async () => {
    try {
      let parsedTxnId;
      let transactionId = localStorage.getItem("txnId");
      if (transactionId) {
        parsedTxnId = JSON.parse(transactionId);
      }
      if (parsedTxnId) {
        let payload = { mobile: mobileNumber, txnId: parsedTxnId.txnId };
        console.log(payload, "payload payload payload");
        await resenedPrimaryMobileOtpAction(payload);
        setSeconds(30);
        ShowAlert("success", "Resend otp successfully to your mobile no.");
      }
    } catch (error: any) {
      ShowAlert("warning", error.response.data.message);
    }
  };
  // const handleSelectHealthId = (name: string, ind: any) => {
  //   setSelectedHealthId(name);
  // };
  useEffect(() => {
    const fetchData = async () => {
      await fetchBenficiaryDetails(benId);
    };
    fetchData();
  }, [benId]);

  return (
    <div style={{ display: "flex" }}>
      {modalStatus?.termsConditionStatus && (
        <Flex width={{ base: "100%" }}>
          <Box width={{ base: "100%" }}>
            <Button
              bg="#008470"
              color="white"
              width={{ base: "100%" }}
              _hover={{
                bg: "#008470",
              }}
              onClick={onOpendGlobal}
            >
              <div style={{ padding: "5px" }}>
                {" "}
                <FaHospitalUser />
              </div>
              Create new ABHA Account
            </Button>

            <Modal
              initialFocusRef={initialRef}
              finalFocusRef={finalRef}
              isOpen={isOpenGlobal}
              onClose={onCloseGlobal}
              isCentered
              size={{ base: "sm", xl: "xl" }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader> Agree Terms & Conditions </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box>
                    <Box p={2} >
                      <TermsAndConditions
                        isChecked={isChecked}
                        handleCheckboxChange={handleCheckboxChange}
                      />
                    </Box>
                    <Box textAlign="center">
                      <Button
                        onClick={handleNext}
                        mr={3}
                        mt={5}
                        bg="#008470"
                        color="white"
                        isDisabled={!isChecked}
                        _hover={{
                          bg: "#008470",
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Flex>
      )}
      {modalStatus?.genrateAadhaarOtpModalStatus && (
        <Flex width={{ base: "100%" }}>
          <Box width={{ base: "100%" }}>
            <Modal
              initialFocusRef={initialRef}
              finalFocusRef={finalRef}
              isOpen={isOpen}
              onClose={onClose}
              isCentered
              size={{ base: "sm", xl: "xl" }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader> Enter Your Aadhaar Number </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box>
                    <Box display="flex" alignItems="center">
                      <Box width="40%" m={2} p={3}>
                        <Image
                          boxSize={{ base: "120px", xl: "150px" }}
                          src="https://developmentbucket.blob.core.windows.net/logos/abhaLogo.png"
                        />
                      </Box>
                      <Box width="60%">
                        <FormControl>
                          <FormLabel>Enter Aadhaar Number</FormLabel>
                          <Input
                            ref={initialRef}
                            placeholder="Aadhaar 3677-XXXX-6852"
                            value={aadhaarNumber}
                            type="text"
                            width="full"
                            onChange={(e) => handleChangeAdhaar(e.target.value)}
                          />
                        </FormControl>
                        <Button
                          onClick={handleSubmitGenerateOtp}
                          mr={3}
                          mt={5}
                          bg="#008470"
                          color="white"
                          isDisabled={!isChecked}
                          isLoading={isAadhaarloading}
                          loadingText="Sending"
                          _hover={{
                            bg: "#008470",
                          }}
                        >
                          Generate OTP
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Flex>
      )}
      {modalStatus?.otpModalStatus && (
        <Box p={4}>
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size={{ base: "sm", xl: "md" }}
          >
            <ModalOverlay />

            <ModalContent>
              <ModalHeader textAlign="left">Verify Aadhaar OTP</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <FormControl>
                    <HStack justifyContent="center" textAlign="center">
                      <PinInput placeholder="" otp size="md">
                        {aadhaarOtp.map((curr, index) => (
                          <PinInputField
                            key={index + "__"}
                            value={curr}
                            onChange={(e) =>
                              handleAadhaarOtp(e.target.value, index)
                            }
                          />
                        ))}
                      </PinInput>
                    </HStack>
                  </FormControl>
                  {
                    <Text mt={5} alignItems={"center"}>
                      Not received OTP?{" "}
                      <Link
                        onClick={() => handleResendAadhaarOtp()}
                        color={"#008470"}
                      >
                        Resend OTP
                      </Link>
                    </Text>
                  }
                  <Button
                    onClick={handleSubmitVerifyAadhaarOtp}
                    colorScheme="blue"
                    mr={3}
                    mt={6}
                    bg="#008470"
                    color="white"
                    isLoading={isLoading}
                    loadingText="Verifying"
                    _hover={{
                      bg: "#008470",
                    }}
                  >
                    Verify OTP
                  </Button>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
      {modalStatus?.checkMobileLinkWithAadhaarModalStatus && (
        <Box>
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size={{ base: "sm", xl: "md" }}
          >
            <ModalOverlay />

            <ModalContent>
              <ModalHeader>Link Primary Mobile Number </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box justifyContent="center" textAlign="center">
                  <FormControl>
                    <FormLabel>Enter Mobile Number</FormLabel>
                    <Input
                      ref={initialRef}
                      placeholder="Mobile Number"
                      value={mobileNumber}
                      type="text"
                      onChange={(e) => handleChangeMobileNumber(e.target.value)}
                    />
                  </FormControl>
                  <Button
                    disabled={true}
                    onClick={handleSubmitMobileLinkWithAadhaar}
                    bg="#008470"
                    color="white"
                    mr={3}
                    mt={6}
                    isLoading={isLoading}
                    loadingText="Sending"
                    _hover={{
                      bg: "#008470",
                      border: "2px solid white",
                    }}
                  >
                    Generate OTP
                  </Button>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
      {modalStatus?.verifyPrimaryMobileModalStatus && (
        <Box p={4}>
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size={{ base: "sm", xl: "md" }}
          >
            <ModalOverlay />

            <ModalContent>
              <ModalHeader textAlign="left">Verify Mobile OTP</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <FormControl>
                    <HStack justifyContent="center" textAlign="center">
                      <PinInput placeholder="" otp size="md">
                        {primaryMobileOtp.map((curr, index) => (
                          <PinInputField
                            key={index + "__"}
                            value={curr}
                            onChange={(e) =>
                              handlePrimaryMobileOtp(e.target.value, index)
                            }
                          />
                        ))}
                      </PinInput>
                    </HStack>
                  </FormControl>
                  {
                    <Text mt={5} alignItems={"center"}>
                      <span>
                        {" "}
                        <Timer timer={seconds} setSeconds={setSeconds} /> Not
                        received OTP?{" "}
                      </span>
                      <Link
                        onClick={handleResendPrimaryMobileOtp}
                        color={"#008470"}
                      >
                        Resend OTP
                      </Link>
                    </Text>
                  }
                  <Button
                    onClick={handleSubmitVerifyPrimaryMobileOtp}
                    colorScheme="blue"
                    mr={3}
                    mt={6}
                    bg="#008470"
                    color="white"
                    isLoading={isLoading}
                    loadingText="Verifying"
                    _hover={{
                      bg: "#008470",
                    }}
                  >
                    Verify OTP
                  </Button>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
      {modalStatus?.createAbhaFormStatus && (
        <Box>
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size={{ base: "sm", xl: "md" }}
          >
            <ModalOverlay />

            <ModalContent>
              <ModalHeader> Create HealthId with Pre-Verified </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box textAlign="center">
                  <form onSubmit={handleCreateHealthIdPreVerifiedSubmit}>
                    <FormControl mb="4">
                      <FormLabel>Create Health ID</FormLabel>
                      <Input
                        type="text"
                        name="healthId"
                        placeholder="Ex : username2002"
                        value={crtHealthIdPreverifiedData.healthId}
                        onChange={handleChangeHealthIdPreverified}
                        required
                      />
                    </FormControl>
                    <Flex
                      mb={4}
                      textAlign="left"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <span color="red">Suggested ID's</span>
                      <Wrap>
                        {suggestHealtIds.map((name, ind) => (
                          <Text
                            onClick={() =>
                              setHealthIdPreverifiedFormData((prev) => ({
                                ...prev,
                                healthId: name,
                              }))
                            }
                            fontSize={12}
                            bg="#008470"
                            borderRadius="50"
                            pl={2}
                            pr={2}
                            color="white"
                            key={ind + "***"}
                            ml={1}
                          >
                            {name}
                          </Text>
                        ))}
                      </Wrap>
                    </Flex>
                    <FormControl mb="4">
                      <FormLabel>Create Password</FormLabel>
                      <Input
                        name="password"
                        placeholder="Ex: India@144 ,1 Upper, LowerCase & special char"
                        value={crtHealthIdPreverifiedData.password}
                        onChange={handleChangeHealthIdPreverified}
                        required
                      />
                    </FormControl>
                    <Button
                      type="submit"
                      bg="#008470"
                      color="white"
                      isLoading={isLoading}
                      loadingText="Please wait..."
                      _hover={{
                        bg: "#008470",
                      }}
                    >
                      Create ABHA
                    </Button>
                  </form>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </div>
  );
};
export default CreateAbhaIdByAadhaar;
