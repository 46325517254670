import { Box, Heading, Text } from '@chakra-ui/react'

const CreateAbhaInfo = () => {
  return (
    <Box mb={4}>
    <Heading padding={2} as="h4" size="md">
      What is ABHA ?
    </Heading>
    <Text p={2} fontWeight={500}>
      The ABHA card's full form is the Ayushman Bharat Health
      Account. The ABHA card contains a unique 14-digit
      identification number called ABHA ID. The entire medical
      history, consultation details, and prescriptions are
      included in the ABHA ID.
    </Text>
    <Text p={2} fontWeight={500}>
      This card is managed under the Ayushman Bharat Digital
      Mission (ABDM), which is a digital healthcare initiative of
      the National Health Authority (NHA). It aims to create,
      develop, and strengthen the healthcare infrastructure of
      India. Under this mission, ABHA is one of the prominent
      steps to facilitate health programs nationwide.
    </Text>
  </Box>
  )
}

export default CreateAbhaInfo