import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import style from "./PocoBridge.module.css";
const PocoBridge: React.FC = () => {
  return (
    <>
      <Box className={style.wrapper}>
        <Box className={style.left_flex}>
          <Box className={style.text_wrapper}>
            <Text className={style.sub_head_style}>
            End to End Timely, Reliable transfer from Incident to ER admission.
            </Text>
          </Box>
          <Box className={style.image_wrapper}>
            <Image src="Images/poco_bridge_img_1.svg" alt="poco_Action" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PocoBridge;
