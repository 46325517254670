import { getSingleBenData } from "../../../http";


export const getSingleBenDetails = async (benId: string) => {
  try {
    const { data } = await getSingleBenData(benId);
    console.log('data:', data);
    if (data.code === 200) {
      return data;
    }
  } catch (e:any) {
    console.error("error from getting single ben data");
   //return alert(e.response.data.message)
  }
};
