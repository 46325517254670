import styled from '@emotion/styled';
import React, { FC } from 'react';
//@ts-ignore
import ReactFileReader from 'react-file-reader';

interface AvatarInputProps {
    url: string;
    handleFiles: (files: any) => void;
}

const AvatarInputWrapper = styled.div`
  margin-bottom: 10px;
  position: relative;
  align-self: center;
  cursor: pointer;

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }

  .circle {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }

    &:hover {
      background: blue;
    }
  }
`;

const ProfilePictureInput: FC<AvatarInputProps> = ({ url, handleFiles }) => {
    return (
        <ReactFileReader fileType={['.png', '.jpg', '.jpeg']} base64={true} handleFiles={handleFiles}>
            <AvatarInputWrapper>
                <img src={url} alt="Avatar Placeholder" />
            </AvatarInputWrapper>
        </ReactFileReader>
    );
};

export default ProfilePictureInput;
