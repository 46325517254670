  export const tabData = [
    {
      id: 1,
      tabName: "Pococare Documents",
    },
    {
      id: 2,
      tabName: "ABHA Documents",
    },
  ];

  export const tableHead = ["Label", "Description", "Date", "Status"];

  export const docTypeColorMapping: any = {
    Prescription: "rgba(240, 56, 156, 0.80)",
    DiagnosticReport: "rgba(56, 91, 240, 0.80)",
    OPConsultation: "rgba(56, 91, 240, 0.80)",
    HealthDocumentRecord: "rgba(56, 91, 240, 0.80)",
    DischargeSummary: "rgba(0, 167, 181, 0.80)",
  };

  export const docType: any = {
    CURRENT_MEDICATIONS: {
      key: "Medication",
      color: "rgba(240, 56, 156, 0.80)",
    },
    PAST_MEDICAL_RECORDS: {
      key: "Past Medical",
      color: "rgba(56, 91, 240, 0.80)",
    },
    ALLERGIES_TO_MEDICATIONS: {
      key: "Allergies",
      color: "rgba(56, 91, 240, 0.80)",
    },
    RECENT_HOSPITALIZATIONS: {
      key: "Recent Hospital",
      color: "rgba(0, 167, 181, 0.80)",
    },
  };

  export const hiType: any = {
    Prescription: {
      code: "440545006",
      display: "Prescription",
    },
    DiagnosticReport: {
      code: "721981007",
      display: "Diagnostic Report",
    },
    OPConsultation: {
      code: "371530004",
      display: "OP Consultation",
    },
    DischargeSummary: {
      code: "373942005",
      display: "Discharge Summary",
    },
    ImmunizationRecord: {
      code: "41000179103",
      display: "Immunization Record",
    },
    HealthDocumentRecord: {
      code: "419891008",
      display: "Record artifact",
    },
  };

  export const documentTypeMapping: any = {
    CURRENT_MEDICATIONS: "Prescription",
    PAST_MEDICAL_RECORDS: "DiagnosticReport",
    ALLERGIES_TO_MEDICATIONS: "HealthDocumentRecord",
    RECENT_HOSPITALIZATIONS: "DischargeSummary",
  };