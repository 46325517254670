import { Box, Flex, Image, Text } from '@chakra-ui/react'

export const DownloadApp = () => {
    return (
        <Flex
            padding={{ base: "18px 16px", md: "58px 98px" }}
            backgroundColor={"#EBEBEB"}
            flexDirection={{ base: "column", md: "row" }}
            alignItems={{ base: "center", md: "stretch" }} // Adjusted alignment for responsiveness
            justifyContent={{ base: "center", md: "flex-start" }} // Adjusted justification for responsiveness
            textAlign={{ base: "center", md: "left" }}
            gap={{ base: "24px", md: "0" }}
        >
            <Box
                // border={"1px"}
                // ml={{ base: "0", md: "10%" }}
                width={{ base: "100%", md: "50%" }}
                marginBottom={{ base: "24px", md: "0" }}
                textAlign={{ base: 'center', md: 'center' }}
                display={{ base: 'flex', md: 'flex' }} // Adjusted display for responsiveness
                justifyContent={{ base: 'center', md: 'center' }} // Adjusted justification for responsiveness
            >
                <Text
                    color="#030303"
                    fontFamily="Poppins"
                    fontSize={{ base: "18px", md: "24px" }}
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="normal"
                    textAlign={{ base: 'left', md: 'left' }}
                >
                    Download <Text as="span" fontWeight="500" color="#00A7B5">Poco</Text> <Text as="span" fontWeight="500" color="#F0389C">EmUrgency</Text> today and be #MedicalEmergencyReady!
                </Text>
            </Box>
            <Box
                // border={"1px"}
                ml={{ base: "0", md: "10%" }}
                width={{ base: "100%", md: "50%" }}
                marginBottom={{ base: "24px", md: "0" }}
                textAlign={{ base: 'center', md: 'center' }}
                display={{ base: 'flex', md: 'flex' }} // Adjusted display for responsiveness
                justifyContent={{ base: 'center', md: 'center' }} // Adjusted justification for responsiveness
            >
                <Image cursor={"pointer"} width={"auto"} maxWidth={{ base: "50%", md: "40%" }} src="/Images/playStoreIcon.png" alt="Description" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.pococare.pocoemurgency", "_blank")} />
            </Box>
        </Flex >
    )
}
