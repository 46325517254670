import React, { useState } from "react";
import GenerateMobileOtp from "../Shared/GenerateMobileOtp";
import VerifyMobileOtp from "../Shared/VerifyMobileOtp";
import { ShowAlert } from "../../../../../GlobalComponents/ShowAlert/ShowAlert";
import {
  authorizedTokenForLoginAction,
  generateLoginMobileOtpAction,
  getAbhaProfileAction,
  resenedLoginOtpAction,
  saveAbhaDetailsAction,
  verifyMobileOtpAction,
} from "../../../Actions/beneficiaryDetails.action";
import SelectAccount from "./SelectAccount";
import { useParams } from "react-router-dom";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { MdIntegrationInstructions } from "react-icons/md";

const LinkAbhaWithPococare = ({ setIntegrateModalStatus }: any) => {
  const { benId } = useParams();
  const [status, setStatus] = useState({
    termsConditionsStatus: true,
    generateMobileOtpStatus: false,
    VerifyMobileOtpStatus: false,
    selectAccountStatus: false,
  });
  const [accounts, setAccounts] = useState([]);
  const [otp, setOtp] = useState(new Array(6).fill(" "));
  const [mobileNo, setMobileNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const handleChangeVerifyOtp = (value: any, index: any) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };
  const handleSubmitVerifyMobileOtp = async () => {
    if (otp.length < 6) {
      return ShowAlert("warning", "Fill all the fields");
    }
    if (otp.length === 6) {
      try {
        setVerifyOtpLoading(true);
        let parsedTxnId;
        let transactionId = localStorage.getItem("txnId");
        if (transactionId) {
          parsedTxnId = JSON.parse(transactionId);
        }

        let response = await verifyMobileOtpAction({
          otp: otp.join(""),
          txnId: parsedTxnId.txnId,
        });
        setAccounts(response.data.mobileLinkedHid);
        setStatus({
          termsConditionsStatus: false,
          generateMobileOtpStatus: false,
          VerifyMobileOtpStatus: false,
          selectAccountStatus: true,
        });
        setVerifyOtpLoading(false);
        return ShowAlert("success", "OTP Verified Successfully");
      } catch (error: any) {
        setVerifyOtpLoading(false);
        return ShowAlert("warning", error.response.data.message);
      }
    }
  };

  const handleChangeMobileOtp = (value: string) => {
    setMobileNumber(value);
  };
  console.log(mobileNo, "Check Mobile.");

  const handleSubmitMobileOtp = async () => {
    if (mobileNo.toString().length < 10) {
      return ShowAlert("warning", "Mobile No should be of minimum 10 digit.");
    }
    try {
      setLoading(true);
      const response = await generateLoginMobileOtpAction({
        mobile: mobileNo.toString(),
      });
      localStorage.setItem("txnId", JSON.stringify(response.data));
      setStatus((prev) => ({
        ...prev,
        termsConditionsStatus: false,
        generateMobileOtpStatus: false,
        VerifyMobileOtpStatus: true,
        selectAccountStatus: false,
      }));
      setLoading(false);
      return ShowAlert("success", "Otp sent to your registered mobile no.");
    } catch (error: any) {
      setLoading(false);
      return ShowAlert("warning", error.response.data.message);
    }
  };

  const handleResenedLoginOtp = async () => {
    try {
      let parsedTxnId;
      let transactionId = localStorage.getItem("txnId");
      if (transactionId) {
        parsedTxnId = JSON.parse(transactionId);
      }
      const payload = {
        authMethod: "AADHAAR_OTP",
        txnId: parsedTxnId?.txnId,
      };
      await resenedLoginOtpAction(payload);
      return ShowAlert("success", "Otp sent to Your Mobile Number");
    } catch (error: any) {
      ShowAlert("warning", error.response.data.message);
    }
  };
  const handleSelectedAccount = async (selectedAcc: any) => {
    try {
      let parsedTxnId;
      let transactionId = localStorage.getItem("txnId");
      if (transactionId) {
        parsedTxnId = JSON.parse(transactionId);
      }
      const userAccDetails = {
        benId: benId,
        healthId: selectedAcc?.healthIdNumber,
        txnId: parsedTxnId?.txnId,
      };

      await authorizedTokenForLoginAction(userAccDetails);
      // console.log(authorized, "authorized");

      let getProfile = await getAbhaProfileAction(benId);
      // console.log(getProfile, "getProfile");

      const abhaDetails = {
        abhaLinked: true,
        beneficiaryId: benId,
        abhaMobileNumber: getProfile?.data?.mobile,
        abhaHealthId: getProfile?.data?.healthId,
        abhaHealthNumber: getProfile?.data?.healthIdNumber,
        abhaAddress: getProfile?.data?.healthId,
        txnId: parsedTxnId?.txnId,
      };
      await saveAbhaDetailsAction(abhaDetails);
      ShowAlert(
        "success",
        "Your ABHA account linked with Pococare succcessfully"
      );
      setStatus({
        termsConditionsStatus: false,
        generateMobileOtpStatus: false,
        VerifyMobileOtpStatus: false,
        selectAccountStatus: false,
      });
      window.location.reload();
    } catch (error: any) {
      ShowAlert("warning", error.response.data.message);
    }
  };
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleCheckboxChange = () => {
    setIsChecked(isChecked ? false : true); // Toggle the isChecked state
  };
  const handleNext = () => {
    setStatus({
      termsConditionsStatus: false,
      generateMobileOtpStatus: true,
      VerifyMobileOtpStatus: false,
      selectAccountStatus: false,
    });
  };

  return (
    <div>
      {status.termsConditionsStatus && (
        <Box>
          <Button
            bg="#008470"
            width={{ base: "100%" }}
            color="white"
            _hover={{
              bg: "#008470",
            }}
            onClick={onOpen}
          >
            <div style={{ padding: "5px" }}>
              <MdIntegrationInstructions />
            </div>
            Link Using Mobile Number
          </Button>
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size={{ base: "sm", xl: "md" }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader textAlign="left">
                Agree Terms and Conditions
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody minW={400}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  textAlign="center"
                  // minWidth="00"
                >
                  <Box width="100%">
                    <TermsAndConditions
                      handleCheckboxChange={handleCheckboxChange}
                      isChecked={isChecked}
                    />
                  </Box>
                  <Button
                    onClick={handleNext}
                    bg="#008470"
                    color="white"
                    mr={3}
                    mt={3}
                    isDisabled={!isChecked}
                    _hover={{
                      bg: "#008470",
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
      {status.generateMobileOtpStatus && (
        <GenerateMobileOtp
          mobileNo={mobileNo}
          loading={loading}
          setStatus={setStatus}
          handleChangeMobileOtp={handleChangeMobileOtp}
          handleSubmitMobileOtp={handleSubmitMobileOtp}
        />
      )}
      {status.VerifyMobileOtpStatus && (
        <VerifyMobileOtp
          setStatus={setStatus}
          handleResenedLoginOtp={handleResenedLoginOtp}
          handleSubmitVerifyMobileOtp={handleSubmitVerifyMobileOtp}
          handleChangeVerifyOtp={handleChangeVerifyOtp}
          verifyOtpLoading={verifyOtpLoading}
          otp={otp}
        />
      )}
      {status.selectAccountStatus && (
        <SelectAccount
          setStatus={setStatus}
          handleSelectedAccount={handleSelectedAccount}
          accounts={accounts}
        />
      )}
    </div>
  );
};

export default LinkAbhaWithPococare;
