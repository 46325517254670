import React from 'react'
import { BenDataProps } from '../../../Helper/BenInterface'
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import emergencyStyle from "./ProfileEmergencyContactDetails.module.css"

const ProfileEmergencyContactDetails:React.FC<{data:BenDataProps}> = ({data}) => {
    const navigate = useNavigate();
    const { emergencyContacts } = data;
    const [mobilePhoneSmall] = useMediaQuery("(max-width: 400px)");
    const handleAddEmergencyDetails = () => {
        navigate(`/addBenFullDetails/${data._id}/3`);
    };
    const handleClick = () => {
        navigate(`/addBenFullDetails/${data._id}/3`);
    };
  return (
    <>
          <Box className={emergencyStyle.emergencyWrapper}>
              <Box className={emergencyStyle.emergency_container}>
                  <Box>
                      <Text py={"5px"} className={emergencyStyle.subTitle}>
                          Emergency Contact
                      </Text>
                  </Box>
                  {emergencyContacts.length < 1 ? (
                      <Box>
                          <Box
                              onClick={handleAddEmergencyDetails}
                              className={emergencyStyle.emergencyCard}
                          >
                              <AiOutlinePlus className={emergencyStyle.addEmergencyDet} />
                              <Text className={emergencyStyle.addEmergencyDet}>
                                  Add Emergency Contact
                              </Text>
                          </Box>
                      </Box>
                  ) : (
                      emergencyContacts.map((el, i) => {
                          return (
                              <Box key={i}>
                                  <Box className={emergencyStyle.profileDetails_Bot_child}>
                                      <Box className={emergencyStyle.profileDetails_Bot_left}>
                                          <Box>
                                              <Flex align={"center"} gap={"15px"}>
                                                  <Text className={emergencyStyle.Title}>
                                                      {el.fullName}
                                                  </Text>
                                                  {el.isPrimary === true && (
                                                      <Text className={emergencyStyle.isPrimary}>
                                                          Primary
                                                      </Text>
                                                  )}
                                              </Flex>
                                              <Text color={"#6F6F6F"}>{el.relationshipToBeneficiary}</Text>
                                          </Box>
                                      </Box>
                                      <Box className={emergencyStyle.profileDetails_Bot_right}>
                                          <Box className={emergencyStyle.contactDetails_Right_det}>
                                              <Flex align={"center"} gap={"10px"} color={"#6F6F6F"}>
                                                  <BsTelephone
                                                      className={
                                                          mobilePhoneSmall
                                                              ? emergencyStyle.hideDisplay
                                                              : emergencyStyle.showDisplay
                                                      }
                                                      fontSize="20px"
                                                  />
                                                  <Text className={emergencyStyle.contact_Number}>
                                                      {mobilePhoneSmall
                                                          ? `Mobile:${el.mobile?.number || ""}`
                                                          : el.mobile?.number || ""}
                                                  </Text>
                                              </Flex>
                                              <Box>
                                                  <Flex align={"center"} gap={"10px"} color={"#6F6F6F"}>
                                                      <AiOutlineWhatsApp
                                                          className={
                                                              mobilePhoneSmall
                                                                  ? emergencyStyle.hideDisplay
                                                                  : emergencyStyle.showDisplay
                                                          }
                                                          fontSize="20px"
                                                      />
                                                      <Text className={emergencyStyle.contact_Number}>
                                                          {mobilePhoneSmall
                                                              ? `WhatsApp:${el.whatsappMobile?.number || ""}`
                                                              : el.whatsappMobile?.number || ""}
                                                      </Text>
                                                  </Flex>
                                              </Box>
                                          </Box>
                                      </Box>
                                  </Box>
                              </Box>
                          );
                      })
                  )}
                  {emergencyContacts.length > 0 && (
                      <Box className={emergencyStyle.editIcon}>
                          <HiOutlinePencilSquare
                              onClick={handleClick}
                              color={"#008470"}
                              fontSize={"23px"}
                          />
                      </Box>
                  )}
              </Box>
          </Box>
    </>
  )
}

export default ProfileEmergencyContactDetails
