import { getCompanyByName } from "../../../../http";

export const getCompanyAction = async (payload:any) => {
    
  try {
    const { data } = await getCompanyByName(payload);
    return data;
  } catch (error:any) {
    throw error;
  }
};

