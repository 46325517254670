import React, { useState } from "react";
import benStyle from "./AddBeneficiary.module.css";
import {
  Box,
  Button,
  ChakraProvider,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Stack,
  Text,
  extendTheme,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
// import beneficiariesDetailsSaveAction from "../../Action/AddBeneficiary/addBeneficiaryFirst-Action";
// import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBeneficiaryData } from "../AddBeneficiaryMinDetails/Action/getBeneficiaries";
import { useSelector } from "react-redux";
import { BenDataProps } from "../../Helper/BenInterface";
interface SubscriberData {
  subscriber: BenDataProps
}
// import { getBeneficiariesSummary } from "../../Action/GetBeneficiary/getBeneficiary-Action";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-28px)",
  backgroundColor: "white",
};
export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
            {
              ...activeLabelStyles,
            },
            label: {
              top: 2,
              left: 0,
              zIndex: 2,
              position: "absolute",
              color: "#6F6F6F",
              fontSize: "16px",

              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
});
const formData = [
  { rel: "Spouse", name: "", index: 0, selected: false, id: 0 },
  { rel: "Daughter", name: "", index: 1, selected: false, id: 0 },
  { rel: "Son", name: "", index: 2, selected: false, id: 0 },
  { rel: "Father", name: "", index: 3, selected: false, id: 0 },
  { rel: "Mother", name: "", index: 4, selected: false, id: 0 },
  { rel: "Father-in-law", name: "", index: 5, selected: false, id: 0 },
  { rel: "Mother-in-law", name: "", index: 6, selected: false, id: 0 }
];
// const allBenData = [];
let daughterCount: number = 1;
let noOfDaughter: string;
let sonCount: number = 1;
let noOfSon: string;
const AddBeneficiary: React.FC = () => {
  // const dispatch = useDispatch();

  const subscriberData = useSelector((state: SubscriberData) => state.subscriber);
  const benData = useSelector((state: any) => state.beneficiary);
  const userData: any = useSelector((state: any) => state.user);
  const [disableCheck, setDisableCheck] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState(formData);
  const [disable, setDisable] = useState(true);

  //if ben selected Daughter it will collect the count of the daughters dynamically
  const handleSelectChild = (value: string) => {
    if (disableCheck) {
      return
    }
    if (value === "daughter") {
      daughterCount = daughterCount + 1;
      noOfDaughter = `Daughter_${daughterCount}`;
      setData([
        ...data,
        {
          rel: noOfDaughter,
          name: "",
          index: data.length,
          selected: false,
          id: 0,
        },
      ]);
    } else {
      sonCount = sonCount + 1;
      noOfSon = `Son_${sonCount}`;
      setData([
        ...data,
        {
          rel: noOfSon,
          name: "",
          index: data.length,
          selected: false,
          id: 0,
        },
      ]);
    }
  };
  type CheckBoxState = {
    [key: string]: boolean;
  };
  const [selectedCheckBox, setSelectedCheckBox] = useState<CheckBoxState>({});
  const handleClick = async () => {
    console.log('subscriberData:', subscriberData);
    const subscriber = {
      fullName: subscriberData?.personalInfo?.fullName,
      email: subscriberData?.personalInfo?.email,
      id: subscriberData?._id,//here i need first ben Id to update address details from 3rd screen
      mobile: {
        countryCode: subscriberData?.personalInfo?.mobile?.countryCode,
        number: subscriberData?.personalInfo?.mobile?.number
      },
      relationshipToSubscriber: subscriberData?.personalInfo?.relationshipToSubscriber,
      city: subscriberData?.address[0]?.city,
      pineCode: subscriberData?.address[0]?.pinCode
    };

    const benData = {

      mobile: {
        countryCode: "",
        number: "",
      },

      fullName: "",
      relationshipToSubscriber: "",

      city: "",
      pinCode: "",

    };

    interface BeneficiaryData {
      mobile?: {
        number: string,
        countryCode: string
      }

      fullName: string;
      relationshipToSubscriber: string;
      progress: number;
      city: string,
      pinCode: string,
      index: number
    }
    let beneficiariesUpdateData: BeneficiaryData[] = [];
    benData.fullName = subscriber.fullName
    benData.relationshipToSubscriber = subscriber.relationshipToSubscriber;
    const subscriberPayload = {
      id: subscriber.id,
      progress: 1,
      ...benData, // Copy the other properties from benData
      index: 0,

      mobile: {
        number: subscriber?.mobile?.number?.toString(), // Update mobile number
        countryCode: subscriber?.mobile?.countryCode?.toString(), // Update country code
      },
      email: subscriber.email,
      city: subscriber.city,
      pinCode: subscriber?.pineCode?.toString()

    };
    beneficiariesUpdateData.push(subscriberPayload);
    let count = 1;
    data?.forEach((el, i) => {

      if (el.name && el.name.length > 0) {

        benData.fullName = el.name;
        benData.relationshipToSubscriber = el.rel;
        beneficiariesUpdateData.push({
          progress: 1, //need to include in new system
          ...benData,
          index: count++
        });
      }
    });

    localStorage.setItem(
      "beneficiariesData",
      JSON.stringify(beneficiariesUpdateData)
    );
    if (beneficiariesUpdateData.length) {
      navigate("/addBenMinDetails");
      setData([]);
    }
  };
  interface DataItem {
    name: string;
    rel: string; // Assuming `rel` is a string, adjust the type if it's different
    index: number;
  }
  const checkFormDataIsEmpty = (data: DataItem[]) => {
    let checkoutOneField = false;
    data.forEach((el) => {
      if (el.name && el.name.length > 2) {
        setDisable(false);
        checkoutOneField = true;
      }
      if (checkoutOneField === false) {
        setDisable(true);
      }
    });
  };

  const unSelectedRemoveName = (data: DataItem[], i: number) => {
    data.forEach((el) => {
      if (el.index === i) {
        if (el.name && el.name.length > 0) {
          el.name = "";
        }
      }
    });
  };

  const checkValuable = (data: any) => {
    let result = true;
    for (let x in data) {
      if (data[x] === true) {
        result = false;
      }
    }
    return result;
  };

  useEffect(() => {
    const checkboxOptions = [
      { name: "Spouse", index: 0 },
      { name: "Daughter", index: 1 },
      { name: "Son", index: 2 },
      { name: "Father", index: 3 },
      { name: "Mother", index: 4 },
      { name: "Father-in-law", index: 5 },
      { name: "Mother-in-law", index: 6 }
    ];

    if (userData?.numberOfBenAllowed) {
      // Initialize a counter
      let trueCount = 0;

      // Iterate over the keys of the object
      for (let key in selectedCheckBox) {
        // Check if the value is true
        if (selectedCheckBox[key] === true) {
          trueCount++;
        }
      }

      console.log(trueCount)
      console.log(selectedCheckBox)

      if (userData.numberOfBenAllowed - 1 <= trueCount) {
        setDisableCheck(true)
      } else {
        setDisableCheck(false)
      }
    }

    for (const option of checkboxOptions) {
      const { name, index } = option;
      if (selectedCheckBox?.[name]) {
        checkFormDataIsEmpty(data);
      } else {
        unSelectedRemoveName(data, index);
      }
    }
    if (!selectedCheckBox[noOfDaughter]) {
      let findIndex: number = -1;
      data.filter((el) => {
        if (el.rel === noOfDaughter) {
          findIndex = el.index;
        }
        return findIndex;
      });
      if (findIndex !== -1) {
        unSelectedRemoveName(data, findIndex);
      }
    }
    if (!selectedCheckBox[noOfSon]) {
      let findIndex: number = -1;
      data.filter((el) => {
        if (el.rel === noOfSon) {
          findIndex = el.index;
        }
        return findIndex;
      });
      if (findIndex !== -1) {
        unSelectedRemoveName(data, findIndex);
      }
    }
    if (checkValuable(selectedCheckBox)) {
      setDisable(true);
    }
    // eslint-disable-next-line
  }, [selectedCheckBox, data]);

  useEffect(() => {
    // console.log("found",userData?.addBeneficiaryAllowed);
    if (benData.length < 1) {
      return navigate("/");
    }
    if ((userData?.addBeneficiaryAllowed === false)) {
      return navigate("/dashboard");//if suppose persitent user enterd this page it will rediract
    }
    //need to do some changes as per latest api data structure
    const checkBenData = async () => {

      const response = await getBeneficiaryData(subscriberData.subscriberId);
      if (response.code === 200) {

        response.data.forEach((elem: any, i: number) => {

          if (
            i !== 0 &&
            elem?.personalInfo?.mobile?.number &&
            elem?.personalInfo?.mobile?.number?.toString().length > 0
          ) {
            return navigate("/");
          }
          if (i !== 0 && elem?.address && elem?.address?.length > 0) {
            return navigate("/");
          }

        });
      }

    }
    checkBenData()
    // eslint-disable-next-line
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Box className={benStyle.ben_wrapper}>
        <Box className={benStyle.ben_container}>
          <Box className={benStyle.ben_section}>
            <Box className={benStyle.ben_banner_image}>
              <Image src="/Images/pococare_login.jpg" alt="banner_Image" />
            </Box>
            <Box className={benStyle.benAddForm}>
              <Box>
                {
                  userData?.numberOfBenAllowed ? <Text className={benStyle.heading}>Family members you want to add as beneficiaries (upto {userData?.numberOfBenAllowed - 1} members)</Text> : <Text className={benStyle.heading}>
                    Family members you want to add as beneficiaries
                  </Text>
                }
              </Box>
              <Flex align={"center"} justify={"center"}>
                <Stack
                  spacing={8}
                  mx={"auto"}
                  width={"100%"}
                  // border={"1px"}
                  py={5}
                >
                  <Box>
                    <Stack spacing={4}>
                      <Box className={benStyle.formData}>
                        {data.map((el) => {
                          return (
                            <Box key={el.rel}>
                              <Checkbox
                                border='black'
                                size="lg"
                                colorScheme="green"
                                mb={".875rem"}
                                isChecked={selectedCheckBox[el.rel]}
                                onChange={(e) => {
                                  noOfDaughter = el.rel;
                                  noOfSon = el.rel;
                                  setSelectedCheckBox({
                                    ...selectedCheckBox,
                                    [el.rel]: e.target.checked,
                                  });
                                }}

                                disabled={disableCheck && !selectedCheckBox[el.rel]}
                              >
                                {el.rel}
                              </Checkbox>
                              <FormControl variant="floating" id="name">
                                <Input
                                  boxShadow="green.400"
                                  autoFocus={true}
                                  focusBorderColor="green.400"
                                  fontSize={"18px"}
                                  name={"relation"}
                                  height={"55px"}
                                  type="text"
                                  bg="white"
                                  placeholder=" "
                                  border={selectedCheckBox[el.rel] ? '2px solid black' : '1px solid'}
                                  isDisabled={!selectedCheckBox[el.rel]}
                                  value={
                                    !selectedCheckBox[el.rel]
                                      ? ""
                                      : data[el.index].name
                                  }
                                  onChange={(e) => {
                                    console.log(e.target.value.length > 0)
                                    let nameWthOnlyLetters =
                                      e.target.value.length > 1
                                        ? e.target.value
                                        : e.target.value.replace(
                                          /[^A-Za-z]/g,
                                          "");
                                    let coyOfRelationshipsFixedList = [...data];
                                    coyOfRelationshipsFixedList[el.index].name =
                                      nameWthOnlyLetters;
                                    coyOfRelationshipsFixedList[
                                      el.index
                                    ].selected = true;
                                    setData(coyOfRelationshipsFixedList);
                                    checkFormDataIsEmpty(data);
                                  }}
                                />
                                <FormLabel>Full Name</FormLabel>
                              </FormControl>
                            </Box>
                          );
                        })}
                      </Box>

                      <Box className={benStyle.addChild}>
                        <Text className={benStyle.referenceStyle}>
                          Add another Child:
                        </Text>
                        <Box className={benStyle.addChild}>
                          <Box>
                            <Text
                              onClick={() => handleSelectChild("daughter")}
                              className={benStyle.titleStyle}
                            >
                              Daughter
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              onClick={() => handleSelectChild("son")}
                              className={benStyle.titleStyle}
                            >
                              Son
                            </Text>
                          </Box>
                        </Box>
                      </Box>

                      <Stack
                        spacing={10}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems="center"
                      >
                        <Button
                          w={"40%"}
                          borderRadius={"46px"}
                          bg={"#008470"}
                          color={"white"}
                          _hover={{
                            bg: "#008470",
                          }}
                          isDisabled={disable}
                          onClick={handleClick}
                        >
                          Next
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default AddBeneficiary;
