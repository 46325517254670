import { verifyOtp } from "../../../../http";
import { LoginOtp } from "../../Login/Action/loginAction";
import * as userSlice from "../../../../Redux/User-slice/User-slice";
export interface SendOtp extends LoginOtp {
  otp:string
}

export const verifyOtReq = async (payload: any,dispatch:any) => {
    
  try {
    
    const { data } = await verifyOtp(payload)
    localStorage.setItem('userData',JSON.stringify(data))
    dispatch(userSlice.saveUser(data));

    return data

  } catch (error:any) {
    throw error
  }
};


// import { addBeneficiaries } from "../../../http"

