import { getBeneficiaryData } from "./getBeneficiaries";

export const benRouting = async (subId:string,navigate:any) => {
  // console.log('subId:', subId);
  const response = await getBeneficiaryData(subId);
  // console.log('response:', response);
  let hasIncomplete=false;
  if(response.data.length>1){
    if (response.code === 200) {
      for (const elem of response.data) {       
        if (
          elem.personalInfo?.mobile?.number &&
          elem.personalInfo?.mobile?.number?.toString().length > 0 && elem?.address?.length > 0 
        ) {
          hasIncomplete = true;
             
        } else {
          hasIncomplete = false;
          break
        }
      }
    }

    
      // console.log('hasIncompletein:Addmin', hasIncomplete)
      if(hasIncomplete){
           return navigate("/dashboard");
      }
    } else {
      // alert(response.message);
    }
  }
 
