import { createSlice } from "@reduxjs/toolkit";
const initialState = {    
};
export const userSlice = createSlice({
    name: "user",
    initialState:initialState,
    reducers: {
        saveUser: (state, action) => {        
            return {...state,...action.payload}
        },
        
        removeUser: (state) => {
            state = [];
            return state;
        },
    }
})
export const { saveUser, removeUser } = userSlice.actions;
export default userSlice.reducer;