

//eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import medicalStyle from "./ProfileMedicalDetails.module.css"
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { BenDataProps, MedicalInfo } from '../../../Helper/BenInterface';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { useNavigate } from 'react-router';

const ProfileMedicalDetails: React.FC<{ data: BenDataProps }> = ({ data }) => {
    let SaSToken = localStorage.getItem("sasToken");
    const [showDetail, setShowDetail] = useState(false);
    const navigate = useNavigate()
    const { medicalInfo } = data;
    let medicalData: any;

    if (medicalInfo) {
        const {
            currentMedications,
            pastMedicalRecords,
            allergiesToMedications,
            recentHospitalizations,
            chronicConditions,
            generalInfo,
        } = medicalInfo;
        medicalData = [
            {
                title: "Blood Group",
                description: generalInfo?.bloodGroup || "",
            },
            {
                title: "Current Medication",
                description: currentMedications[0]?.description || "",
                link: currentMedications[0]?.docsUrl?.length ? currentMedications[0]?.docsUrl : "",
            },
            {
                title: "Allergies To Medication",
                description: allergiesToMedications[0]?.description || "",
            },
            {
                title: "Past Medical History",
                description: pastMedicalRecords[0]?.description || "",
                link: pastMedicalRecords[0]?.docsUrl?.length ? pastMedicalRecords[0]?.docsUrl : "",
            },
            {
                title: "Recent Hospitalization",
                description: recentHospitalizations[0]?.description || "",
                link: recentHospitalizations[0]?.doctorsInformation?.length ? recentHospitalizations[0].doctorsInformation : "",
            },
            {
                title: "Differently Abled",
                description: generalInfo?.differentlyAble || "",

            },
            {
                title: "Chronic Condition",
                description: chronicConditions.join(",") || "",
            },
            {
                title: "Mobility Status",
                description: generalInfo?.mobilityStatus || "",
            },
        ];
    }
    const checkDatesEmpty = (medicalData: MedicalInfo) => {
        if (medicalData?.currentMedications?.length === 0 && medicalData.recentHospitalizations.length === 0 && medicalData.pastMedicalRecords.length === 0 && medicalData.allergiesToMedications.length === 0 && medicalData.chronicConditions.length === 0 && !medicalData.generalInfo) {
            setShowDetail(true)
        } else {
            setShowDetail(false)
        }
    };

    const handleEdit = () => {
        return navigate(`/addBenFullDetails/${data._id}/4`);
        // sessionStorage.setItem("stepNumber", 4);
    };

    const handleMedical = () => {
        navigate(`/addBenFullDetails/${data._id}/4`);
    };
    // here i removed empty dependencies from useEffect if any re-render happen need to change this

    useEffect(() => {
        checkDatesEmpty(medicalInfo);
    });
    return (
        <>
            <Box className={medicalStyle.medical_wrapper}>
                <Box className={medicalStyle.medical_container}>
                    <Box>
                        <Text className={medicalStyle.subTitle}>Medical Profile</Text>
                    </Box>
                    {showDetail ? (
                        <Box>
                            <Box
                                onClick={handleMedical}
                                className={medicalStyle.medicalCard}
                            >
                                <AiOutlinePlus className={medicalStyle.addMedicalDet} />
                                <Text className={medicalStyle.addMedicalDet}>
                                    Add Medical Info
                                </Text>
                            </Box>
                        </Box>
                    ) : (
                        <SimpleGrid columns={1} gap={"5px"}>
                            {medicalData && medicalData?.map((item: any, index: number) => {
                                return (
                                    <Box
                                        key={index}
                                        marginTop={"10px"}
                                        className={
                                            (index + 1) % 2 === 0
                                                ? medicalStyle.withBorder
                                                : medicalStyle.withoutBorder
                                        }
                                        display={{ md: "flex", base: "block" }}
                                        justifyContent={"space-between"}
                                    >
                                        <Box display={"flex"} flexDir={"column"} gap={"3px"}>
                                            <Text fontWeight={"600"}>{item.title}</Text>
                                            {item.link ? (
                                                <a
                                                    style={{
                                                        padding: "7px",
                                                        background: "#e2e8f0",
                                                        borderRadius: "8px",
                                                        marginTop: "10px",
                                                        color: "blue",
                                                        fontWeight: "500",
                                                        width: "140px",
                                                    }}
                                                    href={item.link + "?" + SaSToken}
                                                    target="blank"
                                                >
                                                    Open Document
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                        </Box>
                                        <Text
                                            width={"70%"}
                                            textAlign={"left"}
                                            color={"#1C2B2F"}
                                            fontWeight={"500"}
                                        >
                                            {item.description || "No  "}
                                        </Text>
                                    </Box>
                                );
                            })}
                            {medicalInfo && Object.keys(medicalInfo).length > 1 && (
                                <Box className={medicalStyle.editIcon}>
                                    <HiOutlinePencilSquare
                                        onClick={handleEdit}
                                        color={"#008470"}
                                        fontSize={"23px"}
                                    />
                                </Box>
                            )}
                        </SimpleGrid>
                    )}
                </Box>

            </Box>
        </>
    )
}

export default ProfileMedicalDetails