import React, { useEffect, useState } from "react";
import CreateAbhaIdByAadhaar from "./CreateNewABHAId/CreateAbhaIdByAadhaar";
import AbhaDetails from "./AbhaDetails";
import LinkAbhaWithPococare from "./LinkAbhaByMobileLogin/LinkAbhaWithPococare";
import { useParams } from "react-router-dom";
import {
  getAbhaDetailsFromPococareAccAction,
  getBenCareContextsAction,
  getPocoAbhaDocumentAction,
  setPocoMedicalDocumentAction,
} from "../../Actions/beneficiaryDetails.action";
import { ShowAlert } from "../../../../GlobalComponents/ShowAlert/ShowAlert";
import { Box, Button, HStack, Skeleton, Stack } from "@chakra-ui/react";
import AbhaStyles from "./AbhaDetails.module.css";
import { GrFormPrevious } from "react-icons/gr";
import LinkAndLoginByAdhaar from "./LinkAbhaByAdhaarLogin/LinkAndLoginByAadhaar";
import { MdIntegrationInstructions } from "react-icons/md";
import CreateAbhaInfo from "./Shared/CreateAbhaInfo";
import LinkAbhaInfo from "./Shared/LinkAbhaInfo";

const AbhaIntegration = ({ changeStep }: any) => {
  const [abhaDetails, setAbhaDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pocoMedicalDocDetails, setPocoMedicalDocDetails] = useState<
    any[] | null
  >(null);
  const [careContextDetails, setCareContextsDetails] = useState<any[] | null>(
    null
  );
  const [linkAbhaButton, setLinkAbhaButton] = useState(true);
  const [status, setStatus] = useState({
    linkAbhaStatus: false,
    createAbhaStatus: true,
  });
  const { benId } = useParams();
  // FETCH ABHA DETTAILS OF THE BENEFICIARY.
  const fetchAbhaDetails = async (benId: any) => {
    try {
      setLoading(true);
      const response = await getAbhaDetailsFromPococareAccAction(benId);
      console.log(response.data, "getAbhaDetailsFromPococareAccAction");

      const response2 = await setPocoMedicalDocumentAction(benId);
      console.log(response2, "response2 === setPocoMedicalDocumentAction");

      const response3 = await getPocoAbhaDocumentAction(benId);
      console.log(response3, "response3 >> getPocoAbhaDocumentAction");

      const extractedData = response?.data; // Extract specific data from the response;
      setAbhaDetails(extractedData);
      if (response2?.data.message === "Success") {
        setPocoMedicalDocDetails(response3.data);
        setLoading(false);
        console.log(pocoMedicalDocDetails, "pocoMedicalDocDetails");
      }
    } catch (error: any) {
      setLoading(false);
      ShowAlert(
        "warning",
        error.response?.data?.message || "Error fetching Abha details"
      );
    }
  };

  // FETCH BENEFICIARY CARE CONTEXTS DETAILS.
  const fetchBenCareContexts = async (benId: any) => {
    try {
      const response = await getBenCareContextsAction(benId);
      console.log(
        response.data,
        "fetchBenCareContexts......********Response.data*********"
      );
      setCareContextsDetails(response.data ? response.data : []);
    } catch (error: any) {
      console.log(error, "setCareContextsDetails", setCareContextsDetails);
      ShowAlert(
        "warning",
        error.response?.data?.message ||
          "Error fetching Abha care contexts details"
      );
    }
  };

  // HANDLING ABHA LINKING BUTTON HERE.
  const handleLinkAbhaBtn = () => {
    setLinkAbhaButton(false);
    setStatus({
      linkAbhaStatus: true,
      createAbhaStatus: false,
    });
  };

  const hanldeLinkAbha = () => {
    setStatus((prev) => ({
      ...prev,
      linkAbhaStatus: true,
      createAbhaStatus: false,
    }));
  };

  // CREATE ABHA CRATE CONTEXTS HANDLE OPTION.
  const handleCreateAbha = () => {
    setStatus((prev) => ({
      ...prev,
      linkAbhaStatus: false,
      createAbhaStatus: true,
    }));
  };

  useEffect(() => {
    const fetchDataAbha = async () => {
      await fetchAbhaDetails(benId);
    };
    const fetchCareContextsDetails = async () => {
      await fetchBenCareContexts(benId);
    };
    // eslint-disable-next-line
    fetchDataAbha();
    fetchCareContextsDetails();
    // eslint-disable-next-line
  }, [benId]);

  return (
    <div>
      {loading && (
        <Stack>
          <Skeleton height="220px" width="942px" />
          <Skeleton height="150px" width="942px" />
          <Skeleton height="150px" width="942px" />
        </Stack>
      )}
      {abhaDetails && (
        <AbhaDetails
          abhaDetails={abhaDetails}
          pocoMedicalDocDetails={pocoMedicalDocDetails}
          careContextDetails={careContextDetails}
          fetchBenCareContexts={fetchBenCareContexts}
        />
      )}
      {!loading && !abhaDetails && (
        <>
          <div>
            <div>
              <div>
                <div>
                  {!status.linkAbhaStatus && <CreateAbhaInfo />}
                  {status.linkAbhaStatus && <LinkAbhaInfo />}
                </div>
                <div className={AbhaStyles.abhaIntegration}>
                  {" "}
                  {linkAbhaButton && (
                    <Box paddingLeft={1} paddingRight={1}>
                      {" "}
                      <Button
                        bg="#008470"
                        width={{ base: "100%" }}
                        color="white"
                        _hover={{
                          bg: "#008470",
                        }}
                        onClick={handleLinkAbhaBtn}
                      >
                        <div style={{ padding: "5px" }}>
                          <MdIntegrationInstructions />
                        </div>
                        Link existing ABHA ID
                      </Button>
                    </Box>
                  )}
                  {status.linkAbhaStatus && (
                    <div
                      className={AbhaStyles.LinkAndCreate}
                      onClick={hanldeLinkAbha}
                    >
                      <LinkAbhaWithPococare
                        setIntegrateModalStatus={setStatus}
                      />
                    </div>
                  )}
                  {status.linkAbhaStatus && (
                    <div
                      className={AbhaStyles.LinkAndCreate}
                      onClick={hanldeLinkAbha}
                    >
                      <LinkAndLoginByAdhaar />
                    </div>
                  )}
                  {status.createAbhaStatus && (
                    <div
                      className={AbhaStyles.LinkAndCreate}
                      onClick={handleCreateAbha}
                    >
                      <CreateAbhaIdByAadhaar
                        setIntegrateModalStatus={setStatus}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <HStack marginTop={"30px"} justifyContent={"space-between"}>
        <GrFormPrevious
          style={{
            fontSize: "40px",
            padding: "10px",
            borderRadius: "50px",
            backgroundColor: "#cecece",
            color: "black",
            cursor: "pointer",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
          }}
          onClick={() => changeStep(-1)}
        />
      </HStack>
    </div>
  );
};

export default AbhaIntegration;
