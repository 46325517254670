export const calculateAge = (dateOfBirth: string) => {
  if (!dateOfBirth) {
    return "";
  }
  dateOfBirth = dateOfBirth.toString();
  if (dateOfBirth.length <= 3) {
    return dateOfBirth;
  } else {
    const today = new Date();
    let birthYear = dateOfBirth?.slice(-4);
    let currentYear = today.getFullYear();
    let age = currentYear - +birthYear;
    return age;
  }
};
