import React from "react";
import { Box } from "@chakra-ui/react";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";


interface FixedButtonProps{
    direction:string
    onClick:()=>void
}
const FixedButton: React.FC<FixedButtonProps> = ({ direction, onClick }) => {
    return (
        <Box>
            <Box
                borderRadius="50%"
                border={"1px solid #5B6C78"}
                width="50px"
                height="50px"
                fontSize="24px"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                // _hover={{ bg: "#E961AB" }}
                // backgroundColor="#E961AB"
                color="#5B6C78"
                onClick={onClick}
            >
                {direction === "up" ?<ArrowUpIcon/>: <ArrowDownIcon/> }
            </Box>
        </Box>
    );
};

export default FixedButton;
