// import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from 'leaflet';

const customIcon = new L.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
});

function LocationMarker({ pos, onMove }: any) {
    return (
        <Marker
            position={pos}
            // @ts-ignore
            draggable
            autoPan
            icon={customIcon} // Set the custom icon
            eventHandlers={{
                moveend: (event: any) => {
                    onMove([event.target.getLatLng().lat, event.target.getLatLng().lng]);
                }
            }}
        >
            {/* Popup with your message */}
            <Popup>
                <div>
                    <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>Drag me to change the location</p>
                </div>
            </Popup>
        </Marker>
    );
}

function LeafLetMap({ markerPos, setMarkerPos }: any) {
    // const [markerPos, setMarkerPos] = useState([51.505, -0.09]);

    return (
        <>
            <MapContainer
                // @ts-ignore
                center={markerPos}
                key={`${markerPos[0]}-${markerPos[1]}`}
                zoom={23}
                scrollWheelZoom={false}
                bounceAtZoomLimits={false}
                style={{
                    width: "90%",
                    margin: "auto"
                }}
            >
                <TileLayer
                    url={`https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en&gl=US`}
                    // @ts-ignore
                    attribution='<a href="http://www.pococare.com">Pococare</a>'
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                />
                <LocationMarker onMove={setMarkerPos} pos={markerPos} />
            </MapContainer>
            {/* <p>lat: {markerPos[0]}</p>
            <p>lng: {markerPos[1]}</p> */}
            {/* <div style={{ height: "25vh", backgroundColor: "lightgray" }} /> */}
        </>
    );
}

export default LeafLetMap;