import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Flex,
} from "@chakra-ui/react"; // Import necessary Chakra UI components
// import { benData, createBeneficiaries } from '../../../AddBeneficiaryMinDetails/Action/createBenficiaries';
import { useDispatch, useSelector } from 'react-redux';
import { BenDataProps } from '../../../../Helper/BenInterface';
import { CreatePayload, createBeneficiaries } from '../../../AddBeneficiaryMinDetails/Action/createBenficiaries';
import { ShowAlert } from '../../../../GlobalComponents/ShowAlert/ShowAlert';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';
import addbenStyle from '../../DashBoard.module.css';


interface SubscriberData {
  subscriber: BenDataProps
}

interface AddBeneficiaryModalProps {
  isOpen: boolean;
  onClose: () => void;
  addBen: any;
  // onAddNewBeneficiary: (fullName: string, relationToSubscriber: string) => void;
}
interface AddBenDetails {

  fullName: string;
  relationshipToSubscriber: string;
  city: string;
  pinCode: string;
  phNumber: string
}

const AddBeneficiaryModal: React.FC<AddBeneficiaryModalProps> = ({
  isOpen,
  onClose,
  addBen
  // onAddNewBeneficiary,
}) => {

  const [isLoading, setLoading] = useState<boolean>(false) // eslint-disable-line
  const dispatch = useDispatch()
  const [details, setDetails] = React.useState<AddBenDetails>({
    fullName: '', // Provide an initial value for fullName
    relationshipToSubscriber: '', // Provide an initial value for relationToSubscriber
    city: '',
    pinCode: '',
    phNumber: ''
  })
  const phoneInputClass = classNames(addbenStyle.myPhoneInputClass);
  const containerClass = classNames(addbenStyle.myContainerClass);
  const subscriberData = useSelector((state: SubscriberData) => state.subscriber);

  const handleAddBeneficiary = async () => {

    setLoading(true)
    if (!details.fullName || details.fullName.trim() === "" || !details.relationshipToSubscriber || !details.city || details.city.trim() === "" || !details.pinCode || !details.phNumber || details.phNumber.length < 10) {
      setLoading(false)
      ShowAlert("warning", "Please fill the details properly");
      return
    }
    try {

      const newBenData: CreatePayload = {
        "data": [{
          ...details,
          pinCode: Number(details.pinCode),
          mobile: {
            countryCode: 91,
            number: +details.phNumber,
          }
        }],
        subscriberId: {
          "ID": subscriberData.subscriberId
        }
      }
      // console.log(newBenData)
      await createBeneficiaries(newBenData, dispatch)
      setLoading(false)
      await addBen()
      onClose();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        ShowAlert("warning", `${error?.response?.data?.message}`)
      }
      setLoading(false)
    }

  };

  const handlePhoneChange = (value: string) => {

    setDetails((prevDetails) => ({
      ...prevDetails,
      phNumber: value,
    }))

  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Beneficiary</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Full name</FormLabel>
            <Input
              value={details.fullName}
              onChange={(e) =>
                setDetails((prevDetails) => ({
                  ...prevDetails,
                  fullName: e.target.value,
                }))}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Relation To Subscriber</FormLabel>
            <Select
              onChange={(e) =>
                setDetails((prevDetails) => ({
                  ...prevDetails,
                  relationshipToSubscriber: e.target.value,
                }))}
              placeholder="Select Relationship to subscriber"
            // value={details.relationshipToSubscriber}
            >
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Sister">Sister</option>
              <option value="Brother">Brother</option>
              <option value="Spouse">Spouse</option>
              <option value="Grand Mother">Grand Mother</option>
              <option value="Grand Father">Grand Father</option>
              <option value="Father-in-law">Father-In-Law</option>
              <option value="Mother-in-law">Mother-In-Law</option>
              <option value="Others">Others</option>
            </Select>
          </FormControl>

          <FormControl mt={4}>
            <FormLabel htmlFor="email">Mobile</FormLabel>
            <PhoneInput
              country="in"
              onlyCountries={["in"]}
              autoFormat={true}
              disableCountryCode={true}
              placeholder="00000-00000"
              inputClass={phoneInputClass}
              containerClass={containerClass}
              onChange={handlePhoneChange}
            />
          </FormControl>
          <Flex mt={4} gap={'4'}>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                value={details.city}
                onChange={(e) =>
                  setDetails((prevDetails) => ({
                    ...prevDetails,
                    city: e.target.value,
                  }))}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Pincode</FormLabel>
              <Input
                type='number'
                value={details.pinCode}
                onChange={(e) => {
                  if (e.target.value.length <= 6) {
                    setDetails((prevDetails) => ({
                      ...prevDetails,
                      pinCode: e.target.value,
                    }));
                  }
                }}
              />
            </FormControl>

          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleAddBeneficiary} colorScheme="blue" mr={3}>
            Add
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal >
  );
};

export default AddBeneficiaryModal;
