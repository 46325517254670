import { raisedEmergencyWithQR } from "../../../http"

export const qrCall = async(benId:string, lat:string |null, lng:string|null) => {

    try{
        const emergencyCall = await raisedEmergencyWithQR(benId, lat, lng);
        return emergencyCall
    }catch(e:any){
        throw e;
    }
}
