import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from "@chakra-ui/react";
import { raiseEmergencyAction } from '../../Action/callAction';
import { ShowAlert } from '../../../../GlobalComponents/ShowAlert/ShowAlert';
// import { ShowAlert } from '../../../../GlobalComponents/Toastify/toastify';

interface AlertDialogCompoProps {
  isEmergencyOpen: boolean;
  onEmergencyClose: () => void;
  benId:string;
  mobile:string;
  lat:string|null;
  lng:string|null;
}

const AlertDialogCompo: React.FC<AlertDialogCompoProps>=({ isEmergencyOpen ,onEmergencyClose, benId, mobile, lat, lng}) => {
  
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const HandleEmergencyClick = async() => {
    if(!mobile){
      ShowAlert('warning','Please update mobile number to account')
    }
    try{
      let res = await raiseEmergencyAction(benId,mobile,lat,lng)
      // pets.includes('cat')
      if(res.data.message.includes('ticket already created ticketId')){
        ShowAlert('success','Ticket has already created.')
      }else{

        ShowAlert('success','Please wait, Our team will reach out to you soon!')
      }
      onEmergencyClose()
    }catch(e:any){
      console.log(e)
    }
  };

  return (
    <>
      <AlertDialog
        isOpen={isEmergencyOpen}
        leastDestructiveRef={cancelRef}
        onClose={onEmergencyClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Emergency Call
          </AlertDialogHeader>
          <AlertDialogBody>
            Is there an urgent need for an emergency call?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onEmergencyClose}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={HandleEmergencyClick} ml={3}>
              Make Call
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default AlertDialogCompo;
