import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { MdOutlineEdit } from "react-icons/md";
import { FaRegFileAlt } from "react-icons/fa";
import { LuFileX2 } from "react-icons/lu";
import InsuranceStyle from "./InsuranceInfo.module.css";
import { useState } from "react";
// @ts-ignore
import ReactFileReader from "react-file-reader";
// import { CalculateProfileCompletion } from "../CalculateProfileCompletion/CalculateProfileCompletion";
// import { updateBasicInfoData } from "../../Action/updateBasicInfoData/updateBasicInfoData";
// import { GetUploadURL } from "../../Action/GetUploadURL/GetUploadURL";
// import { UploadFile } from "../../Action/UploadFile/UploadFile";
import { AddIcon } from "@chakra-ui/icons";
import { ShowAlert } from "../../../../GlobalComponents/ShowAlert/ShowAlert";
import {
  addBenInsurance,
  deleteBenInsurance,
  getSingleBeneficiaryData,
  updateBenInsurance,
  uploadFile,
} from "../../Actions/beneficiaryDetails.action";

interface InsuranceInfoProps {
  changeStep: (step: number) => void;
  setData: (data: any) => void;
  data: any; // Replace 'any' with your actual data type
}

const InsuranceInfo: React.FC<InsuranceInfoProps> = ({
  changeStep,
  setData,
  data,
}) => {
  const [isEsic, setIsEsic] = useState(false); // eslint-disable-line
  const { isOpen, onOpen, onClose } = useDisclosure();
  //@ts-ignore
  const initialRef = useRef<HTMLInputElement | null>(null);
  //@ts-ignore
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  //@ts-ignore
  const finalRef = useRef<HTMLInputElement | null>(null);
  const [policyId, setPolicyId] = useState("");
  const [insurer, setInsurer] = useState("");
  const [tpaName, setTpaName] = useState("");
  const [policyType, setPolicyType] = useState("Corporate");
  const [editIndex, setEditIndex] = useState(0);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>();
  const [insuranceDocUrl, setInsuranceDocUrl] = useState("");
  const [uploading, setUploading] = useState(false); // eslint-disable-line
  const [isSmallerThan565] = useMediaQuery("(max-width: 565px)");
  const [isSmallerThan319] = useMediaQuery("(max-width: 319px)");
  let SaSToken = localStorage.getItem("sasToken");

  const openEdit = (data: any, i: number) => {
    setInsurer(data.nameOfInsurer);
    setPolicyId(data.policyId);
    setPolicyType(data.typeOfPolicy);
    setTpaName(data.tpaName);
    setIsEsic(data.isEsic);
    setInsuranceDocUrl(data.docUrl);
    setEditIndex(i);
    onEditOpen();
  };
  // const handleEditOrAddCancel = () => {
  //   setInsurer("");
  //   setPolicyId("");
  //   setPolicyType("");
  //   setTpaName("");
  // };
  const handleCancel = () => {
    setInsurer(data.nameOfInsurer);
    setPolicyId(data.policyId);
    setPolicyType(data.typeOfPolicy);
    setTpaName(data.tpaName);
    setInsuranceDocUrl(data.image);
    setIsEsic(data.isEsic);
    onEditClose();
  };

  const openDelete = (el: any, i: number) => {
    setDeleteIndex(i);
    onDeleteOpen();
  };

  const addInsurance = async () => {
    if (!policyId || policyId.trim() === "" || !insurer || insurer.trim() === "") {
      return ShowAlert("warning", "PolicyId , PolicyType , Insurer fields are required");
    }

    // if (
    //   !insuranceDocUrl ||
    //   insuranceDocUrl === "uploading" ||
    //   insuranceDocUrl === ""
    // ) {
    //   return ShowAlert("warning", "Please upload document");
    // }

    const insurancePayload = {
      policyId: policyId,
      docUrl: insuranceDocUrl,
      nameOfInsurer: insurer,
      expiry: "2021-12-31",
      tpaName: tpaName,
      typeOfPolicy: policyType || "Corporate",
      // isEsic: isEsic,
    };

    try {
      await addBenInsurance(data._id, insurancePayload);
      ShowAlert("success", "Insurance Added Successfully");
      let updatedData = await getSingleBeneficiaryData(data._id);
      setData(updatedData);
      setInsurer("");
      setPolicyId("");
      setInsuranceDocUrl("");
      setTpaName(""); // by d
      setPolicyType(""); // by d
      onClose();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        } else {
          return ShowAlert("warning", error.response.data.message);
        }
      }

      ShowAlert("warning", "Something went wrong, please try again later");
      console.log("error while adding insurance", error);
    }
    // window.location.reload();
  };

  const editInsurance = async () => {
    if (!policyId || !insurer || !policyType || policyId.trim() === "" || insurer.trim() === "" || policyType.trim() === "") {
      return ShowAlert("warning", "PolicyId , PolicyType , Insurer fields are required");
    }

    // if (!insuranceDocUrl || insuranceDocUrl === "uploading" || insuranceDocUrl === "") {
    //   return ShowAlert("warning", "Please upload document");
    // }

    const insurancePayload = {
      _id: data.insurancePolicies[editIndex || 0]._id,
      policyId: policyId,
      nameOfInsurer: insurer,
      docUrl: insuranceDocUrl,
      tpaName: tpaName,
      typeOfPolicy: policyType,
      // isEsic: isEsic,
    };
    try {
      await updateBenInsurance(data._id, insurancePayload);
      ShowAlert("success", "Insurance Updated Successfully");
      let updatedData = await getSingleBeneficiaryData(data._id);
      setData(updatedData);
      setInsurer("");
      setPolicyId("");
      setPolicyType("");
      setTpaName("");
      setInsuranceDocUrl("");
      onEditClose();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
      }
      ShowAlert("warning", "Something went wrong, please try again later");
      console.log("error while adding insurance", error);
    }
  };

  const deleteInsurance = async () => {
    let newInsuranceState: any[] = [];
    for (let i = 0; i < data.insurancePolicies.length; i++) {
      if (i !== deleteIndex) {
        newInsuranceState.push(data.insurancePolicies[i]);
      }
    }

    let insurances = newInsuranceState;

    try {
      await deleteBenInsurance(
        data._id,
        data.insurancePolicies[deleteIndex || 0]._id
      );
      setData({ ...data, insurancePolicies: insurances });
      ShowAlert("success", "Insurance Deleted Successfully");
    } catch (error: any) {
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
      }
    }
    onDeleteClose();
  };

  const handleInsuranceDoc = async (files: any) => {
    try {
      setUploading(true);
      setInsuranceDocUrl("uploading");
      let fileUrl = await uploadFile(data._id, files, "insurance");
      setInsuranceDocUrl(fileUrl);
      setUploading(false);
    } catch (error: any) {
      setUploading(false);
      setInsuranceDocUrl("");
      if (error?.response?.data?.message) {
        if (Array.isArray(error.response.data.message)) {
          return ShowAlert("warning", error.response.data.message[0]);
        }
      }

      ShowAlert("warning", "Something went wrong, please try again later");
    }
  };

  const handleViewInsuranceDoc = (url: string) => {
    if (!url) {
      return ShowAlert("warning", "Document Not Found Please Upload");
    }
    window.open(url + "?" + SaSToken, "_blank");
  };

  // const handleEsicChange = () => {
  //   setIsEsic(!isEsic);
  // };

  return (
    <>
      {data?.insurancePolicies?.length ? (
        <>
          {
            <>
              {data.insurancePolicies?.map((el: any, i: number) => (
                <>
                  <Box className={InsuranceStyle.flex} key={i + 1}>
                    <Box className={InsuranceStyle.mainCard} key={i + 1}>
                      <Stack
                        width={"150px"}
                        p={"3px"}
                        m={"5px"}
                        direction={isSmallerThan565 ? "row" : "column"}
                      >
                        <Text minWidth={"80px"} fontWeight={"600"}>
                          Policy ID/E-Card No
                        </Text>
                        <Text width={"150px"}>{el?.policyId}</Text>
                      </Stack>
                      <Stack
                        width={"150px"}
                        p={"3px"}
                        m={"5px"}
                        direction={isSmallerThan565 ? "row" : "column"}
                      >
                        <Text minWidth={"80px"} fontWeight={"600"}>
                          Insurer Name
                        </Text>
                        <Text width={"150px"}>{el?.nameOfInsurer}</Text>
                      </Stack>
                      <Stack
                        p={"3px"}
                        m={"5px"}
                        direction={isSmallerThan565 ? "row" : "column"}
                      >
                        <Text minWidth={"80px"} fontWeight={"600"}>
                          Policy Type
                        </Text>
                        <Text>{el?.typeOfPolicy}</Text>
                      </Stack>
                      <Stack
                        width={"150px"}
                        p={"3px"}
                        m={"5px"}
                        direction={isSmallerThan565 ? "row" : "column"}
                      >
                        <Text minWidth={"80px"} fontWeight={"600"}>
                          TPA Name
                        </Text>
                        <Text minWidth={"150px"}>
                          {el?.tpaName ? el?.tpaName : "NA"}
                        </Text>
                      </Stack>
                      {/* <Stack
                        direction={isSmallerThan565 ? "row" : "column"}
                      >
                        <Text fontWeight={"600"}>
                          Is this an ESIC Policy
                        </Text>
                        <Text>{el.isEsic ? "Yes" : "No"}</Text>
                      </Stack> */}
                      <HStack>
                        <Stack
                          onClick={() => handleViewInsuranceDoc(el.docUrl)}
                          className={InsuranceStyle.icon}
                        >
                          {el?.docUrl ? <FaRegFileAlt /> : <LuFileX2 />}
                        </Stack>
                        <Stack
                          onClick={() => openEdit(el, i)}
                          className={InsuranceStyle.icon}
                        >
                          <MdOutlineEdit />
                        </Stack>
                        <Stack
                          onClick={() => openDelete(el, i)}
                          className={InsuranceStyle.icon}
                        >
                          <RiDeleteBinLine />
                        </Stack>
                      </HStack>
                    </Box>
                  </Box>
                </>
              ))}
              <HStack marginTop={"30px"} justifyContent={"space-between"}>
                <GrFormPrevious
                  className={InsuranceStyle.controllerIcon}
                  onClick={() => changeStep(-1)}
                />
                {isSmallerThan319 ? (
                  <Tooltip label={"Add Another Insurance"} fontSize="md">
                    {/* @ts-ignore */}
                    <IconButton
                      bg="#EFF3F3"
                      borderRadius={"20px"}
                      onClick={onOpen}
                      variant={"solid"}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Button onClick={onOpen} colorScheme="blue" variant={"solid"}>
                    Add Another Insurance{" "}
                  </Button>
                )}
                <GrFormNext
                  onClick={() => changeStep(+1)}
                  className={InsuranceStyle.controllerIcon}
                />
              </HStack>
            </>
          }
        </>
      ) : (
        <>
          <FormControl>
            <Input
              value={policyId}
              onChange={(el) => setPolicyId(el.target.value)}
              required
              marginBottom={"20px"}
              type="text"
              placeholder="Policy Id / E-Card Number"
            />
            <Input
              value={tpaName}
              onChange={(el) => setTpaName(el.target.value)}
              marginBottom={"20px"}
              type="text"
              placeholder="TPA (Third Party Administrator) Name"
            />
            {/* <Input
              value={policyType}
              onChange={(el) => setPolicyType(el.target.value)}
              marginBottom={"20px"}
              type="text"
              placeholder="Name of Insurance Company"
            /> */}
            {/* <FormLabel htmlFor="policyType">Select Policy Type</FormLabel> */}

            <Select
              id="policyType"
              placeholder="Select Policy Type"
              mb={5}
              value={policyType}
              onChange={(el) => setPolicyType(el.target.value)}
            >
              <option value="Corporate">Corporate</option>
              <option value="Self">Self</option>
              <option value="ESIC">ESIC</option>
              <option value="Others">Others</option>
            </Select>

            <Input
              value={insurer}
              onChange={(el) => setInsurer(el.target.value)}
              marginBottom={"20px"}
              type="text"
              placeholder="Name of Insurance Company"
            />

            {/* <FormControl
              marginBottom={"15px"}
              gap="20px"
              display="flex"
              alignItems="center"
            >
              <FormLabel color={"#1C2B2F"} htmlFor="same-whatsappNumber" mb="0">
                Is this an ESIC Policy?
              </FormLabel>
              <Switch
                  onChange={() => handleEsicChange()}
                  //@ts-ignore
                value={isEsic}
                isChecked={isEsic ? true : false}
                id="same-whatsappNumber"
              />
            </FormControl> */}
            {insuranceDocUrl ? (
              <Button
                isLoading={uploading ? true : false}
                loadingText="Uploading"
                colorScheme="teal"
                variant="outline"
                onClick={() => handleViewInsuranceDoc(insuranceDocUrl)}
                marginBottom={"10px"}
              >
                View Document
              </Button>
            ) : (
              <ReactFileReader
                fileTypes={[".pdf", ".zip", ".jpeg", ".png", ".jpg"]}
                base64={true}
                multipleFiles={false}
                handleFiles={handleInsuranceDoc}
              >
                {/* @ts-ignore */}
                <Button marginBottom={"10px"} type="solid">
                  {" "}
                  Upload Document
                </Button>
              </ReactFileReader>
            )}
          </FormControl>

          <HStack justifyContent={"space-between"} spacing={12}>
            <GrFormPrevious
              className={InsuranceStyle.controllerIcon}
              onClick={() => changeStep(-1)}
            />
            <Button
              onClick={() => addInsurance()}
              colorScheme="green"
              variant={"solid"}
            >
              Add Insurance
            </Button>
          </HStack>
        </>
      )}

      {/* add modal */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Insurance</ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Policy Id / E-Card Number</FormLabel>
              <Input
                mb={1}
                value={policyId}
                onChange={(el) => setPolicyId(el.target.value)}
                placeholder="Policy Id / E-Card Number"
              />
            </FormControl>
            <FormControl>
              <FormLabel>TPA Name</FormLabel>
              <Input
                mb={1}
                value={tpaName}
                onChange={(el) => setTpaName(el.target.value)}
                type="text"
                placeholder="TPA (Third Party Administrator) Name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Select Policy Type</FormLabel>
              <Select
                id="policyType"
                // placeholder="Select Policy Type"
                mb={1}
                value={policyType}
                onChange={(el) => setPolicyType(el.target.value)}
              >
                <option value="Corporate">Corporate</option>
                <option value="Self">Self</option>
                <option value="ESIC">ESIC</option>
                <option value="Others">Others</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Name of insurer</FormLabel>
              <Input
                mb={1}
                value={insurer}
                onChange={(el) => setInsurer(el.target.value)}
                placeholder="Name of insurer"
              />
              {/* <FormControl
                marginTop={"15px"}
                marginBottom={"15px"}
                gap="20px"
                display="flex"
                alignItems="center"
              >
                <FormLabel color={"#1C2B2F"} htmlFor="same-whatsappNumber" mb="0">
                  Is ESIC Applicable?
                </FormLabel>
                <Switch
                  onChange={() => handleEsicChange()}
                  //@ts-ignore
                  value={isEsic}
                  isChecked={isEsic ? true : false}
                  id="same-whatsappNumber"
                />
              </FormControl> */}
              {insuranceDocUrl ? (
                <Button
                  isLoading={uploading ? true : false}
                  loadingText="Uploading"
                  colorScheme="teal"
                  variant="outline"
                  onClick={() => handleViewInsuranceDoc(insuranceDocUrl)}
                  marginTop={"10px"}
                >
                  View Document
                </Button>
              ) : (
                <ReactFileReader
                  fileTypes={[".pdf", ".zip", ".jpeg", ".png", ".jpg"]}
                  base64={true}
                  multipleFiles={false}
                  handleFiles={handleInsuranceDoc}
                >
                  {/* @ts-ignore */}
                  <Button marginTop={"10px"} type="solid">
                    {" "}
                    Upload Document
                  </Button>
                </ReactFileReader>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => addInsurance()} bgColor={"#38a169"} color={"white"} mr={3}>
              Add
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* editModal */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isEditOpen}
        onClose={onEditClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Insurance</ModalHeader>
          <ModalCloseButton onClick={handleCancel} />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Policy Id / E-Card Number</FormLabel>
              <Input
                mb={1}
                value={policyId}
                onChange={(el) => setPolicyId(el.target.value)}
                placeholder="Policy Id / E-Card Number"
              />
            </FormControl>
            <FormControl>
              <FormLabel>TPA Name</FormLabel>
              <Input
                mb={1}
                value={tpaName}
                onChange={(el) => setTpaName(el.target.value)}
                // type="text"
                placeholder="TPA (Third Party Administrator) Name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Select Policy Type</FormLabel>
              <Select
                id="policyType"
                // placeholder="Select Policy Type"
                mb={1}
                value={policyType}
                onChange={(el) => setPolicyType(el.target.value)}
              >
                <option value="Corporate">Corporate</option>
                <option value="Self">Self</option>
                <option value="ESIC">ESIC</option>
                <option value="Others">Others</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Name of insurer</FormLabel>
              <Input
                mb={1}
                value={insurer}
                onChange={(el) => setInsurer(el.target.value)}
                placeholder="Name of insurer"
              />
              {/* <FormControl
                marginTop={"15px"}
                marginBottom={"15px"}
                gap="20px"
                display="flex"
                alignItems="center"
              >
                <FormLabel color={"#1C2B2F"} htmlFor="same-whatsappNumber" mb="0">
                  Is ESIC Applicable?
                </FormLabel>
                <Switch
                  onChange={() => handleEsicChange()}
                  //@ts-ignore
                  value={isEsic}
                  isChecked={isEsic ? true : false}
                  id="same-whatsappNumber"
                />
              </FormControl> */}
              {insuranceDocUrl ? (
                <Button
                  isLoading={uploading ? true : false}
                  loadingText="Uploading"
                  colorScheme="teal"
                  variant="outline"
                  onClick={() => handleViewInsuranceDoc(insuranceDocUrl)}
                  marginTop={"10px"}
                >
                  View Document
                </Button>
              ) : (
                <ReactFileReader
                  fileTypes={[".pdf", ".zip", ".jpeg", ".png", ".jpg"]}
                  base64={true}
                  multipleFiles={false}
                  handleFiles={handleInsuranceDoc}
                >
                  {/* @ts-ignore */}
                  <Button marginTop={"10px"} type="solid">
                    {" "}
                    Upload Document
                  </Button>
                </ReactFileReader>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={editInsurance} colorScheme="blue" mr={3}>
              Save Changes
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* deleteAlert */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Insurance
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteInsurance} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default InsuranceInfo;
