import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";

interface HealthIdInputProps {
  healthId: string;
  handleSubmitHealthIdInput: () => void;
  loading: boolean;
  handleChangeHelathIdInput: (value: string) => void;
  setStatusModal: any;
}
const HealthIdInput: React.FC<HealthIdInputProps> = ({
  healthId,
  loading,
  handleChangeHelathIdInput,
  handleSubmitHealthIdInput,
  setStatusModal,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
    window.location.reload();
    setStatusModal({
      termsConditions: false,
      healthIdInputStatus: false,
      verifyOtpModal: false,
    });
  };
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <>
      {/* <Button
        bg="#008470"
        width={{ base: "100%" }}
        color="white"
        _hover={{
          bg: "#008470",
        }}
        onClick={onOpen}
      >
        <div style={{ padding: "5px" }}>
          <MdIntegrationInstructions />
        </div>
        Link ABHA By Aadhaar
      </Button> */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "sm", xl: "xl" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> ABHA Integration </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              display="flex"
              flexDir={{ base: "column", xl: "row" }}
              justifyContent="space-evenly"
              textAlign="center"
              alignItems="center"
              padding="10px"
            >
              <Box display="flex" justifyContent="center">
                <Image
                  maxW="199px"
                  minW="106px"
                  height="auto"
                  src="https://developmentbucket.blob.core.windows.net/logos/abhaLogo.png"
                />
              </Box>
              <Box
                mt={3}
                alignItems="center"
                textAlign={{ base: "center", xl: "left" }}
              >
                <FormControl>
                  <FormLabel>Enter 14 digit ABHA Health ID</FormLabel>
                  <Input
                    ref={initialRef}
                    placeholder="91XXXXXXXXXX12"
                    value={healthId}
                    type="text"
                    maxLength={14}
                    onChange={(e) => handleChangeHelathIdInput(e.target.value)}
                  />
                </FormControl>
                <Button
                  mt={5}
                  onClick={handleSubmitHealthIdInput}
                  bg="#008470"
                  color="white"
                  mr={3}
                  isLoading={loading}
                  loadingText="Sending OTP"
                >
                  Generate OTP
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HealthIdInput;
