import { getAllBeneficiariesData } from "../../../../http";
import * as beneficiarySlice from "../../../../Redux/Beneficiary-Slice/Beneficiary-slice";

export const getAllBen = async (subId:string,dispatch:any) => {
    
    try {

      const { data } = await getAllBeneficiariesData(subId)
      // console.log(data)
      dispatch(beneficiarySlice.saveBeneficiary(data.data))
      return data;

    } catch (error:any) {

      console.log(error)
      if(error.response?.data){
        throw error.response.data;
      }
      throw error;
      
    }
  };
  